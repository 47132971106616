import React, { useState } from 'react';
import { Popover } from 'react-tiny-popover';

import './customersActionButton.scss';
import CustomersActionDropdown from './CustomersActionDropdown';

function CustomersActionButton({ editClicked, deleteClicked }) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <div className="CustomersActionButton">
      <Popover
        isOpen={isPopoverOpen}
        positions={['bottom', 'right']}
        onClickOutside={() => setIsPopoverOpen(false)}
        containerStyle={{ zIndex: 100 }}
        content={
          <CustomersActionDropdown
            editClicked={() => {
              setIsPopoverOpen(false);
              editClicked();
            }}
            deleteClicked={() => {
              setIsPopoverOpen(false);
              deleteClicked();
            }}
          />
        }
      >
        <button
          className="action-button"
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
          <i className="fas fa-ellipsis-h" aria-hidden="true"></i>
        </button>
      </Popover>
    </div>
  );
}

export default CustomersActionButton;
