import React from 'react';

import './businessSchedules.scss';

function BusinessSchedules({}) {
  return (
    <div className="BusinessSchedules">
      
    </div>
  );
}

export default BusinessSchedules;
