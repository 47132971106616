import React from 'react';

import './dataTable.scss';

function DataTable({ children, colors }) {
  return (
    <div className="DataTable">
      <table className={colors || ''}>
        {children}
      </table>
    </div>
  );
}

export default DataTable;
