import {
  UPDATE_SERVICES,
} from './types';

export const updateServices = (services) => {
  return {
    type: UPDATE_SERVICES,
    payload: services,
  };
};
