import React, { useEffect, useState } from 'react';
import { getAuth } from '@firebase/auth';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

import './businessInfo.scss';
import { config } from '../../../../../config';
import { timezones } from '../../../../../util/timezones';
import { countries } from '../../../../../util/countries';
import { setBusiness } from '../../../../../store/actions';
import Loading from '../../../../shared/Loading';
import Modal from '../../../../shared/Modal';

function BusinessInfo({}) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const business = useSelector(state => state.business);
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [editBusiness, setEditBusiness] = useState({
    name: '',
    appURL: '',
    email: '',
    phone: '',
    phoneExt: '',
    website: '',
    timezone: '',
    country: '',
    city: '',
    state: '',
    zip: '',
    address1: '',
    address2: '',
    description: '',
  });
  const [hasMadeChanges, setHasMadeChanges] = useState(false);

  useEffect(() => {
    if (!business.id && user.id) {
      fetchBusiness();
    }

    if (business.id) {
      setLoading(false);
    }
  }, [business, user]);

  useEffect(() => {
    setEditBusiness({
      name: business.name,
      appURL: business.appURL || business.id,
      email: business.email,
      phone: business.phone,
      phoneExt: business.phoneExt,
      website: business.website,
      timezone: business.timezone,
      country: business.country,
      city: business.city,
      state: business.state,
      zip: business.zip,
      address1: business.address1,
      address2: business.address2,
      description: business.description,
    });
  }, [business]);

  const fetchBusiness = async () => {
    setLoading(true);

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(`${config.api}/api/v1/business/${user.businessId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response.data && response.data.id) {
        dispatch(setBusiness(response.data));
      } else {
        setModalTitle('Error:');
        setModalText('There was an error retrieving the business info, please try again.');
      }
    } catch (e) {
      setModalTitle('Error:');
      setModalText('There was an error retrieving the business info, please try again.');
    }

    setLoading(false);
  };

  const handleFieldChange = (e, type) => {
    setEditBusiness({
      ...editBusiness,
      [type]: e.target.value,
    });
    setHasMadeChanges(true);
  };

  const saveBusinessInfo = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      await axios.put(`${config.api}/api/v1/business/${user.businessId}`,
        {
          ...editBusiness,
          email: editBusiness.email.trim().toLowerCase(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch(setBusiness({
        ...business,
        ...editBusiness,
      }));
      setHasMadeChanges(false);
    } catch (e) {
      let errorMessage = 'There was an error updating the business info, please try again.';
      if (e && e.response && e.response.data && e.response.data.message) {
        errorMessage = e.response.data.message;
      }

      setModalTitle('Error:');
      setModalText(errorMessage);
    }

    setLoading(false);
  };

  return (
    <div className="BusinessInfo">
      {!loading ? null : <Loading position="absolute" />}
      <>
        <div className="input-container">
          <label>Company Name:</label>
          <input
            type="text"
            value={editBusiness.name}
            onChange={(e) => handleFieldChange(e, 'name')}
            placeholder="Enter company name"
          />
        </div>

        <div className="input-container">
          <label>Public Calendar URL:</label>
          <input
            type="text"
            value={editBusiness.appURL}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9a-z\-]/gi, '');
              setEditBusiness({
                ...editBusiness,
                appURL: value,
              });
              setHasMadeChanges(true);
            }}
            placeholder="my-business-name"
          />

          <small>
            <strong>Full URL:</strong> <a href={`${window.location.origin}/schedule/${editBusiness.appURL}`} target="_blank">{window.location.origin}/schedule/{editBusiness.appURL}</a>
          </small>
        </div>

        <div className="input-row">
          <div className="input-container flex-1">
            <label>Company Website:</label>
            <input
              type="text"
              value={editBusiness.website}
              onChange={(e) => handleFieldChange(e, 'website')}
              placeholder="Enter company website"
            />
          </div>

          <div className="input-container flex-1">
            <label>Email:</label>
            <input
              type="email"
              value={editBusiness.email}
              onChange={(e) => handleFieldChange(e, 'email')}
              placeholder="Enter email"
            />
          </div>
        </div>

        <div className="input-row phone-row">
          <div className="input-container flex-1 timezone-container">
            <label>Timezone:</label>
            <select
              value={editBusiness.timezone}
              onChange={(e) => handleFieldChange(e, 'timezone')}
            >
              {timezones.map((timezone, i) => {
                return <option key={`timezone-${i}`} value={timezone}>{timezone}</option>
              })}
            </select>
          </div>

          <div className="input-row">
            <div className="input-container flex-2">
              <label>Phone:</label>
              <input
                type="phone"
                value={editBusiness.phone}
                onChange={(e) => handleFieldChange(e, 'phone')}
                placeholder="Enter phone number"
              />
            </div>

            <div className="input-container flex-1">
              <label>Phone Extension:</label>
              <input
                type="text"
                value={editBusiness.phoneExt}
                onChange={(e) => handleFieldChange(e, 'phoneExt')}
                placeholder="Enter phone extension"
              />
            </div>
          </div>
        </div>

        <div className="input-row">
          <div className="input-container flex-1">
            <label>Country:</label>
            <select
              value={editBusiness.country}
              onChange={(e) => handleFieldChange(e, 'country')}
            >
              {countries.map((c, i) => {
                return <option key={`country-${i}`} value={c.value}>{c.name}</option>
              })}
            </select>
          </div>

          <div className="input-container flex-1">
            <label>State:</label>
            <input
              type="text"
              value={editBusiness.state}
              onChange={(e) => handleFieldChange(e, 'state')}
              placeholder="Enter state"
            />
          </div>
        </div>

        <div className="input-row">
          <div className="input-container flex-1">
            <label>City:</label>
            <input
              type="text"
              value={editBusiness.city}
              onChange={(e) => handleFieldChange(e, 'city')}
              placeholder="Enter city"
            />
          </div>

          <div className="input-container flex-1">
            <label>Zip code:</label>
            <input
              type="text"
              value={editBusiness.zip}
              onChange={(e) => handleFieldChange(e, 'zip')}
              placeholder="Enter zip code"
            />
          </div>
        </div>

        <div className="input-container">
          <label>Address:</label>
          <input
            type="text"
            value={editBusiness.address1}
            onChange={(e) => handleFieldChange(e, 'address1')}
            placeholder="Enter address"
          />
        </div>

        <div className="input-container">
          <label>Apartment, suite, unit etc:</label>
          <input
            type="text"
            value={editBusiness.address2}
            onChange={(e) => handleFieldChange(e, 'address2')}
            placeholder="Enter apartment, suite, unit etc"
          />
        </div>

        <div className="input-container">
          <label>Description:</label>
          <textarea
            value={editBusiness.description}
            onChange={(e) => handleFieldChange(e, 'description')}
            placeholder="Enter description"
          />
        </div>

        <button
          disabled={!hasMadeChanges || !editBusiness.name}
          onClick={saveBusinessInfo}
        >
          Save Changes
        </button>
      </>

      <Modal
        open={!!modalText}
        close={() => {
          setModalTitle('');
          setModalText('');
        }}
        title={modalTitle}
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            setModalTitle('');
            setModalText('');
          }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default BusinessInfo;
