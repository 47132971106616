import React, { useEffect, useState } from 'react';
import moment from 'moment';

import './calendarDayView.scss';
import { displayHours } from '../../../../../util/displayHours';

function CalendarDayView({
  staff = [],
  schedule,
  appointments = [],
  currentDate,
  handleTimeBlockClicked,
  handleAppointmentClicked,
}) {
  const [staffAppointments, setStaffAppointments] = useState({});

  useEffect(() => {
    const updatedStaffAppointments = {};
    
    staff.forEach(s => {
      updatedStaffAppointments[s.id] = [];
    });

    appointments.forEach(a => {
      if (moment(currentDate).startOf('day').isSame(moment(a.start).startOf('day'))) {
        if (updatedStaffAppointments[a.resourceId]) {
          updatedStaffAppointments[a.resourceId].push(a);
        }
      }
    });

    setStaffAppointments(updatedStaffAppointments);
  }, [appointments, currentDate, staff]);

  const renderRowSpacers = (className) => {
    const spacers = [];
    let i = staff.length;

    while (i < 5) {
      spacers.push(<div className={className} key={`spacer-${i}-${className}`}></div>);
      i++;
    }

    return spacers;
  };

  const renderAppointmentsForHour = (staffId, hours) => {
    const startOfHour = moment(currentDate).set({ hours, minutes: 0, seconds: 0, milliseconds: 0 });
    let appointments = [];

    (staffAppointments[staffId] || []).forEach(a => {
      const appointmentStart = moment(a.start);
      const appointmentEnd = moment(a.end);
      const duration = moment.duration(appointmentEnd.diff(appointmentStart));
      const appointmentLengthInMinutes = duration.asMinutes();

      if (moment(appointmentStart).startOf('hour').isSame(startOfHour)) {
        appointments.push({
          ...a,
          leftDistance: ((appointmentStart.get('minutes') / 60) * 125),
          width: ((appointmentLengthInMinutes / 60) * 125),
        });
      }
    });

    return appointments.map((appointment) => {
      return (
        <button
          key={`appointment-button-${appointment.id}`}
          className="appointment-button"
          style={{ left: appointment.leftDistance, width: appointment.width, backgroundColor: appointment.color }}
          onClick={() => handleAppointmentClicked(appointment)}
        >
          <span>
            <span className="appointment-time">{moment(appointment.start).format('h:mm A')} - {moment(appointment.end).format('h:mm A')}</span>
            <span>{appointment.customerName}</span>
          </span>
        </button>
      );
    });
  };

  return (
    <div className="CalendarDayView">
      <div className="staff-column">
        <div className="staff-header">
          <p>Staff</p>
        </div>

        {staff.map((s, i) => {
          return (
            <div key={`staff-${i}`} className="staff-member">
              <div>
                <div className="staff-image-container">
                  <i className="fas fa-user"></i>
                </div>
              </div>
              <div className="staff-details-container">
                <p className="staff-name">{s.name}</p>
                <p className="staff-appointment-count">{(staffAppointments[s.id] || []).length} Appointment{(staffAppointments[s.id] || []).length === 1 ? '' : 's'}</p>
              </div>
            </div>
          );
        })}

        {staff.length > 4 ? null :
          <>
            {renderRowSpacers('staff-member')}
          </>
        }
      </div>

      <div className="time-columns time-scroll-container">
        {displayHours.map(h => {
          const hourArr = h.split(' ');
          let hours = +hourArr[0];

          if (hours !== 12 && hourArr[1] === 'PM') {
            hours += 12;
          } else if (hours === 12 && hourArr[1] === 'AM') {
            hours = 0;
          }

          return (
            <div key={hourArr.join('-')} className="time-column">
              <div className="time-header">
                <p>{h}</p>
              </div>

              {staff.map((s, i) => {
                return (
                  <div key={`time-${hourArr.join('-')}-staff-${i}`} className="staff-time-block">
                    <button
                      onClick={() => handleTimeBlockClicked(h, 0, s.id)}
                      className="time-block-select-button"
                    ></button>
                    <button
                      onClick={() => handleTimeBlockClicked(h, 15, s.id)}
                      className="time-block-select-button"
                    ></button>
                    <button
                      onClick={() => handleTimeBlockClicked(h, 30, s.id)}
                      className="time-block-select-button"
                    ></button>
                    <button
                      onClick={() => handleTimeBlockClicked(h, 45, s.id)}
                      className="time-block-select-button"
                    ></button>

                    {renderAppointmentsForHour(s.id, hours)}
                  </div>
                );
              })}

              {staff.length > 4 ? null :
                <>
                  {renderRowSpacers('staff-time-block')}
                </>
              }
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CalendarDayView;
