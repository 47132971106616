import {
  SET_BUSINESS_SCHEDULE,
} from './types';

export const setBusinessSchedule = (businessSchedule) => {
  return {
    type: SET_BUSINESS_SCHEDULE,
    payload: businessSchedule,
  };
};
