export const timeStringsTo24Hour = (timeString, meridiem, config = {}) => {
  const timeArr = timeString.split(':').map(t => +t);

  if (timeArr[0] === 12) {
    timeArr[0] = 0;
  }

  if (meridiem === 'PM') {
    timeArr[0] += 12;
  }

  if (config.returnAsMinutes) {
    return (timeArr[0] * 60) + timeArr[1];
  }

  return {
    hours: timeArr[0],
    minutes: timeArr[1] || 0,
    seconds: timeArr[2] || 0,
  };
};
