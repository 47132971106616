import React from 'react';

import './tableHead.scss';

function TableHead({ children }) {
  return (
    <thead className="TableHead">
      {children}
    </thead>
  );
}

export default TableHead;
