import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';

import './resetPassword.scss';
import { config } from '../../../config';
import Modal from '../../shared/Modal';

function ResetPassword(props) {
  const history = useHistory();
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [loading, setLoading] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }

    const queryString = props.location.search;
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
      let pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    
    if (!query.token) {
      setModalTitle('Error:');
      setModalText('The provided URL is invalid. Please follow the link sent to your email to reset your password.');
      return;
    }

    if (!newPassword) {
      setModalTitle('Notice:');
      setModalText('New password required. Please enter your new password and try again.');
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setModalTitle('Notice:');
      setModalText('Passwords do not match. Please check your password and try again.');
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(`${config.api}/api/v1/confirm-password-reset`, {
        token: query.token,
        password: newPassword,
      });
      const data = response.data;

      if (data.status === 'error') {
        setModalTitle('Error:');
        setModalText(data.message);
      } else {
        setModalTitle('Success!');
        setModalText('Your password has been reset and you will be redirected to the sign in page shortly.');
        setNewPassword('');
        setConfirmNewPassword('');

        setTimeout(() => {
          setModalTitle('');
          setModalText('');

          setTimeout(() => {
            history.replace('/signin');
          }, 0);
        }, 3000);
      }
    } catch (e) {
      let errorMessage = 'There was an error processing your password reset request. Please try again. If this problem persists, contact us at hello@pupford.com for assistance.';
      if (e && e.response && e.response.data && e.response.data.message) {
        errorMessage = e.response.data.message;
      }

      setModalTitle('Error:');
      setModalText(errorMessage);
    }

    setLoading(false);
  };

  return (
    <div className="ResetPassword">
      <div className="form-container">
        <div className="well auth-well">
          <div className="logo-container">
            <img
              src="https://pupford.b-cdn.net/assets%2F1659561571475-pupford-logo.png?alt=media&token=ae31678e-909d-48ef-990e-b163298d74a4"
              alt="Pupford Logo"
              className="logo"
            />
          </div>

          <h1>Reset Password</h1>

          <p className="forgot-password-message">
            To reset your password, enter and verify your new password below.
          </p>

          <form onSubmit={submit}>
            <div className="input-container">
              <label>New Password:</label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter new password"
              />
            </div>

            <div className="input-container">
              <label>Confirm New Password:</label>
              <input
                type="password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                placeholder="Confirm new password"
              />
            </div>

            <button
              type="submit"
            >
              Submit
            </button>
          </form>

          <div className="sign-in-container">
            <span>Already know your password? </span>

            <Link to="/signin">
              Sign In
            </Link>
          </div>
        </div>
      </div>

      <Modal
        open={!!modalText}
        close={() => {
          setModalTitle('');
          setModalText('');
        }}
        title={modalTitle}
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            setModalTitle('');
            setModalText('');
          }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default ResetPassword;
