import React from 'react';

import './tableCell.scss';

function TableCell({ children, action }) {
  return (
    <td className={`TableCell${action ? ' action' : ''}`}>
      {children}
    </td>
  );
}

export default TableCell;
