import React, { useEffect, useState } from 'react';
import { getAuth } from '@firebase/auth';
import axios from 'axios';
import moment from 'moment';

import './customers.scss';
import { config } from '../../../../config';
import { countries } from '../../../../util/countries';
import DataTable from '../../../shared/DataTable';
import TableHead from '../../../shared/DataTable/TableHead';
import TableCell from '../../../shared/DataTable/TableCell';
import TableRow from '../../../shared/DataTable/TableRow';
import TableBody from '../../../shared/DataTable/TableBody';
import Modal from '../../../shared/Modal';
import CustomersActionButton from './CustomersActionButton';

const pageSize = 10;

function Customers({ user, setLoading, loading }) {
  const [customers, setCustomers] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [currentCustomer, setCurrentCustomer] = useState({});
  const [currentCustomerOpen, setCurrentCustomerOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [cursors, setCursors] = useState([]);
  const [nextDisabled, setNextDisabled] = useState(false);

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    if (user && user.businessId) {
      fetchCustomers();
    }
  }, [user]);

  const fetchCustomers = async (action) => {
    setLoading(true);

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();

      let updatedCursors = [ ...cursors ];
      let query = '';

      if (action === 'back') {
        updatedCursors.pop();
        updatedCursors.pop();
      }

      if (!action) {
        updatedCursors = [];
      }

      if (updatedCursors.length) {
        query = `?next=${updatedCursors[updatedCursors.length - 1]}`;
      }

      const response = await axios.get(`${config.api}/api/v1/business/customers/${user.businessId}${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response.data && response.data.customers && Array.isArray(response.data.customers) && response.data.customers.length) {
        setCustomers(response.data.customers);

        if (response.data.customers.length < pageSize) {
          setNextDisabled(true);
        } else {
          setNextDisabled(false);
        }
        
        if (!updatedCursors.includes(response.data.next)) {
          updatedCursors = [ ...updatedCursors, response.data.next ];
          setCursors(updatedCursors);
        } else {
          setCursors(updatedCursors);
        }
      } else {
        setCursors(updatedCursors);
        setNextDisabled(true);
      }
    } catch (e) {
      setModalTitle('Error:');
      setModalText('There was an error retrieving your services, please try again.');
    }

    setLoading(false);
  };

  const confirmDelete = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const response = await axios.delete(`${config.api}/api/v1/business/customers/${customers[deleteIndex].id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response.data && response.data.status === 'error') {
        setModalTitle(response.data.message);
        setModalText('There was an error deleting this customer, please try again.');
      } else {
        fetchCustomers();
      }
      
      setDeleteIndex(-1);
    } catch (e) {
      setModalTitle('Error:');
      setModalText('There was an error deleting this customer, please try again.');
    }

    setLoading(false);
  };

  const closeCustomerModal = () => {
    setCurrentCustomer({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      phoneExt: '',
      country: 'US',
      city: '',
      state: '',
      zip: '',
      address1: '',
      address2: '',
      group: '',
      businessId: user.businessId,
      notes: '',
      created: moment().valueOf(),
    });
    setCurrentCustomerOpen(false);
  };

  const checkIfSubmitIsDisabled = () => {
    const c = currentCustomer;

    if (!c.firstName) {
      return true;
    }

    return false;
  };

  const handleFieldChange = (e, type) => {
    setCurrentCustomer({
      ...currentCustomer,
      [type]: e.target.value,
    });
  };

  const saveCustomer = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();

      if (currentCustomer.id) {
        await axios.put(`${config.api}/api/v1/business/customers/${currentCustomer.id}`,
          {
            ...currentCustomer,
            email: currentCustomer.email.trim().toLowerCase(),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const customersCopy = [ ...customers ];
        const customerIndex = customersCopy.findIndex(c => c.id === currentCustomer.id);

        if (customerIndex !== -1) {
          customersCopy[customerIndex] = { ...currentCustomer };
        }
        
        fetchCustomers();
        closeCustomerModal();
      } else {
        const result = await axios.post(`${config.api}/api/v1/business/customers`,
          [{
            ...currentCustomer,
            email: currentCustomer.email.trim().toLowerCase(),
          }],
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!result || !result.data || !result.data.createdCustomerIds) {
          setModalTitle('Error:');
          setModalText(`There was an error adding this customer, please try again.`);
        } else {
          const customersCopy = [ ...customers ];
  
          customersCopy.unshift({
            ...currentCustomer,
            id: result.data.createdCustomerIds[0],
          });

          fetchCustomers();
          closeCustomerModal();
        }
      }
    } catch (e) {
      setModalTitle('Error:');
      setModalText(`There was an error ${currentCustomer.id ? 'updating' : 'adding'} this customer, please try again.`);
    }

    setLoading(false);
  };

  return (
    <div className="Customers">
      <div>
        <div className="header-container">
          <h1>Customers</h1>

          <button
            className="small success"
            onClick={() => {
              setCurrentCustomer({
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                phoneExt: '',
                country: 'US',
                city: '',
                state: '',
                zip: '',
                address1: '',
                address2: '',
                group: '',
                businessId: user.businessId,
                notes: '',
                created: moment().valueOf(),
              });
              setCurrentCustomerOpen(true);
            }}
          >
            New Customer <i className="fas fa-plus"></i>
          </button>
        </div>

        <DataTable colors="secondary">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell action></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {customers.map((customer, i) => {
              return (
                <TableRow key={customer.id}>
                  <TableCell>{customer.firstName} {customer.lastName}</TableCell>
                  <TableCell>{customer.email || '-'}</TableCell>
                  <TableCell>
                    {!customer.phone ? '-' :
                      <>{customer.phone}{!customer.phoneExt ? '' : `, ext. ${customer.phoneExt}`}</>
                    }
                  </TableCell>
                  <TableCell action>
                    <CustomersActionButton
                      editClicked={() => {
                        setCurrentCustomer({ ...customer });
                        setCurrentCustomerOpen(true);
                      }}
                      deleteClicked={() => {
                        setDeleteIndex(i);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </DataTable>

        <div className="pagination-container">
          <button
            className="small success"
            disabled={nextDisabled}
            onClick={() => {
              fetchCustomers('next');
            }}
          >
            Next
          </button>

          <button
            className="small success"
            disabled={cursors.length <= 1}
            onClick={() => {
              fetchCustomers('back');
            }}
          >
            Back
          </button>
        </div>
      </div>

      <Modal
        open={currentCustomerOpen}
        close={closeCustomerModal}
        title={`${currentCustomer.id ? 'Edit' : 'Add'} Customer`}
        buttons={[
          <button
            key="modal-save"
            className="small success"
            disabled={checkIfSubmitIsDisabled()}
            onClick={saveCustomer}
          >Submit</button>,
          <button
            key="modal-close"
            className="small"
            onClick={closeCustomerModal}
          >Cancel</button>,
        ]}
      >
        <div className="add-customer-modal">
          <div className="input-row">
            <div className="input-container flex-1">
              <label>First Name:</label>
              <input
                type="text"
                value={currentCustomer.firstName}
                onChange={(e) => handleFieldChange(e, 'firstName')}
                placeholder="Enter first name"
              />
            </div>

            <div className="input-container flex-1">
              <label>Last Name:</label>
              <input
                type="text"
                value={currentCustomer.lastName}
                onChange={(e) => handleFieldChange(e, 'lastName')}
                placeholder="Enter last name"
              />
            </div>
          </div>

          <div className="input-container">
            <label>Email:</label>
            <input
              type="email"
              value={currentCustomer.email}
              onChange={(e) => handleFieldChange(e, 'email')}
              placeholder="Enter email"
            />
          </div>

          <div className="input-row">
            <div className="input-container flex-2">
              <label>Phone:</label>
              <input
                type="phone"
                value={currentCustomer.phone}
                onChange={(e) => handleFieldChange(e, 'phone')}
                placeholder="Enter phone"
              />
            </div>

            <div className="input-container flex-1">
              <label>Phone Ext.:</label>
              <input
                type="number"
                value={currentCustomer.phoneExt}
                onChange={(e) => handleFieldChange(e, 'phoneExt')}
                placeholder="Enter phone extension"
              />
            </div>
          </div>

          <div className="input-row">
            <div className="input-container flex-1">
              <label>Country:</label>
              <select
                value={currentCustomer.country}
                onChange={(e) => handleFieldChange(e, 'country')}
              >
                {countries.map((c, i) => {
                  return <option key={`country-${i}`} value={c.value}>{c.name}</option>
                })}
              </select>
            </div>

            <div className="input-container flex-1">
              <label>State:</label>
              <input
                type="text"
                value={currentCustomer.state}
                onChange={(e) => handleFieldChange(e, 'state')}
                placeholder="Enter state"
              />
            </div>
          </div>

          <div className="input-row">
            <div className="input-container flex-2">
              <label>City:</label>
              <input
                type="text"
                value={currentCustomer.city}
                onChange={(e) => handleFieldChange(e, 'city')}
                placeholder="Enter city"
              />
            </div>

            <div className="input-container flex-1">
              <label>Zip:</label>
              <input
                type="text"
                value={currentCustomer.zip}
                onChange={(e) => handleFieldChange(e, 'zip')}
                placeholder="Enter zip"
              />
            </div>
          </div>

          <div className="input-container">
            <label>Address:</label>
            <input
              type="text"
              value={currentCustomer.address1}
              onChange={(e) => handleFieldChange(e, 'address1')}
              placeholder="Enter address"
            />
          </div>

          <div className="input-container">
            <label>Apartment, suite, unit etc:</label>
            <input
              type="text"
              value={currentCustomer.address2}
              onChange={(e) => handleFieldChange(e, 'address2')}
              placeholder="Enter Apartment, suite, unit etc"
            />
          </div>

          <div className="input-container">
            <label>Notes:</label>
            <textarea
              value={currentCustomer.notes}
              onChange={(e) => handleFieldChange(e, 'notes')}
              placeholder="Enter notes"
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={deleteIndex !== -1}
        close={() => {
          setDeleteIndex(-1);
        }}
        title={`Delete ${customers[deleteIndex] ? `${customers[deleteIndex].firstName} ${customers[deleteIndex].lastName}`  : ''}?`}
        buttons={[
          <button key="modal-delete" className="small danger" onClick={confirmDelete}>Confirm</button>,
          <button key="modal-close" className="small" onClick={() => {
            setDeleteIndex(-1);
          }}>Cancel</button>,
        ]}
      >
        <div>
          <div className="modal-text">
            Are you sure you want to delete <strong>{customers[deleteIndex] ? `${customers[deleteIndex].firstName} ${customers[deleteIndex].lastName}` : ''}</strong>?
          </div>
        </div>
      </Modal>

      <Modal
        open={!!modalText}
        close={() => {
          setModalTitle('');
          setModalText('');
        }}
        title={modalTitle}
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            setModalTitle('');
            setModalText('');
          }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default Customers;
