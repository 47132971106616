import {
  SET_BUSINESS,
  UPDATE_BUSINESS,
} from '../actions/types';

const defaultState = {
  id: '',
  name: '',
  appURL: '',
  logo: '',
  country: '',
  city: '',
  state: '',
  zip: '',
  address1: '',
  address2: '',
  industry: '',
  timezone: '',
  description: '',
  website: '',
  email: '',
  phone: '',
  phoneExt: '',
  customerCount: 0,
  created: 0,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_BUSINESS:
      return action.payload;
    case UPDATE_BUSINESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
