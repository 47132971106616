import React from 'react';

import './customersActionDropdown.scss';

function CustomersActionDropdown({ editClicked, deleteClicked }) {
  return (
    <div className="CustomersActionDropdown">
      <button onClick={() => editClicked()}>
        Edit
      </button>
      <button onClick={() => deleteClicked()}>
        Delete
      </button>
    </div>
  );
}

export default CustomersActionDropdown;
