import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import VerifyAccount from './VerifyAccount';
import SignIn from './SignIn';
import SignUp from './SignUp';

function Auth({}) {
  return (
    <div>
      <Switch>
        <Route path="/signin" component={SignIn} />
        <Route path="/signup" component={SignUp} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/verify-account" component={VerifyAccount} />
        <Route path="/reset-password" component={ResetPassword}></Route>
        <Route component={SignIn} />
      </Switch>
    </div>
  );
}

export default Auth;
