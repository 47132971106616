import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import Calendar from 'react-calendar';
import { PatternFormat } from 'react-number-format';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

import './schedulePicker.scss';
import { config } from '../../../config';
import { colors } from '../../../util/colors';
import { get12HourTimeWithMeridiem } from '../../../util/get12HourTimeWithMeridiem';
import { timeStringsTo24Hour } from '../../../util/timeStringsTo24Hour';
import { isValidEmail } from '../../../util/isValidEmail';
import Loading from '../../shared/Loading';
import Modal from '../../shared/Modal';
import BusinessNotFound from '../BusinessNotFound';

const minDate = moment().add(1, 'day').toDate();

function SchedulePicker({}) {
  let location = useLocation();
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [showNotFound, setShowNotFound] = useState(false);
  const [scheduleTimezone, setScheduleTimezone] = useState('');
  const [business, setBusiness] = useState({});
  const [service, setService] = useState({});
  const [schedule, setSchedule] = useState({});
  const [staff, setStaff] = useState([]);
  const [selectedStaffIndex, setSelectedStaffIndex] = useState(-1);
  const [selectedDate, setSelectedDate] = useState(0);
  const [staffScheduledAppointments, setStaffScheduledAppointments] = useState([]);
  const [startOfDisplayedCalendarMonth, setStartOfDisplayedCalendarMonth] = useState(0);
  const [availableDaysInCurrentMonth, setAvailableDaysInCurrentMonth] = useState({});
  const [selectedTime, setSelectedTime] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [appointmentScheduled, setAppointmentScheduled] = useState(false);
  const [selectNewTimeNotificationOpen, setSelectNewTimeNotificationOpen] = useState(false);

  useEffect(() => {
    fetchScheduleData();
  }, []);

  useEffect(() => {
    if (scheduleTimezone && staff.length && selectedStaffIndex !== -1) {
      const start = moment().tz(scheduleTimezone).startOf('day').valueOf();
      const end = moment().tz(scheduleTimezone).endOf('month').valueOf();
      fetchStaffMemberAppointments(start, end);
    }
  }, [scheduleTimezone, staff, selectedStaffIndex]);

  useEffect(() => {
    updateAvailability();
  }, [staffScheduledAppointments, schedule, scheduleTimezone, startOfDisplayedCalendarMonth]);

  const fetchScheduleData = async () => {
    const pathArr = location.pathname.split('/');
    const businessPath = pathArr[2];
    const serviceId = pathArr[3];

    if (!businessPath || !serviceId) {
      setLoading(false);
      setShowNotFound(true);
      return;
    }

    try {
      const businessResponse = await axios.get(`${config.api}/api/v1/business/app-url/${businessPath}`);
      const businessData = businessResponse.data;

      const serviceResponse = await axios.get(`${config.api}/api/v1/business/service/${serviceId}`);
      const serviceData = serviceResponse.data;

      if (!businessData.id || !Array.isArray(serviceData.staff) || !serviceData.staff.length) {
        setLoading(false);
        setShowNotFound(true);
        return;
      }

      let scheduleData;

      if (!serviceData.scheduleId) {
        const scheduleResponse = await axios.get(`${config.api}/api/v1/business/schedule/${businessData.id}`);
        scheduleData = scheduleResponse.data;
      } else {
        const scheduleResponse = await axios.get(`${config.api}/api/v1/user/schedule/${serviceData.scheduleId}`);
        scheduleData = scheduleResponse.data;
      }

      if (!scheduleData || !scheduleData.id) {
        setLoading(false);
        setShowNotFound(true);
        return;
      }

      const timezone = scheduleData.timezone || businessData.timezone;

      if (!timezone) {
        setLoading(false);
        setShowNotFound(true);
        return;
      }

      setScheduleTimezone(timezone);

      const staffResponse = await axios.post(`${config.api}/api/v1/users/service`, serviceData.staff);
      const staffData = staffResponse.data;

      if (!staffData || !Array.isArray(staffData) || !staffData.length) {
        setLoading(false);
        setShowNotFound(true);
        return;
      }

      if (staffData.length === 1) {
        setSelectedStaffIndex(0);
      }

      setBusiness(businessData);
      setService(serviceData);
      setSchedule(scheduleData);
      setStaff(staffData);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving this schedule. It may have been deleted or modified.');
    }
  };

  const fetchStaffMemberAppointments = async (startDate, endDate) => {
    setLoading(true);

    const currentDate = moment().valueOf();
    let start = startDate;

    if (currentDate > start) {
      start = currentDate;
    }

    try {
      const appointmentsResponse = await axios.get(`${config.api}/api/v1/staff/appointments/${staff[selectedStaffIndex].id}/${start}/${endDate}`);
      setStaffScheduledAppointments(appointmentsResponse.data || []);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving staff schedule. Refresh the page and try again.');
    }
  };

  const getAvailableAppointmentStartTimesForDay = (workingHours, appointments) => {
    const availableAppointmentStartTimesForDay = [];
    const appointmentsWithStartAndEndInMinutes = appointments.map((a) => {
      const start = get12HourTimeWithMeridiem(moment(a.start).tz(scheduleTimezone).toDate());
      const end = get12HourTimeWithMeridiem(moment(a.end).tz(scheduleTimezone).toDate());

      return {
        start: timeStringsTo24Hour(start.time, start.meridiem, { returnAsMinutes: true }),
        end: timeStringsTo24Hour(end.time, end.meridiem, { returnAsMinutes: true }),
      };
    });

    workingHours.forEach(h => {
      const startAs24Hour = timeStringsTo24Hour(h.startTime, h.startMeridiem);
      const startAsMinutes = timeStringsTo24Hour(h.startTime, h.startMeridiem, { returnAsMinutes: true });
      const endAsMinutes = timeStringsTo24Hour(h.endTime, h.endMeridiem, { returnAsMinutes: true });
      let start = startAsMinutes;

      while (start < endAsMinutes) {
        const startOfTimePeriod = start;
        const serviceDuration = service.duration;
        const endOfTimePeriod = start + serviceDuration;

        if (endOfTimePeriod <= endAsMinutes) {
          let hasConflict = false;

          appointmentsWithStartAndEndInMinutes.forEach(a => {
            const startIsDuringAppointment = startOfTimePeriod < a.end && startOfTimePeriod > a.start;
            const endIsDuringAppointment = endOfTimePeriod < a.end && endOfTimePeriod > a.start;
            const appointmentIsDuringTimePeriod = a.start >= startOfTimePeriod && a.end <= endOfTimePeriod;

            if (startIsDuringAppointment || endIsDuringAppointment || appointmentIsDuringTimePeriod) {
              hasConflict = true;
            }
          });

          if (!hasConflict) {
            const minutesFromStartOfWorkingHours = startOfTimePeriod - startAsMinutes;
            const hoursToAddToStart = Math.floor(minutesFromStartOfWorkingHours / 60);
            const minutesToAddToStart = Math.floor(minutesFromStartOfWorkingHours - (hoursToAddToStart * 60));
            const startTime = {
              hours: startAs24Hour.hours + hoursToAddToStart,
              minutes: startAs24Hour.minutes + minutesToAddToStart,
              seconds: 0,
            };

            let meridiem = 'AM';

            if (startTime.hours >= 12) {
              meridiem = 'PM';
            }

            if (startTime.hours > 12) {
              startTime.hours -= 12;
            }

            if (startTime.hours === 0) {
              startTime.hours = 12;
            }

            const hours = `${startTime.hours < 10 ? '0' : ''}${startTime.hours}`;
            const minutes = `${startTime.minutes < 10 ? '0' : ''}${startTime.minutes}`;
            const time = `${hours}:${minutes}:00`;

            availableAppointmentStartTimesForDay.push({ time, meridiem });
          }
        }

        start += 15;
      }
    });

    return availableAppointmentStartTimesForDay;
  };

  const updateAvailability = () => {
    if (!scheduleTimezone || !schedule.schedule) {
      setAvailableDaysInCurrentMonth({});
    } else {
      const tomorrow = moment().add(1, 'day').startOf('day');
      const endDate = (startOfDisplayedCalendarMonth ? moment(startOfDisplayedCalendarMonth).endOf('month') : moment().endOf('month')).tz(scheduleTimezone);
      let startDate = startOfDisplayedCalendarMonth ? moment(startOfDisplayedCalendarMonth) : moment().startOf('month');

      if (startDate.isBefore(tomorrow, 'day')) {
        startDate = tomorrow;
      }

      const dateOverridesMap = {};
      const activeDaysMap = {};

      (schedule.dateOverrides || []).forEach(o => {
        o.dates.forEach(d => {
          dateOverridesMap[`${d}`] = o.availableTimes;
        });
      });

      (schedule.schedule || []).forEach(d => {
        if (d.active) {
          const availableTimes = [{
            startTime: d.startTime,
            startMeridiem: d.startMeridiem,
            endTime: d.endTime,
            endMeridiem: d.endMeridiem,
          }];

          (d.additionalTimes || []).forEach(t => {
            availableTimes.push(t);
          });

          activeDaysMap[d.day] = availableTimes;
        }
      });

      const updatedAvailableDaysInCurrentMonth = {};

      while (startDate.isBefore(moment(endDate).add(1, 'day'))) {
        let hasDateOverride = false;

        const appointmentsOnDay = staffScheduledAppointments.filter(a => {
          return moment(a.start).isSame(startDate, 'day');
        });

        for (let key in dateOverridesMap) {
          if (moment(+key).tz(scheduleTimezone).isSame(startDate, 'day')) {
            if (dateOverridesMap[key] && dateOverridesMap[key].length) {
              const availableAppointmentStartTimesForDay = getAvailableAppointmentStartTimesForDay(dateOverridesMap[key], appointmentsOnDay);

              if (availableAppointmentStartTimesForDay.length) {
                updatedAvailableDaysInCurrentMonth[`${moment(startDate).valueOf()}`] = availableAppointmentStartTimesForDay;
              }
            }

            hasDateOverride = true;
          }
        }

        if (!hasDateOverride) {
          const day = moment(startDate).format('dddd');

          if (activeDaysMap[day]) {
            const availableAppointmentStartTimesForDay = getAvailableAppointmentStartTimesForDay(activeDaysMap[day], appointmentsOnDay);

            if (availableAppointmentStartTimesForDay.length) {
              updatedAvailableDaysInCurrentMonth[`${moment(startDate).valueOf()}`] = availableAppointmentStartTimesForDay;
            }
          }
        }

        startDate = moment(startDate).add(1, 'day').startOf('day');
      }

      setAvailableDaysInCurrentMonth(updatedAvailableDaysInCurrentMonth);
    }
  };

  const submit = async (e) => {
    e.preventDefault();

    if (!firstName || !lastName) {
      setModalTitle('Notice!');
      setModalText('One or more required fields were empty. Please fill out all required fields.');
      return;
    }

    const emailValid = isValidEmail(email);
    const updatedPhone = phone.replace(/\D/g,'');
    const phoneValid = updatedPhone.length === 11;

    if (!emailValid && !phoneValid) {
      setModalTitle('Notice!');
      setModalText('A valid email address or phone number is required. Please enter a valid email address or phone number.');
      return;
    }

    setLoading(true);

    try {
      // handle customer
      const customerResponse = await axios.post(`${config.api}/api/v1/customer`, {
        firstName: firstName,
        lastName: lastName,
        email: email.trim().toLowerCase(),
        phone: updatedPhone,
        phoneExt: '',
        country: 'US',
        city: '',
        state: '',
        zip: '',
        address1: '',
        address2: '',
        group: '',
        businessId: business.id,
        notes: '',
        created: moment().valueOf(),
      });

      if (!customerResponse || !customerResponse.data || !customerResponse.data.id) {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('There was an error saving your info. Please try again.');
        return;
      }

      const start = moment(selectedDate).tz(scheduleTimezone);
      const end = moment(selectedDate).tz(scheduleTimezone);

      const startTimeData = timeStringsTo24Hour(selectedTime.time, selectedTime.meridiem);

      start.hour(startTimeData.hours).minutes(startTimeData.minutes).seconds(startTimeData.seconds);
      end.hour(startTimeData.hours).minutes(startTimeData.minutes).seconds(startTimeData.seconds);

      end.add(service.duration, 'minutes');

      const appointmentData = {
        start: start.valueOf(),
        end: end.valueOf(),
        businessId: business.id,
        color: colors[0],
        createdFrom: 'schedule-link',
        createdBy: staff[selectedStaffIndex].id,
        bookedFrom: 'schedule-link',
        customerId: customerResponse.data.id,
        customerName: `${firstName} ${lastName}`,
        noteToCustomer: '',
        staffId: staff[selectedStaffIndex].id,
        staffName: staff[selectedStaffIndex].id,
        noteToStaff: `This event was created by ${firstName} ${lastName} (${email.trim().toLowerCase()}) on your calendar.`,
        sendCustomerReminderEmail: emailValid,
        sendCustomerReminderText: phoneValid,
        sendStaffReminderEmail: true,
        sendStaffReminderText: false,
        staffReminderTime: moment(start).subtract(1, 'day').valueOf(),
        staffRemindersSent: false,
        customerReminderTime: moment(start).subtract(1, 'day').valueOf(),
        customerRemindersSent: false,
        recurringAppointmentId: '',
        created: Date.now(),
        serviceId: service.id || '',
      };

      const response = await axios.post(`${config.api}/api/v1/schedule/appointments`, appointmentData);


      const timeString = `${moment(start).format('dddd, MMMM Do')} from ${moment(start).format('h:mm')} to ${moment(end).format('h:mma')}`;

      await axios.post(`${config.api}/api/v1/notification/appointment-confirmation`,
        {
          customerFirstName: firstName,
          email: emailValid ? email.trim().toLowerCase() : '',
          phone: phoneValid ? updatedPhone : '',
          timeString,
          businessName: business.name,
          recurringMessage: '',
        }
      );

      setLoading(false);

      if (response && response.data && response.data.id) {
        setAppointmentScheduled(true);
      } else {
        setModalTitle('Error:');
        setModalText('There was an error creating your appointment. Please try again.');
      }
    } catch (e) {
      setLoading(false);

      if (e && e.response && e.response.data && e.response.data.message && e.response.data.message === 'this appointment has a time conflict') {
        setSelectNewTimeNotificationOpen(true);
        return;
      }

      setModalTitle('Error:');
      setModalText('There was an error submitting your appointment. Please try again.');
    }
  };

  const renderCalendarButton = () => {
    let address = '';

    if (business.name) {
      address += business.name;
    }

    if (business.address1) {
      address += `${address.length ? `, ${business.address1}` : business.address1}`;
    }

    if (business.address2) {
      address += `${address.length ? `, ${business.address2}` : business.address2}`;
    }

    if (business.city) {
      address += `${address.length ? `, ${business.city}` : business.city}`;
    }

    if (business.state) {
      address += `${address.length ? `, ${business.state}` : business.state}`;
    }

    if (business.country) {
      address += `${address.length ? `, ${business.country}` : business.country}`;
    }

    if (business.zip) {
      address += `${address.length ? `, ${business.zip}` : business.zip}`;
    }

    const eventDate = moment(selectedDate).format('YYYY-MM-DD');
    const startTime = timeStringsTo24Hour(selectedTime.time, selectedTime.meridiem);

    const serviceDuration = +service.duration;
    const hoursToAddToEnd = Math.floor(serviceDuration / 60);
    const minutesToAddToEnd = Math.floor(serviceDuration - (hoursToAddToEnd * 60));
    const endTime = {
      hours: startTime.hours + hoursToAddToEnd,
      minutes: startTime.minutes + minutesToAddToEnd,
    };

    return (
      <AddToCalendarButton
        name={service.name}
        options={['Apple', 'Google', 'Outlook.com', 'Yahoo']}
        location={address}
        startDate={eventDate}
        endDate={eventDate}
        startTime={`${startTime.hours < 10 ? '0' : ''}${startTime.hours}:${startTime.minutes < 10 ? '0' : ''}${startTime.minutes}`}
        endTime={`${endTime.hours < 10 ? '0' : ''}${endTime.hours}:${endTime.minutes < 10 ? '0' : ''}${endTime.minutes}`}
        timeZone={scheduleTimezone}
        hideBackground={true}
      ></AddToCalendarButton>
    );
  };

  const tileDisabled = ({ date, view }) => {
    if (view === 'month') {
      let isActiveDate = false;

      for (let key in availableDaysInCurrentMonth) {
        if (moment(+key).isSame(moment(date), 'day')) {
          isActiveDate = true;
        }
      }

      return !isActiveDate;
    }

    return true;
  };

  const getDisplayTime = (time, meridiem) => {
    const timeArr = time.split(':');
    const hourArr = timeArr[0].split('');
    const hour = hourArr[0] === '0' ? hourArr[1] : timeArr[0];
    const minute = timeArr[1];
    return`${hour}:${minute}${meridiem.toLowerCase()}`;
  };

  return (
    <div className="SchedulePicker">
      {!loading ? null : <Loading />}
      {showNotFound ?
        <BusinessNotFound/> :
        <>
          {appointmentScheduled ?
            <div className="scheduled-message-container">
              <h1>Success!</h1>
              <p>Your appointment has been scheduled and you will receive an email and/or text confirmation shortly. You will receive a reminder 24 hours before your scheduled appointment.</p>

              <div className="details-container">
                <p><strong>Details</strong></p>
                <p>{business.name}{selectedStaffIndex === -1 ? null : <> - {staff[selectedStaffIndex].firstName} {staff[selectedStaffIndex].lastName}</>}</p>
                <p>{service.name}</p>
                <p className="duration"><i className="fa fa-clock"></i> {service.duration} min</p>
                {!selectedTime ? null :
                  <>
                    <p className="duration"><i className="fa fa-calendar"></i> {getDisplayTime(selectedTime.time, selectedTime.meridiem)}, {moment(selectedDate).tz(scheduleTimezone).format('LL')}</p>
                    <p className="duration"><i className="fa fa-globe-americas"></i> {scheduleTimezone}</p>
                  </>
                }
                {renderCalendarButton()}
              </div>
            </div> :
            <>
              {!staff.length ? null :
                <div className="content-container">
                  <div className="left-container">
                    <p>{business.name}{selectedStaffIndex === -1 ? null : <> - {staff[selectedStaffIndex].firstName} {staff[selectedStaffIndex].lastName}</>}</p>
                    <h1>{service.name}</h1>
                    <p className="duration"><i className="fa fa-clock"></i> {service.duration} min</p>
                    {!selectedTime ? null :
                      <>
                        <p className="duration"><i className="fa fa-calendar"></i> {getDisplayTime(selectedTime.time, selectedTime.meridiem)}, {moment(selectedDate).tz(scheduleTimezone).format('LL')}</p>
                        <p className="duration"><i className="fa fa-globe-americas"></i> {scheduleTimezone}</p>
                      </>
                    }
                  </div>
                  <div className="right-container">
                    {selectedStaffIndex ===  -1 ?
                      <div className="staff-picker">
                        <h2>Select Staff Member</h2>
                        {staff.map((staffMember, i) => {
                          return (
                            <button
                              key={`staff-member-${i}`}
                              onClick={() => setSelectedStaffIndex(i)}
                            >
                              {staffMember.firstName} {staffMember.lastName}
                            </button>
                          );
                        })}
                      </div> :
                      <div>
                        {selectedTime ?
                          <div>
                            <button
                              className="back-button"
                              onClick={() => {
                                setSelectedTime(null);
                              }}
                            >
                              <i className="fa fa-arrow-left"></i> Change Time
                            </button>
                            <h2>Enter Details</h2>

                            <form onSubmit={submit}>
                              <div className="input-container">
                                <label>First Name *</label>
                                <input
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                  type="text"
                                  placeholder="Enter first name"
                                />
                              </div>

                              <div className="input-container">
                                <label>Last Name *</label>
                                <input
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                  type="text"
                                  placeholder="Enter last name"
                                />
                              </div>

                              <div className="input-container">
                                <label>Email</label>
                                <input
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  type="email"
                                  placeholder="example@domain.com"
                                />
                              </div>

                              <div className="input-container">
                                <label>Phone</label>
                                <PatternFormat
                                  format="+1 (###) ###-####"
                                  allowEmptyFormatting
                                  mask="_"
                                  placeholder="+1 (999) 999-9999"
                                  value={phone}
                                  onChange={(e) => setPhone(e.target.value)}
                                />
                              </div>

                              <div className="input-container">
                                <button
                                  type="submit"
                                >
                                  Schedule Appointment
                                </button>
                              </div>
                            </form>
                          </div> :
                          <>
                            {selectedDate ?
                              <div className="time-picker-container">
                                <button
                                  className="back-button"
                                  onClick={() => {
                                    const start = moment().tz(scheduleTimezone).startOf('day').valueOf();
                                    const end = moment().tz(scheduleTimezone).endOf('month').valueOf();

                                    setSelectedDate(0);
                                    setStartOfDisplayedCalendarMonth(moment().tz(scheduleTimezone).startOf('month').valueOf());
                                    fetchStaffMemberAppointments(start, end);
                                  }}
                                >
                                  <i className="fa fa-arrow-left"></i> Change Date
                                </button>
                                <h2>Select a Time</h2>

                                <div className="times-container">
                                  <div className="time-buttons-container">
                                    {(availableDaysInCurrentMonth[selectedDate] || []).map((entry, i) => {
                                      return (
                                        <button
                                          key={`time-${i}`}
                                          onClick={() => {
                                            setSelectedTime(entry);
                                          }}
                                        >
                                          {getDisplayTime(entry.time, entry.meridiem)}
                                        </button>
                                      );
                                    })}
                                  </div>
                                  <div>
                                    <p>Date: {moment(selectedDate).tz(scheduleTimezone).format('ll')}</p>
                                    <p>Time zone: {scheduleTimezone}</p>
                                  </div>
                                </div>
                              </div> :
                              <div>
                                {staff.length < 2 ? null :
                                  <button
                                    className="back-button"
                                    onClick={() => setSelectedStaffIndex(-1)}
                                  >
                                    <i className="fa fa-arrow-left"></i> Change Staff Member
                                  </button>
                                }
                                <h2>Select a Date</h2>
                                <div className="date-picker-container">
                                  <Calendar
                                    onChange={(newDate) => setSelectedDate(moment(newDate).tz(scheduleTimezone).startOf('day').valueOf())}
                                    onActiveStartDateChange={(data) => {
                                      const start = moment(data.activeStartDate).tz(scheduleTimezone).startOf('month').valueOf();
                                      const end = moment(data.activeStartDate).tz(scheduleTimezone).endOf('month').valueOf();
                                      fetchStaffMemberAppointments(start, end);
                                      setStartOfDisplayedCalendarMonth(start);
                                    }}
                                    minDate={minDate}
                                    prev2Label={<></>}
                                    next2Label={<></>}
                                    prevLabel={<i className="fas fa-chevron-left"></i>}
                                    nextLabel={<i className="fas fa-chevron-right"></i>}
                                    calendarType="US"
                                    view="month"
                                    showNeighboringMonth={false}
                                    tileDisabled={({ date, view }) => tileDisabled({ date, view })}
                                  />
                                </div>
                              </div>
                            }
                          </>
                        }
                      </div>
                    }
                  </div>
                </div>
              }
            </>
          }
        </>
      }

      <Modal
        open={selectNewTimeNotificationOpen}
        close={() => {
          const start = moment().tz(scheduleTimezone).startOf('day').valueOf();
          const end = moment().tz(scheduleTimezone).endOf('month').valueOf();

          setSelectedTime(null);
          setSelectedDate(0);
          setStartOfDisplayedCalendarMonth(moment().tz(scheduleTimezone).startOf('month').valueOf());
          fetchStaffMemberAppointments(start, end);
          setSelectNewTimeNotificationOpen(false);
        }}
        title="Notice:"
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            const start = moment().tz(scheduleTimezone).startOf('day').valueOf();
            const end = moment().tz(scheduleTimezone).endOf('month').valueOf();
  
            setSelectedTime(null);
            setSelectedDate(0);
            setStartOfDisplayedCalendarMonth(moment().tz(scheduleTimezone).startOf('month').valueOf());
            fetchStaffMemberAppointments(start, end);
            setSelectNewTimeNotificationOpen(false);
          }}>Select New Time</button>,
        ]}
      >
        <div>
          <div className="modal-text">The selected time has recently been taken. Please choose a different time and try again.</div>
        </div>
      </Modal>

      <Modal
        open={!!modalText}
        close={() => {
          setModalTitle('');
          setModalText('');
        }}
        title={modalTitle}
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            setModalTitle('');
            setModalText('');
          }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default SchedulePicker;
