import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './Home';

function Dashboard({}) {
  return (
    <div>
      <Switch>
        <Route path="/dashboard" component={Home} />
        <Route component={Home} />
      </Switch>
    </div>
  );
}

export default Dashboard;
