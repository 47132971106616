import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';

import './onboardingStep2.scss';
import { config } from '../../../../config';
import { times } from '../../../../util/times';
import { updateUser } from '../../../../store/actions';

function OnboardingStep2({ handleError }) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [saving, setSaving] = useState(false);
  const [hours, setHours] = useState([
    {
      day: 'Monday',
      startTime: '08:00:00',
      startMeridiem: 'AM',
      endTime: '05:00:00',
      endMeridiem: 'PM',
      active: true,
    },
    {
      day: 'Tuesday',
      startTime: '08:00:00',
      startMeridiem: 'AM',
      endTime: '05:00:00',
      endMeridiem: 'PM',
      active: true,
    },
    {
      day: 'Wednesday',
      startTime: '08:00:00',
      startMeridiem: 'AM',
      endTime: '05:00:00',
      endMeridiem: 'PM',
      active: true,
    },
    {
      day: 'Thursday',
      startTime: '08:00:00',
      startMeridiem: 'AM',
      endTime: '05:00:00',
      endMeridiem: 'PM',
      active: true,
    },
    {
      day: 'Friday',
      startTime: '08:00:00',
      startMeridiem: 'AM',
      endTime: '05:00:00',
      endMeridiem: 'PM',
      active: true,
    },
    {
      day: 'Saturday',
      startTime: '08:00:00',
      startMeridiem: 'AM',
      endTime: '05:00:00',
      endMeridiem: 'PM',
      active: false,
    },
    {
      day: 'Sunday',
      startTime: '08:00:00',
      startMeridiem: 'AM',
      endTime: '05:00:00',
      endMeridiem: 'PM',
      active: false,
    },
  ]);

  const moveToNextStep = async () => {
    if (saving) {
      return;
    }

    setSaving(true);

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();

      const response = await axios.post(`${config.api}/api/v1/business/schedule/${user.id}`,
        {
          businessId: user.id,
          schedule: hours,
          dateOverrides: [],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const userData = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: '',
        onboardingStep: 2,
        roles: user.roles,
        businessId: user.businessId,
        created: user.created,
      };

      await axios.put(`${config.api}/api/v1/user/${user.id}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch(updateUser(userData));
    } catch (e) {
      let errorMessage = 'There was an error saving this step. Please try again.';
      if (e && e.response && e.response.data && e.response.data.message) {
        errorMessage = e.response.data.message;
      }

      handleError('Error:', errorMessage);
      setSaving(false);
    }
  };

  return (
    <div className="OnboardingStep2">
      <div className="top-container">
        <h2>Set your working hours</h2>
      </div>

      <p>You will be able to update this and further customize your schedule later.</p>

      <table>
        <thead>
          <tr>
            <td>Day</td>
            <td className="active-cell">Active</td>
            <td>Start</td>
            <td>End</td>
          </tr>
        </thead>
        <tbody>
          {hours.map((entry, i) => {
            return (
              <tr key={`row-${i}`}>
                <td style={{ opacity: entry.active ? 1 : 0.5 }}>{entry.day}</td>
                <td className="active-cell">
                  <input
                    type="checkbox"
                    checked={entry.active}
                    onChange={(e) => {
                      const hoursCopy = [ ...hours ];
                      const entryCopy = {
                        ...entry,
                        active: e.target.checked,
                      };

                      hoursCopy[i] = entryCopy;
                      setHours(hoursCopy);
                    }}
                  />
                </td>
                <td className="time-cell">
                  <div className="time-container">
                    <select
                      value={entry.startTime}
                      onChange={(e) => {
                        const hoursCopy = [ ...hours ];
                        const entryCopy = {
                          ...entry,
                          startTime: e.target.value,
                        };

                        hoursCopy[i] = entryCopy;
                        setHours(hoursCopy);
                      }}
                      disabled={!entry.active}
                    >
                      {times.map(time => {
                        return <option key={time.value} value={time.value}>{time.display}</option>
                      })}
                    </select>
                    <select
                      value={entry.startMeridiem}
                      onChange={(e) => {
                        const hoursCopy = [ ...hours ];
                        const entryCopy = {
                          ...entry,
                          startMeridiem: e.target.value,
                        };

                        hoursCopy[i] = entryCopy;
                        setHours(hoursCopy);
                      }}
                      disabled={!entry.active}
                    >
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </td>
                <td className="time-cell">
                  <div className="time-container">
                    <select
                      value={entry.endTime}
                      onChange={(e) => {
                        const hoursCopy = [ ...hours ];
                        const entryCopy = {
                          ...entry,
                          endTime: e.target.value,
                        };

                        hoursCopy[i] = entryCopy;
                        setHours(hoursCopy);
                      }}
                      disabled={!entry.active}
                    >
                      {times.map(time => {
                        return <option key={time.value} value={time.value}>{time.display}</option>
                      })}
                    </select>
                    <select
                      value={entry.endMeridiem}
                      onChange={(e) => {
                        const hoursCopy = [ ...hours ];
                        const entryCopy = {
                          ...entry,
                          endMeridiem: e.target.value,
                        };

                        hoursCopy[i] = entryCopy;
                        setHours(hoursCopy);
                      }}
                      disabled={!entry.active}
                    >
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="actions-row">
        <Link
          to="/signin"
          onClick={() => {
            const auth = getAuth();
            auth.signOut();
          }}
        >
          Sign out
        </Link>

        <button
          className="success"
          onClick={moveToNextStep}
        >
          {saving ? 'Saving...' : 'Next'}
        </button>
      </div>
    </div>
  );
}

export default OnboardingStep2;
