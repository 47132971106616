import {
  SET_BUSINESS,
  UPDATE_BUSINESS,
} from './types';

export const setBusiness = (business) => {
  return {
    type: SET_BUSINESS,
    payload: business,
  };
};

export const updateBusiness = (businessData) => {
  return {
    type: UPDATE_BUSINESS,
    payload: businessData,
  };
};
