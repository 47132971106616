import React, { useState, useEffect } from 'react';
import { getAuth } from '@firebase/auth';
import axios from 'axios';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import Calendar from 'react-calendar';

import './availability.scss';
import { config } from '../../../../config';
import { times } from '../../../../util/times';
import { timezones } from '../../../../util/timezones';
import { defaultSchedule } from '../../../../util/defaultSchedule';
import { timeStringsTo24Hour } from '../../../../util/timeStringsTo24Hour';
import { setBusiness, setBusinessSchedule, setUserSchedules } from '../../../../store/actions';
import Modal from '../../../shared/Modal';

const cloneDeep = require('lodash.clonedeep');

const businessScheduleEditRole = 'owner';
const businessHoursScheduleName = 'Business Hours';

function Availability({ setLoading }) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const business = useSelector(state => state.business);
  const businessSchedule = useSelector(state => state.businessSchedule);
  const userSchedulesInitialized = useSelector(state => state.userSchedule.initialized);
  const userSchedules = useSelector(state => state.userSchedule.schedules);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [schedules, setSchedules] = useState([]);
  const [currentSchedule, setCurrentSchedule] = useState(cloneDeep(defaultSchedule));
  const [currentScheduleIndex, setCurrentScheduleIndex] = useState(-1);
  const [doesNotHaveEditAccess, setDoesNotHaveEditAccess] = useState(true);
  const [currentScheduleTimeErrors, setCurrentScheduleTimeErrors] = useState([]);
  const [dateOverridesBeingEdited, setDateOverridesBeingEdited] = useState({
    dates: [],
    availableTimes: [{
      startTime: '08:00:00',
      startMeridiem: 'AM',
      endTime: '05:00:00',
      endMeridiem: 'PM',
    }],
  });
  const [showDateOverridesModal, setShowDateOverridesModal] = useState(false);
  const [dateOverridesBeingEditedIndex, setDateOverridesBeingEditedIndex] = useState(-1);
  const [dateOverrideErrors, setDateOverrideErrors] = useState([]);
  const [deleteScheduleConfirmationOpen, setDeleteScheduleConfirmationOpen] = useState(false);

  useEffect(() => {
    if (user.id) {
      fetchSchedules();
    } else {
      setLoading(true);
    }
  }, [user.id]);

  useEffect(() => {
    if ((currentSchedule.id === businessSchedule.id && !user.roles.includes(businessScheduleEditRole))) {
      setDoesNotHaveEditAccess(true);
    } else {
      setDoesNotHaveEditAccess(false);
    }

    checkCurrentScheduleForErrors();
  }, [currentSchedule]);

  useEffect(() => {
    checkDateOverridesForErrors();
  }, [dateOverridesBeingEdited]);

  const fetchSchedules = async () => {
    setLoading(true);

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      let allSchedules = [];

      if (!userSchedulesInitialized) {
        const response = await axios.get(`${config.api}/api/v1/user/schedules/${user.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const usersSchedulesData = response.data || [];

        allSchedules = [ ...usersSchedulesData ];
        dispatch(setUserSchedules([ ...usersSchedulesData ]));
      } else {
        allSchedules = [ ...userSchedules ];
      }

      if (!businessSchedule.id) {
        const response = await axios.get(`${config.api}/api/v1/business/schedule/${user.businessId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response && response.data && response.data.id) {
          allSchedules.push({
            ...response.data,
            name: businessHoursScheduleName,
          });
          dispatch(setBusinessSchedule(response.data));
        } else {
          setLoading(false);
          setModalTitle('Error:');
          setModalText('There was an error retrieving the business schedule, please try again.');
          return;
        }
      } else {
        allSchedules.push({
          ...businessSchedule,
          name: businessHoursScheduleName,
        });
      }

      if (!business.id) {
        const response = await axios.get(`${config.api}/api/v1/business/${user.businessId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response && response.data && response.data.id) {
          dispatch(setBusiness(response.data));
        }
      }

      setSchedules(allSchedules);
      setCurrentSchedule(cloneDeep(allSchedules[0]));
      setCurrentScheduleIndex(0);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving your schedules, please refresh the page and try again.');
    }
  };

  const addAdditionalTimes = (i) => {
    const scheduleCopy = cloneDeep(currentSchedule);
    const additionalTimes = scheduleCopy.schedule[i].additionalTimes || [];

    const scheduleTimes = [{
      startTime: scheduleCopy.schedule[i].startTime,
      startMeridiem: scheduleCopy.schedule[i].startMeridiem,
      endTime: scheduleCopy.schedule[i].endTime,
      endMeridiem: scheduleCopy.schedule[i].endMeridiem,
    }];

    (additionalTimes).forEach((additional) => {
      scheduleTimes.push({
        startTime: additional.startTime,
        startMeridiem: additional.startMeridiem,
        endTime: additional.endTime,
        endMeridiem: additional.endMeridiem,
      });
    });

    scheduleTimes.sort((first, second) => {
      const firstEndTime24Hour = timeStringsTo24Hour(first.endTime, first.endMeridiem, { returnAsMinutes: true });
      const secondEndTime24Hour = timeStringsTo24Hour(second.endTime, second.endMeridiem, { returnAsMinutes: true });

      return firstEndTime24Hour - secondEndTime24Hour;
    });

    let newStartTime = '08:00:00';
    let newStartMeridiem = 'AM';
    let newEndTime = '05:00:00';
    let newEndMeridiem = 'PM';

    const latestEndTime = {
      time: scheduleTimes[scheduleTimes.length - 1].endTime,
      meridiem: scheduleTimes[scheduleTimes.length - 1].endMeridiem,
    };

    const timeIndex = times.findIndex(t => t.value === latestEndTime.time);

    if (timeIndex !== -1 && times[timeIndex + 1] && times[timeIndex + 2]) {
      newStartTime = times[timeIndex + 1].value;
      newStartMeridiem = latestEndTime.meridiem;
      newEndTime = times[timeIndex + 2].value;
      newEndMeridiem = latestEndTime.meridiem;
    }

    additionalTimes.push({
      startTime: newStartTime,
      startMeridiem: newStartMeridiem,
      endTime: newEndTime,
      endMeridiem: newEndMeridiem,
    });

    scheduleCopy.schedule[i].additionalTimes = additionalTimes;
    setCurrentSchedule(scheduleCopy);
  };

  const addOverrideTimes = () => {
    const timesCopy = [ ...dateOverridesBeingEdited.availableTimes ];
    let newStartTime = '08:00:00';
    let newStartMeridiem = 'AM';
    let newEndTime = '05:00:00';
    let newEndMeridiem = 'PM';

    if (timesCopy.length) {
      timesCopy.sort((first, second) => {
        const firstEndTime24Hour = timeStringsTo24Hour(first.endTime, first.endMeridiem, { returnAsMinutes: true });
        const secondEndTime24Hour = timeStringsTo24Hour(second.endTime, second.endMeridiem, { returnAsMinutes: true });

        return firstEndTime24Hour - secondEndTime24Hour;
      });

      const latestEndTime = {
        time: timesCopy[timesCopy.length - 1].endTime,
        meridiem: timesCopy[timesCopy.length - 1].endMeridiem,
      };

      const timeIndex = times.findIndex(t => t.value === latestEndTime.time);

      if (timeIndex !== -1 && times[timeIndex + 1] && times[timeIndex + 2]) {
        newStartTime = times[timeIndex + 1].value;
        newStartMeridiem = latestEndTime.meridiem;
        newEndTime = times[timeIndex + 2].value;
        newEndMeridiem = latestEndTime.meridiem;
      }
    }

    timesCopy.push({
      startTime: newStartTime,
      startMeridiem: newStartMeridiem,
      endTime: newEndTime,
      endMeridiem: newEndMeridiem,
    });
    setDateOverridesBeingEdited({
      ...dateOverridesBeingEdited,
      availableTimes: timesCopy,
    });
  };

  const applyDateOverrides = () => {
    const timezone = currentSchedule.timezone || business.timezone || timezones[0];
    const dateOverride = {
      dates: dateOverridesBeingEdited.dates.map((d) => moment(d).tz(timezone).valueOf()),
      availableTimes: dateOverridesBeingEdited.availableTimes.map(t => {
        return { ...t };
      }),
    };
    const dateOverrides = [ ...(currentSchedule.dateOverrides || []) ];

    dateOverrides[dateOverridesBeingEditedIndex] = dateOverride;

    setCurrentSchedule({
      ...currentSchedule,
      dateOverrides,
    });
    setShowDateOverridesModal(false);
    setDateOverridesBeingEditedIndex(-1);
  };

  const checkIfHoursOverlap = (first, second) => {
    const config = { returnAsMinutes: true };
    const firstStart24Hour = timeStringsTo24Hour(first.startTime, first.startMeridiem, config);
    const firstEnd24Hour = timeStringsTo24Hour(first.endTime, first.endMeridiem, config);
    const secondStart24Hour = timeStringsTo24Hour(second.startTime, second.startMeridiem, config);
    const secondEnd24Hour = timeStringsTo24Hour(second.endTime, second.endMeridiem, config);

    if ((secondStart24Hour >= firstStart24Hour && secondStart24Hour <= firstEnd24Hour) || (secondEnd24Hour >= firstStart24Hour && secondEnd24Hour <= firstEnd24Hour)) {
      return true;
    }
  };

  const checkCurrentScheduleForErrors = () => {
    const scheduleTimeErrors = [];

    currentSchedule.schedule.forEach((schedule, i) => {
      const scheduleTimes = [{
        startTime: schedule.startTime,
        startMeridiem: schedule.startMeridiem,
        endTime: schedule.endTime,
        endMeridiem: schedule.endMeridiem,
      }];

      (schedule.additionalTimes || []).forEach((additionalTimes) => {
        scheduleTimes.push({
          startTime: additionalTimes.startTime,
          startMeridiem: additionalTimes.startMeridiem,
          endTime: additionalTimes.endTime,
          endMeridiem: additionalTimes.endMeridiem,
        });
      });

      scheduleTimes.forEach((entry, j) => {
        const { startTime, startMeridiem, endTime, endMeridiem } = entry;
        let endTimeError = false;

        if (startMeridiem === 'PM' && endMeridiem === 'AM') {
          endTimeError = true;
        } else if (startMeridiem === endMeridiem) {
          const startTimeArr = startTime.split(':');
          const startTimeHour = +startTimeArr[0];
          const startTimeMinutes = +startTimeArr[1];

          if (startTimeHour === 12) {
            const endTimeArr = endTime.split(':');
            const endTimeHour = +endTimeArr[0];
            const endTimeMinutes = +endTimeArr[1];

            if (+endTimeHour === 12 && (+endTimeMinutes <= startTimeMinutes)) {
              endTimeError = true;
            }
          } else {
            const startTimeIndex = times.findIndex((time) => time.value === startTime);
            const endTimeIndex = times.findIndex((time) => time.value === endTime);

            if (endTimeIndex <= startTimeIndex) {
              endTimeError = true;
            }
          }
        }

        if (endTimeError) {
          scheduleTimeErrors.push({
            dayIndex: i,
            timeIndex: j,
            error: 'End Time must be after Start Time.',
          });
        } else {
          for (let k = 0; k < scheduleTimes.length; k++) {
            if (k === j) {
              continue;
            }
            const entryToCheckAgainst = scheduleTimes[k];
            const currentHours = {
              startTime,
              startMeridiem,
              endTime,
              endMeridiem,
            };
            const hoursToCheckAgainst = {
              startTime: entryToCheckAgainst.startTime,
              startMeridiem: entryToCheckAgainst.startMeridiem,
              endTime: entryToCheckAgainst.endTime,
              endMeridiem: entryToCheckAgainst.endMeridiem,
            };

            const hasOverlappingHours = checkIfHoursOverlap(currentHours, hoursToCheckAgainst);

            if (hasOverlappingHours) {
              scheduleTimeErrors.push({
                dayIndex: i,
                timeIndex: j,
                error: 'Times overlap with another set of times.',
              });
            }
          }
        }
      });
    });

    setCurrentScheduleTimeErrors(scheduleTimeErrors);
  };

  const checkDateOverridesForErrors = () => {
    const overrideTimeErrors = [];

    dateOverridesBeingEdited.availableTimes.forEach((entry, i) => {
      const { startTime, startMeridiem, endTime, endMeridiem } = entry;
      let endTimeError = false;

      if (startMeridiem === 'PM' && endMeridiem === 'AM') {
        endTimeError = true;
      } else if (startMeridiem === endMeridiem) {
        const startTimeArr = startTime.split(':');
        const startTimeHour = +startTimeArr[0];
        const startTimeMinutes = +startTimeArr[1];

        if (startTimeHour === 12) {
          const endTimeArr = endTime.split(':');
          const endTimeHour = +endTimeArr[0];
          const endTimeMinutes = +endTimeArr[1];

          if (+endTimeHour === 12 && (+endTimeMinutes <= startTimeMinutes)) {
            endTimeError = true;
          }
        } else {
          const startTimeIndex = times.findIndex((time) => time.value === startTime);
          const endTimeIndex = times.findIndex((time) => time.value === endTime);

          if (endTimeIndex <= startTimeIndex) {
            endTimeError = true;
          }
        }
      }

      if (endTimeError) {
        overrideTimeErrors.push({
          timeIndex: i,
          error: 'End Time must be after Start Time.',
        });
      } else {
        for (let j = 0; j < dateOverridesBeingEdited.availableTimes.length; j++) {
          if (j === i) {
            continue;
          }
          const entryToCheckAgainst = dateOverridesBeingEdited.availableTimes[j];
          const currentHours = {
            startTime,
            startMeridiem,
            endTime,
            endMeridiem,
          };
          const hoursToCheckAgainst = {
            startTime: entryToCheckAgainst.startTime,
            startMeridiem: entryToCheckAgainst.startMeridiem,
            endTime: entryToCheckAgainst.endTime,
            endMeridiem: entryToCheckAgainst.endMeridiem,
          };

          const hasOverlappingHours = checkIfHoursOverlap(currentHours, hoursToCheckAgainst);

          if (hasOverlappingHours) {
            overrideTimeErrors.push({
              timeIndex: i,
              error: 'Times overlap with another set of times.',
            });
          }
        }
      }
    });

    setDateOverrideErrors(overrideTimeErrors);
  };

  const save = async () => {
    if (!currentSchedule.name) {
      setModalTitle('Error:');
      setModalText('Schedule name is required. Please provide a schedule name.');
      return;
    }

    if (currentScheduleTimeErrors.length) {
      setModalTitle('Error:');
      setModalText('There are errors with the selected times for this schedule. Please update the selected times.');
      return;
    }

    setLoading(true);

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const isBusinessSchedule = currentSchedule.id && currentSchedule.businessId;
      const scheduleToSave = {
        schedule: currentSchedule.schedule,
        dateOverrides: currentSchedule.dateOverrides || [],
      };
      const updatedSchedules = [ ...schedules ];

      if (isBusinessSchedule) {
        scheduleToSave.businessId = currentSchedule.businessId;

        await axios.put(`${config.api}/api/v1/business/schedule/${currentSchedule.id}`,
          scheduleToSave,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        updatedSchedules[currentScheduleIndex] = {
          ...updatedSchedules[currentScheduleIndex],
          ...scheduleToSave,
        };

        setSchedules(updatedSchedules);
        dispatch(setBusinessSchedule({
          ...businessSchedule,
          schedule: currentSchedule.schedule,
          dateOverrides: currentSchedule.dateOverrides || [],
        }));
      } else {
        scheduleToSave.userId = currentSchedule.userId || user.id;
        scheduleToSave.name = currentSchedule.name;
        scheduleToSave.timezone = currentSchedule.timezone;

        const updatedUserSchedules = [ ...userSchedules ];

        if (!currentSchedule.id) {
          const result = await axios.post(`${config.api}/api/v1/user/schedule/${user.id}`,
            scheduleToSave,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          updatedUserSchedules.push({
            ...currentSchedule,
            id: result.data.id,
          });
          scheduleToSave.id = result.data.id;
          setCurrentSchedule({
            ...currentSchedule,
            id: result.data.id,
          });
        } else {
          await axios.put(`${config.api}/api/v1/user/schedule/${currentSchedule.id}`,
            scheduleToSave,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const foundIndex = userSchedules.findIndex(s => s.id === currentSchedule.id);
          updatedUserSchedules[foundIndex] = {
            ...currentSchedule,
          };
        }

        updatedSchedules[currentScheduleIndex] = {
          ...updatedSchedules[currentScheduleIndex],
          ...scheduleToSave,
        };

        setSchedules(updatedSchedules);
        dispatch(setUserSchedules(updatedUserSchedules));
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error saving this schedule. Please try again.');
    }
  };

  const deleteSchedule = async () => {
    setDeleteScheduleConfirmationOpen(false);
    setLoading(true);

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const updatedUserSchedules = [ ...userSchedules ];
      const updatedSchedules = [ ...schedules ];

      await axios.delete(`${config.api}/api/v1/user/schedule/${currentSchedule.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const foundIndex = userSchedules.findIndex(s => s.id === currentSchedule.id);
      updatedUserSchedules.splice(foundIndex, 1);

      updatedSchedules.splice(currentScheduleIndex, 1);

      setCurrentSchedule(cloneDeep(updatedSchedules[currentScheduleIndex]));
      setSchedules(updatedSchedules);
      dispatch(setUserSchedules(updatedUserSchedules));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error deleting this schedule. Please try again.');
    }
  };

  const onCalendarChange = (clickedDate) => {
    const updatedDates = [ ...dateOverridesBeingEdited.dates ];
    const currentDate = moment(clickedDate);
    const selectedDateIndex = updatedDates.findIndex(d => moment(d).isSame(currentDate, 'day'));

    if (selectedDateIndex === -1) {
      updatedDates.push(clickedDate);
    } else {
      updatedDates.splice(selectedDateIndex, 1);
    }

    setDateOverridesBeingEdited({
      ...dateOverridesBeingEdited,
      dates: updatedDates,
    });
  };

  const renderTimeError = (dayIndex, timeIndex) => {
    let error = '';

    currentScheduleTimeErrors.forEach(e => {
      if (e.dayIndex === dayIndex && e.timeIndex === timeIndex) {
        error = e.error;
      }
    });

    if (!error) {
      return null;
    }

    return <p className="time-error">{error}</p>;
  };

  const renderDateOverrideTimeError = (timeIndex) => {
    let error = '';

    dateOverrideErrors.forEach(e => {
      if (e.timeIndex === timeIndex) {
        error = e.error;
      }
    });

    if (!error) {
      return null;
    }

    return <p className="time-error">{error}</p>;
  };

  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      if (dateOverridesBeingEdited.dates.find(d => moment(d).isSame(moment(date), 'day'))) {
        return 'date-selected-for-override';
      }
    }
  };

  return (
    <div className="Availability">
      <>
        <div className="header-container">
          <h1>Availability</h1>

          <button
            className="small success"
            onClick={() => {
              const newSchedule = cloneDeep({
                ...defaultSchedule,
                name: `Schedule ${schedules.length + 1}`,
                timezone: business.timezone || timezones[0],
              });

              setSchedules([ newSchedule, ...schedules ]);
              setCurrentSchedule(cloneDeep(newSchedule));
              setCurrentScheduleIndex(0);
            }}
          >
            New Schedule <i className="fas fa-plus"></i>
          </button>
        </div>

        {currentScheduleIndex === -1 ? null :
          <div>
            <div className="input-container">
              <label>Schedule:</label>
              <select
                value={currentScheduleIndex}
                onChange={(e) => {
                  const value = e.target.value;
                  setCurrentScheduleIndex(value);
                  setCurrentSchedule(cloneDeep(schedules[value]));
                }}
              >
                {schedules.map((schedule, i) => {
                  return <option key={`schedule-${i}`} value={i}>{schedule.name}</option>
                })}
              </select>
            </div>

            <div className="schedule-container">
              {doesNotHaveEditAccess ?
                <p className="permission-message">
                  You do not have permission to edit this schedule.
                </p> : null
              }
              <div className="schedule-top-row">
                <div className="input-container">
                  <label>Name:</label>
                  <input
                    value={currentSchedule.name}
                    onChange={(e) => setCurrentSchedule({ ...currentSchedule, name: e.target.value })}
                    placeholder="Schedule name"
                    disabled={currentSchedule.id === businessSchedule.id}
                  />
                </div>

                <button
                  onClick={save}
                  disabled={doesNotHaveEditAccess}
                >
                  Save
                </button>
              </div>

              {currentSchedule.id === businessSchedule.id ?
                <p className="timezone-message">The timezone for this schedule defaults to your business timezone and can be changed in settings.</p> :
                <div className="input-container">
                  <label>Timezone:</label>
                  <select
                    value={currentSchedule.timezone}
                    onChange={(e) => setCurrentSchedule({ ...currentSchedule, timezone: e.target.value })}
                  >
                    {timezones.map((timezone, i) => {
                      return <option key={`timezone-${i}`} value={timezone}>{timezone}</option>
                    })}
                  </select>
                </div>
              }

              <div className="times-container">
                <div className="hours-container">
                  {currentSchedule.schedule.map((entry, i) => {
                    return (
                      <div key={`row-${i}`} className="hours-row">
                        <div className="hour-cell active-cell">
                          <input
                            type="checkbox"
                            disabled={doesNotHaveEditAccess}
                            checked={entry.active}
                            onChange={(e) => {
                              const schedule = [ ...currentSchedule.schedule ];
                              const entryCopy = {
                                ...entry,
                                active: e.target.checked,
                              };

                              schedule[i] = entryCopy;
                              setCurrentSchedule({ ...currentSchedule, schedule });
                            }}
                          />
                        </div>
                        <div className={`hour-cell day-cell${entry.active ? '' : ' inactive'}`}>
                          {entry.day.substring(0, 3)}
                        </div>
                        <div className="times-column">
                          <div className="times-row">
                            <div className="hour-cell time-cell">
                              <div className="time-container">
                                <select
                                  value={entry.startTime}
                                  onChange={(e) => {
                                    const schedule = [ ...currentSchedule.schedule ];
                                    const entryCopy = {
                                      ...entry,
                                      startTime: e.target.value,
                                    };

                                    schedule[i] = entryCopy;
                                    setCurrentSchedule({ ...currentSchedule, schedule });
                                  }}
                                  disabled={!entry.active || doesNotHaveEditAccess}
                                >
                                  {times.map(time => {
                                    return <option key={time.value} value={time.value}>{time.display}</option>
                                  })}
                                </select>
                                <select
                                  value={entry.startMeridiem}
                                  onChange={(e) => {
                                    const schedule = [ ...currentSchedule.schedule ];
                                    const entryCopy = {
                                      ...entry,
                                      startMeridiem: e.target.value,
                                    };

                                    schedule[i] = entryCopy;
                                    setCurrentSchedule({ ...currentSchedule, schedule });
                                  }}
                                  disabled={!entry.active || doesNotHaveEditAccess}
                                >
                                  <option value="AM">AM</option>
                                  <option value="PM">PM</option>
                                </select>
                              </div>
                            </div>
                            <div className="hour-cell time-cell">
                              <div className="time-container">
                                <select
                                  value={entry.endTime}
                                  onChange={(e) => {
                                    const schedule = [ ...currentSchedule.schedule ];
                                    const entryCopy = {
                                      ...entry,
                                      endTime: e.target.value,
                                    };

                                    schedule[i] = entryCopy;
                                    setCurrentSchedule({ ...currentSchedule, schedule });
                                  }}
                                  disabled={!entry.active || doesNotHaveEditAccess}
                                >
                                  {times.map(time => {
                                    return <option key={time.value} value={time.value}>{time.display}</option>
                                  })}
                                </select>
                                <select
                                  value={entry.endMeridiem}
                                  onChange={(e) => {
                                    const schedule = [ ...currentSchedule.schedule ];
                                    const entryCopy = {
                                      ...entry,
                                      endMeridiem: e.target.value,
                                    };

                                    schedule[i] = entryCopy;
                                    setCurrentSchedule({ ...currentSchedule, schedule });
                                  }}
                                  disabled={!entry.active || doesNotHaveEditAccess}
                                >
                                  <option value="AM">AM</option>
                                  <option value="PM">PM</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          {renderTimeError(i, 0)}
                          {(currentSchedule.schedule[i].additionalTimes || []).map((additionalTime, j) => {
                            return (
                              <div key={`additional-times-${i}-${j}`}>
                                <div className="times-row">
                                  <div className="hour-cell time-cell">
                                    <div className="time-container">
                                      <select
                                        value={additionalTime.startTime}
                                        onChange={(e) => {
                                          const schedule = [ ...currentSchedule.schedule ];
                                          const entryCopy = {
                                            ...schedule[i],
                                            additionalTimes: [ ...schedule[i].additionalTimes ],
                                          };

                                          entryCopy.additionalTimes[j] = {
                                            ...entryCopy.additionalTimes[j],
                                            startTime: e.target.value,
                                          };

                                          schedule[i] = entryCopy;
                                          setCurrentSchedule({ ...currentSchedule, schedule });
                                        }}
                                        disabled={!entry.active || doesNotHaveEditAccess}
                                      >
                                        {times.map(time => {
                                          return <option key={time.value} value={time.value}>{time.display}</option>
                                        })}
                                      </select>
                                      <select
                                        value={additionalTime.startMeridiem}
                                        onChange={(e) => {
                                          const schedule = [ ...currentSchedule.schedule ];
                                          const entryCopy = {
                                            ...schedule[i],
                                            additionalTimes: [ ...schedule[i].additionalTimes ],
                                          };

                                          entryCopy.additionalTimes[j] = {
                                            ...entryCopy.additionalTimes[j],
                                            startMeridiem: e.target.value,
                                          };

                                          schedule[i] = entryCopy;
                                          setCurrentSchedule({ ...currentSchedule, schedule });
                                        }}
                                        disabled={!entry.active || doesNotHaveEditAccess}
                                      >
                                        <option value="AM">AM</option>
                                        <option value="PM">PM</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="hour-cell time-cell">
                                    <div className="time-container">
                                      <select
                                        value={additionalTime.endTime}
                                        onChange={(e) => {
                                          const schedule = [ ...currentSchedule.schedule ];
                                          const entryCopy = {
                                            ...schedule[i],
                                            additionalTimes: [ ...schedule[i].additionalTimes ],
                                          };

                                          entryCopy.additionalTimes[j] = {
                                            ...entryCopy.additionalTimes[j],
                                            endTime: e.target.value,
                                          };

                                          schedule[i] = entryCopy;
                                          setCurrentSchedule({ ...currentSchedule, schedule });
                                        }}
                                        disabled={!entry.active || doesNotHaveEditAccess}
                                      >
                                        {times.map(time => {
                                          return <option key={time.value} value={time.value}>{time.display}</option>
                                        })}
                                      </select>
                                      <select
                                        value={additionalTime.endMeridiem}
                                        onChange={(e) => {
                                          const schedule = [ ...currentSchedule.schedule ];
                                          const entryCopy = {
                                            ...schedule[i],
                                            additionalTimes: [ ...schedule[i].additionalTimes ],
                                          };

                                          entryCopy.additionalTimes[j] = {
                                            ...entryCopy.additionalTimes[j],
                                            endMeridiem: e.target.value,
                                          };

                                          schedule[i] = entryCopy;
                                          setCurrentSchedule({ ...currentSchedule, schedule });
                                        }}
                                        disabled={!entry.active || doesNotHaveEditAccess}
                                      >
                                        <option value="AM">AM</option>
                                        <option value="PM">PM</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="remove-additional-time">
                                    <button
                                      disabled={doesNotHaveEditAccess}
                                      onClick={() => {
                                        const schedule = [ ...currentSchedule.schedule ];
                                        const entryCopy = {
                                          ...schedule[i],
                                          additionalTimes: [ ...schedule[i].additionalTimes ],
                                        };

                                        entryCopy.additionalTimes.splice(j, 1);

                                        schedule[i] = entryCopy;
                                        setCurrentSchedule({ ...currentSchedule, schedule });
                                      }}
                                    >
                                      <i className="fas fa-trash"></i>
                                    </button>
                                  </div>
                                </div>
                                {renderTimeError(i, j + 1)}
                              </div>
                            );
                          })}
                        </div>
                        <div className="hour-cell add-cell">
                          <button
                            disabled={doesNotHaveEditAccess}
                            onClick={() => addAdditionalTimes(i)}
                          >
                            <span>Add Hours </span><i className="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="date-overrides">
                  <p className="date-overrides-title">Date overrides</p>
                  <p className="date-overrides-description">Add dates when your hours deviate from your regular schedule</p>

                  <button
                    className="add-date-override-button"
                    disabled={doesNotHaveEditAccess}
                    onClick={() => {
                      const dateOverrides = currentSchedule.dateOverrides || [];
                      setDateOverridesBeingEditedIndex(dateOverrides.length);
                      setDateOverridesBeingEdited({
                        dates: [],
                        availableTimes: [{
                          startTime: '08:00:00',
                          startMeridiem: 'AM',
                          endTime: '05:00:00',
                          endMeridiem: 'PM',
                        }],
                      });
                      setShowDateOverridesModal(true);
                    }}
                  >
                    Add Date Override <i className="fas fa-plus"></i>
                  </button>

                  {(currentSchedule.dateOverrides || []).map((override, i) => {
                    return (
                      <div key={`date-override-${i}`} className="date-override-item">
                        <div className="date-override-headers">
                          <p>Dates</p>
                          <p>Times</p>
                        </div>
                        <div className="date-override-details">
                          <div className="date-override-column">
                            {([ ...override.dates ]).sort((a, b) => a - b).map((d, j) => {
                              return (
                                <p key={`override-date-${i}-${j}`}>
                                  {moment(d).format('MMM DD, YYYY')}
                                </p>
                              );
                            })}
                          </div>
                          <div className="date-override-column">
                            {(override.availableTimes && override.availableTimes.length) ?
                              <>
                                {override.availableTimes.map((t, j) => {
                                  const startTimeArr = t.startTime.split(':');
                                  const endTimeArr = t.endTime.split(':');
                                  return (
                                    <p key={`override-times-${i}-${j}`}>
                                      {+startTimeArr[0]}:{startTimeArr[1]}{t.startMeridiem} - {+endTimeArr[0]}:{endTimeArr[1]}{t.endMeridiem}
                                    </p>
                                  );
                                })}
                              </> :
                              <p>Unavailable</p>
                            }
                          </div>
                        </div>

                        <div className="actions-row">
                          <button
                            disabled={doesNotHaveEditAccess}
                            onClick={() => {
                              const dateOverrides = [ ...currentSchedule.dateOverrides ];

                              dateOverrides.splice(i, 1);

                              setCurrentSchedule({
                                ...currentSchedule,
                                dateOverrides,
                              });
                            }}
                          >
                            <i className="fas fa-trash"></i>
                          </button>

                          <button
                            onClick={() => {
                              const timezone = currentSchedule.timezone || business.timezone || timezones[0];
                              setDateOverridesBeingEditedIndex(i);
                              setDateOverridesBeingEdited({
                                dates: override.dates.map((d) => moment(d).tz(timezone).toDate()),
                                availableTimes: (override.availableTimes || []).map(t => {
                                  return { ...t };
                                }),
                              });
                              setShowDateOverridesModal(true);
                            }}
                            disabled={doesNotHaveEditAccess}
                          >
                            <i className="fas fa-edit"></i>
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {(currentSchedule.id === businessSchedule.id || !currentSchedule.id) ? null :
              <div className="delete-schedule-button-container">
                <button
                  className="danger"
                  onClick={() => setDeleteScheduleConfirmationOpen(true)}
                >
                  Delete Schedule <i className="fas fa-trash"></i>
                </button>
              </div>
            }
          </div>
        }
      </>

      <Modal
        open={showDateOverridesModal}
        close={() => setShowDateOverridesModal(false)}
        title="Date Overrides"
        buttons={[
          <button
            key="modal-apply"
            className="small success"
            onClick={() => applyDateOverrides()}
            disabled={!dateOverridesBeingEdited.dates.length || dateOverrideErrors.length}
          >
            Apply
          </button>,
          <button
            key="modal-cancel"
            className="small"
            onClick={() => setShowDateOverridesModal(false)}
          >
            Cancel
          </button>,
        ]}
      >
        <div className="date-overrides-modal">
          <p className="date-overrides-modal-description">Select dates to override</p>
          <div className="date-overrides-modal-calendar-picker-container">
            <Calendar
              onChange={onCalendarChange}
              value={dateOverridesBeingEdited.dates}
              prev2Label={<></>}
              next2Label={<></>}
              prevLabel={<i className="fas fa-chevron-left"></i>}
              nextLabel={<i className="fas fa-chevron-right"></i>}
              calendarType="US"
              showNeighboringMonth={false}
              tileClassName={tileClassName}
            />
          </div>

          {!dateOverridesBeingEdited.dates.length ? null :
            <div className="date-overrides-modal-select-hours-container">
              <p className="date-overrides-modal-description">Set hours you are available</p>

              {dateOverridesBeingEdited.availableTimes.map((entry, i) => {
                return (
                  <div key={`date-override-available-times-${i}`}>
                    <div className="time-entry-row">
                      <div className="times-row">
                        <div className="time-cell">
                          <div className="time-container">
                            <select
                              value={entry.startTime}
                              onChange={(e) => {
                                const timesCopy = [ ...dateOverridesBeingEdited.availableTimes ];
                                const entryCopy = {
                                  ...entry,
                                  startTime: e.target.value,
                                };

                                timesCopy[i] = entryCopy;
                                setDateOverridesBeingEdited({
                                  ...dateOverridesBeingEdited,
                                  availableTimes: timesCopy,
                                });
                              }}
                            >
                              {times.map(time => {
                                return <option key={time.value} value={time.value}>{time.display}</option>
                              })}
                            </select>
                            <select
                              value={entry.startMeridiem}
                              onChange={(e) => {
                                const timesCopy = [ ...dateOverridesBeingEdited.availableTimes ];
                                const entryCopy = {
                                  ...entry,
                                  startMeridiem: e.target.value,
                                };

                                timesCopy[i] = entryCopy;
                                setDateOverridesBeingEdited({
                                  ...dateOverridesBeingEdited,
                                  availableTimes: timesCopy,
                                });
                              }}
                            >
                              <option value="AM">AM</option>
                              <option value="PM">PM</option>
                            </select>
                          </div>
                        </div>
                        <div className="time-cell">
                          <div className="time-container">
                            <select
                              value={entry.endTime}
                              onChange={(e) => {
                                const timesCopy = [ ...dateOverridesBeingEdited.availableTimes ];
                                const entryCopy = {
                                  ...entry,
                                  endTime: e.target.value,
                                };

                                timesCopy[i] = entryCopy;
                                setDateOverridesBeingEdited({
                                  ...dateOverridesBeingEdited,
                                  availableTimes: timesCopy,
                                });
                              }}
                            >
                              {times.map(time => {
                                return <option key={time.value} value={time.value}>{time.display}</option>
                              })}
                            </select>
                            <select
                              value={entry.endMeridiem}
                              onChange={(e) => {
                                const timesCopy = [ ...dateOverridesBeingEdited.availableTimes ];
                                const entryCopy = {
                                  ...entry,
                                  endMeridiem: e.target.value,
                                };

                                timesCopy[i] = entryCopy;
                                setDateOverridesBeingEdited({
                                  ...dateOverridesBeingEdited,
                                  availableTimes: timesCopy,
                                });
                              }}
                            >
                              <option value="AM">AM</option>
                              <option value="PM">PM</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="delete-container">
                        <button
                          onClick={() => {
                            const timesCopy = [ ...dateOverridesBeingEdited.availableTimes ];

                            timesCopy.splice(i, 1);

                            setDateOverridesBeingEdited({
                              ...dateOverridesBeingEdited,
                              availableTimes: timesCopy,
                            });
                          }}
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </div>
                    </div>

                    {renderDateOverrideTimeError(i)}
                  </div>
                );
              })}

              <div className="add-availability-container">
                {dateOverridesBeingEdited.availableTimes.length ? null :
                  <div className="unavailable-message-container">
                    <p>Unavailable</p>
                  </div>
                }

                <div className="add-override-times-button-container">
                  <button
                    onClick={() => addOverrideTimes()}
                  >
                    Add Hours <i className="fas fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
      </Modal>

      <Modal
        open={deleteScheduleConfirmationOpen}
        close={() => setDeleteScheduleConfirmationOpen(false)}
        title="Delete Schedule?"
        buttons={[
          <button key="modal-confirm" className="small danger" onClick={() => {
            deleteSchedule();
          }}>Confirm</button>,
          <button key="modal-cancel" className="small" onClick={() => {
            setDeleteScheduleConfirmationOpen(false);
          }}>Cancel</button>,
        ]}
      >
        <div>
          <div className="modal-text">Are you sure you want to delete {currentSchedule.name ? <strong>{currentSchedule.name}</strong> : 'this schedule'}? This action cannot be undone.</div>
        </div>
      </Modal>

      <Modal
        open={!!modalText}
        close={() => {
          setModalTitle('');
          setModalText('');
        }}
        title={modalTitle}
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            setModalTitle('');
            setModalText('');
          }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default Availability;
