export const times = [
  {
    display: "1:00",
    value: "01:00:00"
  },
  {
    display: "1:15",
    value: "01:15:00"
  },
  {
    display: "1:30",
    value: "01:30:00"
  },
  {
    display: "1:45",
    value: "01:45:00"
  },
  {
    display: "2:00",
    value: "02:00:00"
  },
  {
    display: "2:15",
    value: "02:15:00"
  },
  {
    display: "2:30",
    value: "02:30:00"
  },
  {
    display: "2:45",
    value: "02:45:00"
  },
  {
    display: "3:00",
    value: "03:00:00"
  },
  {
    display: "3:15",
    value: "03:15:00"
  },
  {
    display: "3:30",
    value: "03:30:00"
  },
  {
    display: "3:45",
    value: "03:45:00"
  },
  {
    display: "4:00",
    value: "04:00:00"
  },
  {
    display: "4:15",
    value: "04:15:00"
  },
  {
    display: "4:30",
    value: "04:30:00"
  },
  {
    display: "4:45",
    value: "04:45:00"
  },
  {
    display: "5:00",
    value: "05:00:00"
  },
  {
    display: "5:15",
    value: "05:15:00"
  },
  {
    display: "5:30",
    value: "05:30:00"
  },
  {
    display: "5:45",
    value: "05:45:00"
  },
  {
    display: "6:00",
    value: "06:00:00"
  },
  {
    display: "6:15",
    value: "06:15:00"
  },
  {
    display: "6:30",
    value: "06:30:00"
  },
  {
    display: "6:45",
    value: "06:45:00"
  },
  {
    display: "7:00",
    value: "07:00:00"
  },
  {
    display: "7:15",
    value: "07:15:00"
  },
  {
    display: "7:30",
    value: "07:30:00"
  },
  {
    display: "7:45",
    value: "07:45:00"
  },
  {
    display: "8:00",
    value: "08:00:00"
  },
  {
    display: "8:15",
    value: "08:15:00"
  },
  {
    display: "8:30",
    value: "08:30:00"
  },
  {
    display: "8:45",
    value: "08:45:00"
  },
  {
    display: "9:00",
    value: "09:00:00"
  },
  {
    display: "9:15",
    value: "09:15:00"
  },
  {
    display: "9:30",
    value: "09:30:00"
  },
  {
    display: "9:45",
    value: "09:45:00"
  },
  {
    display: "10:00",
    value: "10:00:00"
  },
  {
    display: "10:15",
    value: "10:15:00"
  },
  {
    display: "10:30",
    value: "10:30:00"
  },
  {
    display: "10:45",
    value: "10:45:00"
  },
  {
    display: "11:00",
    value: "11:00:00"
  },
  {
    display: "11:15",
    value: "11:15:00"
  },
  {
    display: "11:30",
    value: "11:30:00"
  },
  {
    display: "11:45",
    value: "11:45:00"
  },
  {
    display: "12:00",
    value: "12:00:00"
  },
  {
    display: "12:15",
    value: "12:15:00"
  },
  {
    display: "12:30",
    value: "12:30:00"
  },
  {
    display: "12:45",
    value: "12:45:00"
  },
];
