import { combineReducers } from 'redux';

import UserReducer from './userReducer';
import BusinessReducer from './businessReducer';
import SettingsReducer from './settingsReducer';
import ServiceReducer from './serviceReducer';
import StaffReducer from './staffReducer';
import BusinessScheduleReducer from './businessScheduleReducer';
import UserScheduleReducer from './userScheduleReducer';

export default combineReducers({
  user: UserReducer,
  business: BusinessReducer,
  settings: SettingsReducer,
  services: ServiceReducer,
  staff: StaffReducer,
  businessSchedule: BusinessScheduleReducer,
  userSchedule: UserScheduleReducer,
});
