import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getAuth } from '@firebase/auth';
import axios from 'axios';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

import './services.scss';
import { config } from '../../../../config';
import { setBusiness, setUserSchedules, updateServices } from '../../../../store/actions';
import DataTable from '../../../shared/DataTable';
import TableHead from '../../../shared/DataTable/TableHead';
import TableCell from '../../../shared/DataTable/TableCell';
import TableRow from '../../../shared/DataTable/TableRow';
import TableBody from '../../../shared/DataTable/TableBody';
import Modal from '../../../shared/Modal';
import ServicesActionButton from './ServicesActionButton';

function Services({ user, setLoading, loading }) {
  const dispatch = useDispatch();
  const servicesInitialized = useSelector(state => state.services.initialized);
  const services = useSelector(state => state.services.services);
  const userSchedulesInitialized = useSelector(state => state.userSchedule.initialized);
  const userSchedules = useSelector(state => state.userSchedule.schedules);
  const business = useSelector(state => state.business);
  const staffInitialized = useSelector(state => state.staff.initialized);
  const [staff, setStaff] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [currentService, setCurrentService] = useState({});
  const [currentServiceOpen, setCurrentServiceOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [shareCalendarIndex, setShareCalendarIndex] = useState(-1);

  useEffect(() => {
    if (!servicesInitialized) {
      setLoading(true);
    }
  }, [servicesInitialized]);

  useEffect(() => {
    if (user && user.businessId) {
      fetchServices();
    }
  }, [user]);

  useEffect(() => {
    if (user.id) {
      fetchUserSchedules();
    }
  }, [user.id]);

  useEffect(() => {
    if (user.businessId && !business.id) {
      fetchBusiness();
    }
  }, [user.businessId, business.id]);

  useEffect(() => {
    if (servicesInitialized && userSchedulesInitialized && business.id) {
      setLoading(false);
    }
  }, [servicesInitialized, userSchedulesInitialized, business.id]);

  const fetchServices = async () => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();

      const staffResponse = await axios.get(`${config.api}/api/v1/users/staff/${user.businessId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const staffIdNameMap = {};

      if (staffResponse && staffResponse.data && Array.isArray(staffResponse.data) && staffResponse.data.length) {
        setStaff(staffResponse.data.map(u => {
          staffIdNameMap[u.id] = `${u.firstName} ${u.lastName}`;
          return {
            value: u.id,
            label: `${u.firstName} ${u.lastName}`,
          };
        }));
      }

      const response = await axios.get(`${config.api}/api/v1/business/services/${user.businessId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response.data && Array.isArray(response.data) && response.data.length) {
        dispatch(updateServices(response.data.map(service => {
          return {
            ...service,
            staff: service.staff.map(s => {
              return {
                value: s,
                label: staffIdNameMap[s],
              };
            }),
          };
        })));
      } else {
        dispatch(updateServices([]));
      }
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving your services, please try again.');
    }
  };

  const fetchUserSchedules = async () => {
    if (userSchedulesInitialized) {
      return;
    }

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(`${config.api}/api/v1/user/schedules/${user.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const usersSchedulesData = response.data || [];
      dispatch(setUserSchedules([ ...usersSchedulesData ]));
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving your schedules, please refresh the page and try again.');
    }
  };

  const fetchBusiness = async () => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(`${config.api}/api/v1/business/${user.businessId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response.data && response.data.id) {
        dispatch(setBusiness(response.data));
      } else {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('There was an error retrieving the business info, please try again.');
      }
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving the business info, please try again.');
    }
  };

  const confirmDelete = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const response = await axios.delete(`${config.api}/api/v1/business/services/${services[deleteIndex].id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response.data && response.data.status === 'error') {
        setModalTitle(response.data.message);
        setModalText('There was an error deleting the service, please try again.');
      } else {
        const servicesCopy = [ ...services ];
  
        servicesCopy.splice(deleteIndex, 1);
        dispatch(updateServices(servicesCopy));
      }

      setDeleteIndex(-1);
    } catch (e) {
      setModalTitle('Error:');
      setModalText('There was an error deleting the service, please try again.');
    }

    setLoading(false);
  };

  const closeServiceModal = () => {
    setCurrentService({
      name: '',
      description: '',
      duration: 30,
      bufferTime: 0,
      staff: [{ value: user.id, label: `${user.firstName} ${user.lastName}` }],
      businessId: user.businessId,
      scheduleId: '',
      price: '',
      created: moment().valueOf(),
    });
    setCurrentServiceOpen(false);
  };

  const checkIfSubmitIsDisabled = () => {
    const s = currentService;

    if (!s.name || !s.duration) {
      return true;
    }

    return false;
  };

  const handleFieldChange = (e, type) => {
    setCurrentService({
      ...currentService,
      [type]: e.target.value,
    });
  };

  const saveService = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const serviceData = {
        name: currentService.name,
        description: currentService.description,
        duration: parseInt(currentService.duration),
        bufferTime: parseInt(currentService.bufferTime || 0),
        staff: currentService.staff.map(s => s.value),
        businessId: currentService.businessId,
        scheduleId: currentService.scheduleId ? +currentService.scheduleId : '',
        price: parseFloat(currentService.price),
        created: currentService.created,
      };

      if (currentService.id) {
        await axios.put(`${config.api}/api/v1/business/services/${currentService.id}`,
          serviceData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const servicesCopy = [ ...services ];
        const staffIndex = servicesCopy.findIndex(s => s.id === currentService.id);

        if (staffIndex !== -1) {
          servicesCopy[staffIndex] = { ...currentService };
        }

        dispatch(updateServices(servicesCopy));
        closeServiceModal();
      } else {
        const result = await axios.post(`${config.api}/api/v1/business/services`,
          [ serviceData ],
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!result || !result.data || !result.data.createdBusinessServiceIds) {
          setModalTitle('Error:');
          setModalText(`There was an error adding the service, please try again.`);
        } else {
          const servicesCopy = [ ...services ];
  
          servicesCopy.unshift({
            ...currentService,
            id: result.data.createdBusinessServiceIds[0],
          });
  
          dispatch(updateServices(servicesCopy));
          closeServiceModal();
        }
      }
    } catch (e) {
      console.log('error', e)
      setModalTitle('Error:');
      setModalText(`There was an error ${currentService.id ? 'updating' : 'adding'} the service, please try again.`);
    }

    setLoading(false);
  };

  return (
    <div className="Services">
      <div>
        <div className="header-container">
          <h1>Services</h1>

          <button
            className="small success"
            onClick={() => {
              setCurrentService({
                name: '',
                description: '',
                duration: 30,
                bufferTime: 0,
                staff: [{ value: user.id, label: `${user.firstName} ${user.lastName}` }],
                businessId: user.businessId,
                scheduleId: '',
                price: '',
                created: moment().valueOf(),
              });
              setCurrentServiceOpen(true);
            }}
          >
            Add Service <i className="fas fa-plus"></i>
          </button>
        </div>

        <DataTable colors="primary">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Duration</TableCell>
              {/* <TableCell>Buffer Time</TableCell> */}
              <TableCell>Staff</TableCell>
              <TableCell action></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {services.map((service, i) => {
              return (
                <TableRow key={service.id}>
                  <TableCell>{service.name}</TableCell>
                  <TableCell>${(+service.price).toFixed(2)}</TableCell>
                  <TableCell>{service.duration} min.</TableCell>
                  {/* <TableCell>{service.bufferTime} min.</TableCell> */}
                  <TableCell>{service.staff.map(s => s.label).join(', ')}</TableCell>
                  <TableCell>
                    <ServicesActionButton
                      editClicked={() => {
                        setCurrentService({
                          id: service.id,
                          name: service.name,
                          description: service.description,
                          duration: service.duration,
                          bufferTime: service.bufferTime,
                          staff: service.staff,
                          businessId: service.businessId,
                          scheduleId: service.scheduleId || '',
                          price: service.price,
                          created: service.created,
                        });
                        setCurrentServiceOpen(true);
                      }}
                      shareCalendarClicked={() => {
                        setShareCalendarIndex(i);
                      }}
                      previewCalendarClicked={() => {
                        window.open(`/schedule/${business.appURL || business.id}/${service.id}`, '_blank').focus();
                      }}
                      deleteClicked={() => {
                        setDeleteIndex(i);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </DataTable>
      </div>

      <Modal
        open={currentServiceOpen}
        close={closeServiceModal}
        title={`${currentService.id ? 'Edit' : 'Add'} Service`}
        buttons={[
          <button
            key="modal-save"
            className="small success"
            disabled={checkIfSubmitIsDisabled()}
            onClick={saveService}
          >Submit</button>,
          <button
            key="modal-close"
            className="small"
            onClick={closeServiceModal}
          >Cancel</button>,
        ]}
      >
        <>
          <div className="input-container">
            <label>Service Name:</label>
            <input
              type="text"
              value={currentService.name}
              onChange={(e) => handleFieldChange(e, 'name')}
              placeholder="Enter service name"
            />
          </div>

          <div className="input-container">
            <label>Staff:</label>
            <Select
              value={currentService.staff}
              isMulti
              onChange={(staff) => {
                setCurrentService({
                  ...currentService,
                  staff,
                });
              }}
              options={staff}
              styles={{
                input: (provided, state) => ({
                  ...provided,
                  padding: '10px 20px',
                }),
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderRadius: 12,
                }),
              }}
            />
          </div>

          <div className="input-container">
            <label>Schedule:</label>
            <select
              value={currentService.scheduleId || ''}
              onChange={(e) => handleFieldChange(e, 'scheduleId')}
            >
              <option value="">Business Hours</option>
              {userSchedules.map((schedule, i) => {
                return <option key={`schedule-${i}`} value={schedule.id}>{schedule.name}</option>
              })}
            </select>
          </div>

          <div className="input-container">
            <label>Description:</label>
            <textarea
              value={currentService.description}
              onChange={(e) => handleFieldChange(e, 'description')}
              placeholder="Enter description"
            />
          </div>

          <div className="input-container">
            <label>Duration (minutes):</label>
            <input
              value={currentService.duration}
              placeholder="Enter duration"
              type="number"
              onChange={(e) => handleFieldChange(e, 'duration')}
            />
          </div>

          {/* <div className="input-container">
            <label>Buffer Time (minutes):</label>
            <input
              value={currentService.bufferTime}
              placeholder="Enter buffer time"
              type="number"
              onChange={(e) => handleFieldChange(e, 'bufferTime')}
            />
          </div> */}

          <div className="input-container">
            <label>Price (optional):</label>
            <input
              value={currentService.price}
              placeholder="Enter price"
              type="number"
              onChange={(e) => handleFieldChange(e, 'price')}
            />
          </div>
        </>
      </Modal>

      <Modal
        open={deleteIndex !== -1}
        close={() => {
          setDeleteIndex(-1);
        }}
        title={`Delete ${services[deleteIndex] ? services[deleteIndex].name : ''}?`}
        buttons={[
          <button key="modal-delete" className="small danger" onClick={confirmDelete}>Confirm</button>,
          <button key="modal-close" className="small" onClick={() => {
            setDeleteIndex(-1);
          }}>Cancel</button>,
        ]}
      >
        <div>
          <div className="modal-text">
            Are you sure you want to delete <strong>{services[deleteIndex] ? services[deleteIndex].name : ''}</strong>?
          </div>
        </div>
      </Modal>

      <Modal
        open={shareCalendarIndex !== -1}
        close={() => {
          setShareCalendarIndex(-1);
        }}
        title="Share Schedule"
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            setShareCalendarIndex(-1);
          }}>Close</button>,
        ]}
      >
        <div>
          <div className="share-schedule-modal">
            Copy and paste your scheduling link into a message
            {!services[shareCalendarIndex] ? null :
              <div className="share-schedule-input-container">
                <input
                  value={`${window.location.host}/schedule/${business.appURL || business.id}/${services[shareCalendarIndex].id}`}
                  disabled
                />
                <button
                  className="success"
                  onClick={() => {
                    navigator.clipboard.writeText(`${window.location.host}/schedule/${business.appURL || business.id}/${services[shareCalendarIndex].id}`);
                  }}
                >
                  Copy Link
                </button>
              </div>
            }
          </div>
        </div>
      </Modal>

      <Modal
        open={!!modalText}
        close={() => {
          setModalTitle('');
          setModalText('');
        }}
        title={modalTitle}
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            setModalTitle('');
            setModalText('');
          }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default Services;
