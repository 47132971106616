import {
  SET_USER_SCHEDULES,
} from '../actions/types';

const defaultState = {
  initialized: false,
  schedules: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_USER_SCHEDULES:
      return {
        initialized: true,
        schedules: action.payload,
      };
    default:
      return state;
  }
};
