import React from 'react';
import moment from 'moment';

import './calendarHeader.scss';

function CalendarHeader({
  viewType,
  updateViewType,
  currentDate,
  moveDateBackClicked,
  moveDateForwardClicked,
  createAppointmentClicked,
}) {
  return (
    <div className="CalendarHeader">
      <div className="header-left">
        <h1>Calendar</h1>

        <div className="view-toggle">
          <button
            onClick={() => updateViewType('day')}
            className={viewType === 'day' ? 'active' : ''}
          >
            Day
          </button>
          <button
            onClick={() => updateViewType('week')}
            className={viewType === 'week' ? 'active' : ''}
          >
            Week
          </button>
        </div>
      </div>

      <div className="date-selector-container">
        <div className="date-selector">
          <button onClick={() => moveDateBackClicked()}>
            <i className="fas fa-chevron-left"></i>
          </button>
          <div className="date-display">
            {viewType === 'day' ?
              <>{moment(currentDate).format('MMM D')}</> :
              <>{moment(currentDate).startOf('week').format('MMM D')} - {moment(currentDate).endOf('week').format('MMM D')}</>
            }
          </div>
          <button onClick={() => moveDateForwardClicked()}>
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>

      <div className="header-right">
        <button onClick={() => createAppointmentClicked()}>
          New Appointment <i className="fas fa-plus"></i>
        </button>
      </div>
    </div>
  );
}

export default CalendarHeader;
