import React from 'react';
import { Switch, Route } from 'react-router-dom';

import BusinessSchedules from './BusinessSchedules';
import SchedulePicker from './SchedulePicker';
import BusinessNotFound from './BusinessNotFound';

function Schedule({}) {
  return (
    <div className="Schedule">
      <Switch>
        <Route path="/schedule/:business/:schedule" component={SchedulePicker} />
        <Route path="/schedule/:business" component={BusinessSchedules} />
        <Route component={BusinessNotFound} />
      </Switch>
    </div>
  );
}

export default Schedule;
