import React, { useEffect, useState } from 'react';
import { getAuth } from '@firebase/auth';
import axios from 'axios';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

import './staff.scss';
import { config } from '../../../../config';
import { updateStaff } from '../../../../store/actions';
import DataTable from '../../../shared/DataTable';
import TableHead from '../../../shared/DataTable/TableHead';
import TableCell from '../../../shared/DataTable/TableCell';
import TableRow from '../../../shared/DataTable/TableRow';
import TableBody from '../../../shared/DataTable/TableBody';
import Modal from '../../../shared/Modal';

import { isValidEmail } from '../../../../util/isValidEmail';
import StaffActionButton from './StaffActionButton';

function Staff({ user, setLoading, loading }) {
  const dispatch = useDispatch();
  const staffInitialized = useSelector(state => state.staff.initialized);
  const staff = useSelector(state => state.staff.staff);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [currentStaff, setCurrentStaff] = useState({});
  const [currentStaffOpen, setCurrentStaffOpen] = useState(false);

  useEffect(() => {
    if (!staffInitialized) {
      setLoading(true);
    }
  }, [staffInitialized]);

  useEffect(() => {
    if (user && user.businessId && !staffInitialized) {
      fetchStaff();
    }
  }, [user, staffInitialized]);

  const fetchStaff = async () => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(`${config.api}/api/v1/users/staff/${user.businessId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response.data && Array.isArray(response.data) && response.data.length) {
        dispatch(updateStaff(response.data.filter(u => u.id !== user.id)));
      }
    } catch (e) {
      setModalTitle('Error:');
      setModalText('There was an error retrieving staff members, please try again.');
    }

    setLoading(false);
  };

  const checkIfSubmitIsDisabled = () => {
    const u = currentStaff;
    const isEmail = isValidEmail(u.email);

    if (!u.firstName || !u.lastName || !u.email || !isEmail) {
      return true;
    }

    return false;
  };

  const handleFieldChange = (e, type) => {
    setCurrentStaff({
      ...currentStaff,
      [type]: e.target.value,
    });
  };

  const closeStaffModal = () => {
    setCurrentStaff({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      onboardingStep: -1,
      roles: ['staff'],
      businessId: user.businessId,
      created: moment().valueOf(),
    });
    setCurrentStaffOpen(false);
  };

  const saveStaff = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const userData = {
        firstName: currentStaff.firstName,
        lastName: currentStaff.lastName,
        email: currentStaff.email.trim().toLowerCase(),
        phone: currentStaff.phone,
        onboardingStep: currentStaff.onboardingStep,
        roles: currentStaff.roles,
        businessId: currentStaff.businessId,
        created: currentStaff.created,
      };

      if (currentStaff.id) {
        await axios.put(`${config.api}/api/v1/user/${currentStaff.id}`,
          userData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const staffCopy = [ ...staff ];
        const staffIndex = staffCopy.findIndex(s => s.id === currentStaff.id);

        if (staffIndex !== -1) {
          staffCopy[staffIndex] = { ...currentStaff };
        }

        dispatch(updateStaff(staffCopy));
        closeStaffModal();
      } else {
        const result = await axios.post(`${config.api}/api/v1/users/staff`,
          {
            storeOwnerName: user.firstName,
            users: [userData],
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!result || !result.data || !result.data.createdUserIds) {
          let errorMessage = 'There was an error adding the staff member, please try again.';

          if (result && result.data && result.data.userEmailErrors && result.data.userEmailErrors.length) {
            errorMessage = result.data.userEmailErrors[0];
          }

          setModalTitle('Error:');
          setModalText(errorMessage);
        } else {
          const staffCopy = [ ...staff ];
  
          staffCopy.unshift({
            ...currentStaff,
            id: result.data.createdUserIds[0],
          });
  
          dispatch(updateStaff(staffCopy));
          closeStaffModal();

          setModalTitle('Success!');
          setModalText(`We have created an account for ${currentStaff.firstName} and sent them an email at ${currentStaff.email} with a link to finish setting up their account.`);
        }
      }
    } catch (e) {
      setModalTitle('Error:');
      setModalText(`There was an error ${currentStaff.id ? 'updating' : 'adding'} the staff member, please try again.`);
    }

    setLoading(false);
  };

  return (
    <div className="Staff">
      <div>
        <div className="header-container">
          <h1>Staff</h1>

          <button
            className="small success"
            onClick={() => {
              setCurrentStaff({
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                onboardingStep: -1,
                roles: ['staff'],
                businessId: user.businessId,
                created: moment().valueOf(),
              });
              setCurrentStaffOpen(true);
            }}
          >
            Add Staff <i className="fas fa-plus"></i>
          </button>
        </div>

        <DataTable colors="success">
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell action></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {staff.map(staffUser => {
              return (
                <TableRow key={staffUser.id}>
                  <TableCell>{staffUser.firstName}</TableCell>
                  <TableCell>{staffUser.lastName}</TableCell>
                  <TableCell>{staffUser.email}</TableCell>
                  <TableCell>{staffUser.phone || '-'}</TableCell>
                  <TableCell action>
                    <StaffActionButton
                      editClicked={() => {
                        setCurrentStaff({
                          id: staffUser.id,
                          firstName: staffUser.firstName,
                          lastName: staffUser.lastName,
                          email: staffUser.email,
                          phone: staffUser.phone,
                          onboardingStep: staffUser.onboardingStep,
                          roles: staffUser.roles,
                          businessId: staffUser.businessId,
                          created: staffUser.created,
                        });
                        setCurrentStaffOpen(true);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </DataTable>
      </div>

      <Modal
        open={currentStaffOpen}
        close={closeStaffModal}
        title={`${currentStaff.id ? 'Edit' : 'Add'} Staff`}
        buttons={[
          <button
            key="modal-save"
            className="small success"
            disabled={checkIfSubmitIsDisabled()}
            onClick={saveStaff}
          >Submit</button>,
          <button
            key="modal-close"
            className="small"
            onClick={closeStaffModal}
          >Cancel</button>,
        ]}
      >
        <>
          <div className="input-container">
            <label>First Name:</label>
            <input
              type="text"
              value={currentStaff.firstName}
              onChange={(e) => handleFieldChange(e, 'firstName')}
              placeholder="Enter first name"
            />
          </div>

          <div className="input-container">
            <label>Last Name:</label>
            <input
              type="text"
              value={currentStaff.lastName}
              onChange={(e) => handleFieldChange(e, 'lastName')}
              placeholder="Enter last name"
            />
          </div>

          <div className="input-container">
            <label>Email:</label>
            <input
              type="email"
              value={currentStaff.email}
              onChange={(e) => handleFieldChange(e, 'email')}
              placeholder="Enter email"
              disabled={!!currentStaff.id}
            />
          </div>

          <div className="input-container">
            <label>Phone:</label>
            <input
              type="tel"
              value={currentStaff.phone}
              onChange={(e) => handleFieldChange(e, 'phone')}
              placeholder="Enter phone"
            />
          </div>
        </>
      </Modal>

      <Modal
        open={!!modalText}
        close={() => {
          setModalTitle('');
          setModalText('');
        }}
        title={modalTitle}
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            setModalTitle('');
            setModalText('');
          }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default Staff;
