import {
  SET_USER_SCHEDULES,
} from './types';

export const setUserSchedules = (userSchedules) => {
  return {
    type: SET_USER_SCHEDULES,
    payload: userSchedules,
  };
};
