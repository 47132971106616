export const get12HourTimeWithMeridiem = (date) => {
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };

  const timeArr = (date.toLocaleString('en-US', options)).split(' ');
  
  const meridiem = timeArr[1];
  const hoursMinutesArray = timeArr[0].split(':');
  let hours = hoursMinutesArray[0];
  let minutes = +hoursMinutesArray[1];

  if (hours.length === 1) {
    hours = `0${hours}`;
  }
  
  if (minutes < 15) {
    minutes = '00';
  } else if (minutes < 30) {
    minutes = '15';
  } else if (minutes < 45) {
    minutes = '30';
  } else {
    minutes = '45';
  }

  return {
    meridiem,
    time: `${hours}:${minutes}:00`,
  };
};
