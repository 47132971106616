import React from 'react';

import './tableBody.scss';

function TableBody({ children }) {
  return (
    <tbody className="TableBody">
      {children}
    </tbody>
  );
}

export default TableBody;
