import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import moment from 'moment';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

import './onboardingStep4.scss';
import { config } from '../../../../config';
import { updateUser } from '../../../../store/actions';

function OnboardingStep4({ handleError }) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [saving, setSaving] = useState(false);
  const [servicesToAdd, setServicesToAdd] = useState([]);
  const [staff, setStaff] = useState([{ label: `${user.firstName} ${user.lastName}`, value: user.id }]);

  useEffect(() => {
    fetchStaff();
  }, []);

  const fetchStaff = async () => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(`${config.api}/api/v1/users/staff/${user.businessId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response && response.data && Array.isArray(response.data) && response.data.length) {
        setStaff(response.data.map(u => {
          return {
            value: u.id,
            label: `${u.firstName} ${u.lastName}`,
          };
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const checkIfNextStepIsDisabled = () => {
    for (let i = 0; i < servicesToAdd.length; i++) {
      const s = servicesToAdd[i];

      if (!s.name || !s.duration || !s.staff.length) {
        return true;
      }
    }

    return false;
  };

  const handleFieldChange = (e, i, field) => {
    const servicesCopy = [ ...servicesToAdd ];
    const serviceCopy = {
      ...servicesCopy[i],
      [field]: e.target.value,
    };

    servicesCopy[i] = serviceCopy;

    setServicesToAdd(servicesCopy);
  };

  const moveToNextStep = async () => {
    if (saving) {
      return;
    }

    setSaving(true);

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();

      const updatedServices = servicesToAdd.map((s) => {
        return {
          ...s,
          duration: parseInt(s.duration),
          price: parseFloat(s.price),
          staff: s.staff.map(s => s.value),
        };
      });

      await axios.post(`${config.api}/api/v1/business/services`,
        updatedServices,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const userData = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: '',
        onboardingStep: -1,
        roles: user.roles,
        businessId: user.businessId,
        created: user.created,
      };

      await axios.put(`${config.api}/api/v1/user/${user.id}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch(updateUser(userData));
    } catch (e) {
      console.log(e)
      let errorMessage = 'There was an error saving this step. Please try again.';
      if (e && e.response && e.response.data && e.response.data.message) {
        errorMessage = e.response.data.message;
      }

      handleError('Error:', errorMessage);
      setSaving(false);
    }
  };

  return (
    <div className="OnboardingStep4">
      <div className="top-container">
        <h2>Add Services You Offer</h2>

        <div className="add-button-container">
          <button
            className="small alternate"
            onClick={() => {
              const servicesCopy = [ ...servicesToAdd ];

              servicesCopy.push({
                name: '',
                description: '',
                duration: 30,
                bufferTime: 0,
                staff: [{ value: user.id, label: `${user.firstName} ${user.lastName}` }],
                businessId: user.businessId,
                scheduleId: '',
                price: '',
                created: moment().valueOf(),
              });

              setServicesToAdd(servicesCopy);
            }}
          >
            Add
          </button>
        </div>
      </div>

      <p>Don't worry. You can always update this later.</p>

      {servicesToAdd.map((service, i) => {
        return (
          <div className="service-container" key={`row-${i}`}>
            <div className="service-top-row">
              <h3>Service {i + 1}</h3>


              <button
                className="delete-button"
                onClick={() => {
                  const servicesCopy = [ ...servicesToAdd ];
    
                  servicesCopy.splice(i, 1);
    
                  setServicesToAdd(servicesCopy);
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>

            <div className="input-row">
              <div className="input-container flex-2">
                <label>Service Name:</label>
                <input
                  value={service.name}
                  placeholder="Enter service name"
                  onChange={(e) => handleFieldChange(e, i, 'name')}
                />
              </div>

              <div className="input-container flex-1">
                <label>Duration (minutes):</label>
                <input
                  value={service.duration}
                  placeholder="Enter duration"
                  type="number"
                  onChange={(e) => handleFieldChange(e, i, 'duration')}
                />
              </div>
            </div>

            <div className="input-row">
              <div className="input-container flex-2">
                <label>Staff:</label>
                <Select
                  value={service.staff}
                  isMulti
                  onChange={(staff) => {
                    const servicesCopy = [ ...servicesToAdd ];
                    const serviceCopy = {
                      ...servicesCopy[i],
                      staff,
                    };

                    servicesCopy[i] = serviceCopy;

                    setServicesToAdd(servicesCopy);
                  }}
                  options={staff}
                  styles={{
                    input: (provided, state) => ({
                      ...provided,
                      padding: '10px 20px',
                    }),
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderRadius: 12,
                    }),
                  }}
                />
              </div>

              <div className="input-container flex-1">
                <label>Price (optional):</label>
                <input
                  value={service.price}
                  placeholder="Enter price"
                  type="number"
                  onChange={(e) => handleFieldChange(e, i, 'price')}
                />
              </div>
            </div>
          </div>
        );
      })}

      <div className="actions-row">
        <Link
          to="/signin"
          onClick={() => {
            const auth = getAuth();
            auth.signOut();
          }}
        >
          Sign out
        </Link>

        <button
          className="success"
          onClick={moveToNextStep}
          disabled={checkIfNextStepIsDisabled()}
        >
          {saving ? 'Saving...' : 'Next'}
        </button>
      </div>
    </div>
  );
}

export default OnboardingStep4;
