import React, { useState, useEffect } from 'react';
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updateEmail, updatePassword } from '@firebase/auth';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

import './profile.scss';
import { config } from '../../../../../config';
import { updateUser } from '../../../../../store/actions';
import { authCodesMap } from '../../../../auth/authCodesMap';
import Loading from '../../../../shared/Loading';
import Modal from '../../../../shared/Modal';

function Profile({}) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [updatedUser, setUpdatedUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    onboardingStep: -1,
    roles: [],
    businessId: '',
    created: 0,
  });
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [hasMadeChanges, setHasMadeChanges] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [updatedPassword, setUpdatedPassword] = useState('');
  const [updatedPasswordConfirmation, setUpdatedPasswordConfirmation] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [emailChangePassword, setEmailChangePassword] = useState('');
  const [showConfirmPasswordModal, setShowConfirmPasswordModal] = useState(false);

  useEffect(() => {
    if (user.businessId) {
      setUpdatedUser({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        onboardingStep: user.onboardingStep,
        roles: user.roles,
        businessId: user.businessId,
        created: user.created,
      });
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [user]);

  const handleFieldChange = (e, type) => {
    setUpdatedUser({
      ...updatedUser,
      [type]: e.target.value,
    });
    setHasMadeChanges(true);
  };

  const saveUser = async () => {
    setLoading(true);
    const auth = getAuth();
    const currentUser = auth.currentUser;
    let reAuthenticated = false;

    if (updatedUser.email !== user.email) {
      const passwordToUse = changePassword ? currentPassword : emailChangePassword;

      if (!passwordToUse) {
        setLoading(false);
        setShowConfirmPasswordModal(true);
        return;
      }

      try {
        const credential = EmailAuthProvider.credential(
          user.email, 
          passwordToUse
        );
        await reauthenticateWithCredential(currentUser, credential);
        reAuthenticated = true;

        await updateEmail(currentUser, updatedUser.email);
      } catch (e) {
        console.log(e.code);
        const errorMessage = authCodesMap[e.code] || 'An error occurred resetting your email. Please try again.';
        setLoading(false);
        setModalTitle('Error:');
        setModalText(errorMessage);
        return;
      }
    }

    setEmailChangePassword('');
    setShowConfirmPasswordModal(false);

    try {
      const token = await auth.currentUser.getIdToken();
      await axios.put(`${config.api}/api/v1/user/${user.uid}`,
        {
          ...updatedUser,
          email: updatedUser.email.trim().toLowerCase(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch(updateUser({
        ...user,
        ...updatedUser,
      }));
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error updating your profile, please try again.');
      return;
    }

    if (changePassword && currentPassword && updatedPassword) {
      if (updatedPassword !== updatedPasswordConfirmation) {
        setLoading(false);
        setModalTitle('Notice:');
        setModalText('Passwords do not match');
        return;
      }

      try {
        if (!reAuthenticated) {
          const credential = EmailAuthProvider.credential(
            user.email, 
            currentPassword
          );
          await reauthenticateWithCredential(currentUser, credential);
        }

        await updatePassword(currentUser, updatedPassword);
      } catch (e) {
        const errorMessage = authCodesMap[e.code] || 'An error occurred resetting your password. Please try again.';
        setLoading(false);
        setModalTitle('Error:');
        setModalText(errorMessage);
        return;
      }
    }

    setChangePassword(false);
    setCurrentPassword('');
    setUpdatedPassword('');
    setUpdatedPasswordConfirmation('');
    setEmailChangePassword('');
    setHasMadeChanges(false);
    setLoading(false);
  };

  return (
    <div className="Profile">
      {!loading ? null : <Loading position="absolute" />}

      <>
        <div className="input-row">
          <div className="input-container flex-1">
            <label>First Name:</label>
            <input
              type="text"
              value={updatedUser.firstName}
              onChange={(e) => handleFieldChange(e, 'firstName')}
              placeholder="Enter your first name"
            />
          </div>

          <div className="input-container flex-1">
            <label>Last Name:</label>
            <input
              type="text"
              value={updatedUser.lastName}
              onChange={(e) => handleFieldChange(e, 'lastName')}
              placeholder="Enter your last name"
            />
          </div>
        </div>

        <div className="input-row">
          <div className="input-container flex-1">
            <label>Phone:</label>
            <input
              type="phone"
              value={updatedUser.phone}
              onChange={(e) => handleFieldChange(e, 'phone')}
              placeholder="Enter phone number"
            />
          </div>

          <div className="input-container flex-1">
            <label>Email:</label>
            <input
              type="email"
              autoComplete="off"
              value={updatedUser.email}
              onChange={(e) => handleFieldChange(e, 'email')}
              placeholder="Enter email address"
            />
          </div>
        </div>

        <div className="update-password-container-outer">
          <button
            className="update-password-button"
            onClick={() => setChangePassword(!changePassword)}
          >
            <span>Update Password</span>
            <i className={`fas fa-angle-down${changePassword ? ' up' : ''}`}></i>
          </button>

          <div className={`update-password-container${changePassword ? ' open' : ''}`}>
            <div className="input-container">
              <label>Current Password:</label>
              <input
                type="password"
                autoComplete="off"
                value={currentPassword}
                onChange={(e) => {
                  setCurrentPassword(e.target.value);
                  setHasMadeChanges(true);
                }}
                placeholder="Enter current password"
              />
            </div>

            <div className="input-container">
              <label>New Password:</label>
              <input
                type="password"
                autoComplete="off"
                value={updatedPassword}
                onChange={(e) => {
                  setUpdatedPassword(e.target.value);
                  setHasMadeChanges(true);
                }}
                placeholder="Enter new password"
              />
            </div>

            <div className="input-container">
              <label>New Password Confirmation:</label>
              <input
                type="password"
                autoComplete="off"
                value={updatedPasswordConfirmation}
                onChange={(e) => {
                  setUpdatedPasswordConfirmation(e.target.value);
                  setHasMadeChanges(true);
                }}
                placeholder="Confirm new password"
              />
            </div>
          </div>
        </div>

        <button
          disabled={!hasMadeChanges}
          onClick={saveUser}
        >
          Save Changes
        </button>
      </>

      <Modal
        open={showConfirmPasswordModal}
        close={() => {
          setEmailChangePassword('');
          setShowConfirmPasswordModal(false);
        }}
        title="Confirm Password"
        buttons={[
          <button
            key="modal-submit"
            className="small success"
            disabled={!emailChangePassword.length}
            onClick={saveUser}
          >
            Submit
          </button>,
          <button
            key="modal-close"
            className="small"
            onClick={() => {
              setEmailChangePassword('');
              setShowConfirmPasswordModal(false);
            }}
          >
            Close
          </button>,
        ]}
      >
        <div>
          <div className="modal-text">Password confirmation is required to update your email address. Please enter your password.</div>

          <div className="input-container">
            <label>Password:</label>
            <input
              type="password"
              autoComplete="off"
              value={emailChangePassword}
              onChange={(e) => {
                setEmailChangePassword(e.target.value);
              }}
              placeholder="Enter password"
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={!!modalText}
        close={() => {
          setModalTitle('');
          setModalText('');
        }}
        title={modalTitle}
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            setModalTitle('');
            setModalText('');
          }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default Profile;
