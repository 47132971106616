import {
} from '../actions/types';

const defaultState = {
  defaultCalendarView: 'week',
  timeSlotDuration: 15,
  calendarStartHour: 8,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
