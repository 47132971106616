import React, { useState } from 'react';
import { Popover } from 'react-tiny-popover';

import './servicesActionButton.scss';
import ServicesActionDropdown from './ServicesActionDropdown';

function ServicesActionButton({
  editClicked,
  shareCalendarClicked,
  previewCalendarClicked,
  deleteClicked,
}) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <div className="ServicesActionButton">
      <Popover
        isOpen={isPopoverOpen}
        positions={['bottom', 'right']}
        onClickOutside={() => setIsPopoverOpen(false)}
        containerStyle={{ zIndex: 100 }}
        content={
          <ServicesActionDropdown
            editClicked={() => {
              setIsPopoverOpen(false);
              editClicked();
            }}
            shareCalendarClicked={() => {
              setIsPopoverOpen(false);
              shareCalendarClicked();
            }}
            previewCalendarClicked={() => {
              setIsPopoverOpen(false);
              previewCalendarClicked();
            }}
            deleteClicked={() => {
              setIsPopoverOpen(false);
              deleteClicked();
            }}
          />
        }
      >
        <button
          className="action-button"
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
          <i className="fas fa-ellipsis-h" aria-hidden="true"></i>
        </button>
      </Popover>
    </div>
  );
}

export default ServicesActionButton;
