import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import './sidebar.scss';

const links = [
  {
    route: '/',
    name: 'Dashboard',
    iconClassName: 'fas fa-columns',
  },
  {
    route: '/calendar',
    name: 'Calendar',
    iconClassName: 'far fa-calendar',
    borderBottom: true,
  },
  {
    route: '/availability',
    name: 'Availability',
    iconClassName: 'fas fa-clock',
  },
  {
    route: '/services',
    name: 'Services',
    iconClassName: 'fas fa-store',
    borderBottom: true,
  },
  {
    route: '/customers',
    name: 'Customers',
    iconClassName: 'far fa-address-book',
  },
  {
    route: '/staff',
    name: 'Staff',
    iconClassName: 'fas fa-users',
  },
  {
    route: '/account',
    name: 'Settings',
    iconClassName: 'fas fa-cog',
    borderBottom: true,
  },
];

function Sidebar({
  closeSidebar,
  logOut,
}) {
  let location = useLocation();

  const getActiveClassName = (link) => {
    if (location.pathname === '/dashboard') {
      return link.name === 'Dashboard' ? ' active' : '';
    }

    if (location.pathname.includes('/course')) {
      return link.route.includes('/course') ? ' active' : '';
    }

    if (location.pathname.includes('/account')) {
      return link.route.includes('/account') ? ' active' : '';
    }

    return location.pathname === link.route ? ' active' : '';
  };

  return (
    <div className="Sidebar">
      <div className="bottom-border first"></div>

      {links.map(link => {
        return (
          <div key={link.route}>
            <Link to={link.route}>
              <div
                onClick={closeSidebar}
                className="nav-item"
              >
                <div className={`nav-active-item${getActiveClassName(link)}`}></div>
                <i className={link.iconClassName}></i>
                {link.name}
              </div>
            </Link>

            {!link.borderBottom ? null : <div className="bottom-border"></div>}
          </div>
        );
      })}
      <a>
        <div
          onClick={() => {
            closeSidebar();
            logOut();
          }}
          className="nav-item sign-out"
        >
          <i className="fas fa-sign-out-alt"></i>
          Log Out
        </div>
      </a>
    </div>
  );
}

export default Sidebar;
