import React, { useState, useEffect } from 'react';
import { getAuth } from '@firebase/auth';
import axios from 'axios';
import moment from 'moment';

import './overview.scss';
import { config } from '../../../../config';
import Modal from '../../../shared/Modal';
import DataTable from '../../../shared/DataTable';
import TableHead from '../../../shared/DataTable/TableHead';
import TableCell from '../../../shared/DataTable/TableCell';
import TableRow from '../../../shared/DataTable/TableRow';
import TableBody from '../../../shared/DataTable/TableBody';

import CalendarIcon from '../../../../images/calendar.png';
import ClockIcon from '../../../../images/clock.png';
import DogIcon from '../../../../images/dog-with-line.png';
import DogProductsIcon from '../../../../images/dog-with-product.png';

const startOfDay = moment().startOf('day');
const endOfDay = moment().endOf('day');

function Overview({ user, setLoading, loading }) {
  const [appointments, setAppointments] = useState([]);
  const [scheduleInitialized, setScheduleInitialized] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [searchValue, setSearchValue] = useState();

  useEffect(() => {
    if (user && user.businessId && !scheduleInitialized) {
      setScheduleInitialized(true);
      fetchBusinessSchedule();
    }

    if (!scheduleInitialized) {
      setLoading(true);
    }
  }, [user, scheduleInitialized]);

  const fetchBusinessSchedule = async () => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(`${config.api}/api/v1/business/appointments/${user.businessId}/${startOfDay.valueOf()}/${endOfDay.valueOf()}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response) {
        if (response.data && Array.isArray(response.data)) {
          setAppointments(response.data);
        } else {
          setAppointments([]);
        }
      } else {
        setModalTitle('Error:');
        setModalText('There was an error retrieving appointments for the current week, please try again.');
      }
    } catch (e) {
      setModalTitle('Error:');
      setModalText('There was an error retrieving appointments for the current week, please try again.');
    }

    setLoading(false);
  };

  return (
    <div className="Overview">
      <div>
        <div className="header-container">
          <h1>Dashboard</h1>

          {/* <div className="search-container">
            <i className="fas fa-search"></i>
            <input
              placeholder="Search by Name, Email or Phone"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div> */}
        </div>

        <div className="top-row">
          <div className="top-container success">
            <div className="image-container">
              <img src={CalendarIcon} alt="calendar"/>
            </div>

            <div>
              <p className="top-text">Today</p>
              <p className="bottom-text">{startOfDay.format('Do MMM')}</p>
            </div>
          </div>

          <div className="top-container danger">
            <div className="image-container">
              <img src={ClockIcon} alt="clock"/>
            </div>

            <div>
              <p className="top-text">{appointments.length} No. of</p>
              <p className="bottom-text">Appointments</p>
            </div>
          </div>

          <div className="welcome-container">
            <div className="welcome-text">
              <p>Welcome</p>
              <p>{user.firstName}!</p>
            </div>

            <div className="welcome-images-container">
              <div className="welcome-image-container first">
                <img src={DogIcon} alt="dogs"/>
              </div>
              <div className="welcome-image-container second">
                <img src={DogProductsIcon} alt="dogs"/>
              </div>
            </div>
          </div>
        </div>

        {!appointments.length ? null :
          <div className="appointments-container">
            <DataTable colors="primary">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Staff</TableCell>
                </TableRow>
              </TableHead>
    
              <TableBody>
                {appointments.map((appointment, i) => {
                  return (
                    <TableRow key={`appointment-${i}`}>
                      <TableCell>{moment(appointment.start).format('MMM Do')}</TableCell>
                      <TableCell>{moment(appointment.start).format('h:mm A')} - {moment(appointment.end).format('h:mm A')}</TableCell>
                      <TableCell>{appointment.customerName}</TableCell>
                      <TableCell>{appointment.staffName}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </DataTable>
          </div>
        }

        {(!appointments.length && !loading) ?
          <div className="no-appointments-container">
            <p>No scheduled appointments today.</p>
          </div> : null
        }
      </div>

      <Modal
        open={!!modalText}
        close={() => {
          setModalTitle('');
          setModalText('');
        }}
        title={modalTitle}
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            setModalTitle('');
            setModalText('');
          }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default Overview;
