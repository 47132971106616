import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from 'firebase/auth';
import { Link, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import './home.scss';
import { config } from '../../../config';
import { updateUser } from '../../../store/actions';
import Loading from '../../shared/Loading';
import Header from '../../layout/Header';
import Sidebar from '../../layout/Sidebar';
import Onboarding from '../Onboarding';
import Overview from './Overview';
import Calendar from './Calendar';
import Customers from './Customers';
import Staff from './Staff';
import Services from './Services';
import Account from './Account';
import Availability from './Availability';

function Home({}) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [navClosed, setNavClosed] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user.uid) {
      fetchUser(user.uid);
    }
  }, []);

  const fetchUser = async (userId) => {
    const auth = getAuth();
    let token = '';

    try {
      token = await auth.currentUser.getIdToken();
    } catch (e) {
      auth.signOut();
      return;
    }

    try {
      const result = await axios.get(`${config.api}/api/v1/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch(updateUser(result.data));
    } catch (e) {
      if (!e.response || e.response.status === 403) {
        auth.signOut();
        return;
      }

      if (e.response.status === 404) {
        createUser(userId, token, auth.currentUser.email);
      }
    }
  };

  const createUser = async (userId, token, email) => {
    try {
      const userData = {
        firstName: '',
        lastName: '',
        email,
        phone: '',
        onboardingStep: 0,
        roles: ['owner'],
        businessId: '',
        created: moment().valueOf(),
      };

      await axios.post(`${config.api}/api/v1/user/${userId}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch(updateUser({
        ...userData,
        id: userId,
      }));
    } catch (e) {
      const auth = getAuth();
      auth.signOut();
    }
  };

  return (
    <div className="Home">
      {!user.hasOwnProperty('onboardingStep') || user.onboardingStep === -1 ?
        <>
          {!loading ? null : <Loading />}
          <div className="header-container">
            <Header
              profileImageUrl={(user.profileImage && user.profileImage.url) || ''}
              userName={`${user.firstName ? user.firstName : ''}`}
              openNav={() => setNavClosed(false)}
            />
          </div>

          <div className="main-container">
            <div className="nav-container">
              <div className={`nav-inner${navClosed ? ' closed' : ''}`}>
                <div className="nav-content-container">
                  <div className="logo-container">
                    <div className="close-container">
                      <i onClick={() => setNavClosed(true)} className="fas fa-times close"></i>
                    </div>

                    <div className="nav-logo-container">
                      <Link to="/">
                        <img
                          className="logo"
                          src="https://pupford.b-cdn.net/assets%2F1659561571475-pupford-logo.png?alt=media&token=ae31678e-909d-48ef-990e-b163298d74a4"
                          alt=""
                        />
                      </Link>
                    </div>
                  </div>

                  <Sidebar
                    logOut={() => {
                      const auth = getAuth();
                      auth.signOut();
                    }}
                    closeSidebar={() => setNavClosed(true)}
                  />
                </div>

                <div className="rounded-sidebar"></div>
              </div>
            </div>

            <div className="content-container">
              <div className="content-inner">
                <Switch>
                  <Route
                    path="/calendar" 
                    render={(props) => (
                      <Calendar
                        {...props}
                        user={user}
                        setLoading={(val) => {
                          setLoading(val);
                        }}
                        loading={loading}
                      />
                    )}
                  />
                  <Route
                    path="/availability"
                    render={(props) => (
                      <Availability
                        {...props}
                        user={user}
                        setLoading={(val) => {
                          setLoading(val);
                        }}
                        loading={loading}
                      />
                    )}
                  />
                  <Route
                    path="/customers"
                    render={(props) => (
                      <Customers
                        {...props}
                        user={user}
                        setLoading={(val) => {
                          setLoading(val);
                        }}
                        loading={loading}
                      />
                    )}
                  />
                  <Route
                    path="/staff"
                    render={(props) => (
                      <Staff
                        {...props}
                        user={user}
                        setLoading={(val) => {
                          setLoading(val);
                        }}
                        loading={loading}
                      />
                    )}
                  />
                  <Route
                    path="/services"
                    render={(props) => (
                      <Services
                        {...props}
                        user={user}
                        setLoading={(val) => {
                          setLoading(val);
                        }}
                        loading={loading}
                      />
                    )}
                  />
                  <Route path="/account" component={Account} />
                  <Route
                    path="/"
                    render={(props) => (
                      <Overview
                        {...props}
                        user={user}
                        setLoading={(val) => {
                          setLoading(val);
                        }}
                        loading={loading}
                      />
                    )}
                  />
                  <Route
                    render={(props) => (
                      <Overview
                        {...props}
                        user={user}
                        setLoading={(val) => {
                          setLoading(val);
                        }}
                        loading={loading}
                      />
                    )}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </> :
        <Onboarding onboardingStep={user.onboardingStep}/>
      }
    </div>
  );
}

export default Home;
