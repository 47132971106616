import React, { useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

import './onboardingStep3.scss';
import { config } from '../../../../config';
import { isValidEmail } from '../../../../util/isValidEmail';
import { updateUser } from '../../../../store/actions';

function OnboardingStep3({ handleError }) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [saving, setSaving] = useState(false);
  const [usersToAdd, setUsersToAdd] = useState([]);

  const handleFieldChange = (e, i, field) => {
    const usersCopy = [ ...usersToAdd ];
    const userCopy = {
      ...usersCopy[i],
      [field]: e.target.value,
    };

    usersCopy[i] = userCopy;

    setUsersToAdd(usersCopy);
  };

  const checkIfNextStepIsDisabled = () => {
    for (let i = 0; i < usersToAdd.length; i++) {
      const u = usersToAdd[i];
      const isEmail = isValidEmail(u.email);

      if (!u.firstName || !u.lastName || !u.email || !isEmail) {
        return true;
      }
    }

    return false;
  };

  const moveToNextStep = async () => {
    if (saving) {
      return;
    }

    setSaving(true);

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();

      await axios.post(`${config.api}/api/v1/users/staff`,
        {
          storeOwnerName: user.firstName,
          users: usersToAdd.map(u => {
            return {
              ...u,
              email: u.email.trim().toLowerCase(),
            };
          }),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const userData = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email.trim().toLowerCase(),
        phone: '',
        onboardingStep: 3,
        roles: user.roles,
        businessId: user.businessId,
        created: user.created,
      };

      await axios.put(`${config.api}/api/v1/user/${user.id}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch(updateUser(userData));
    } catch (e) {
      console.log(e)
      let errorMessage = 'There was an error saving this step. Please try again.';
      if (e && e.response && e.response.data && e.response.data.message) {
        errorMessage = e.response.data.message;
      }

      handleError('Error:', errorMessage);
      setSaving(false);
    }
  };

  return (
    <div className="OnboardingStep3">
      <div className="top-container">
        <h2>Add Your Staff</h2>

        <div className="add-button-container">
          <button
            className="small alternate"
            onClick={() => {
              const usersCopy = [ ...usersToAdd ];

              usersCopy.push({
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                onboardingStep: -1,
                roles: ['staff'],
                businessId: user.businessId,
                created: moment().valueOf(),
              });

              setUsersToAdd(usersCopy);
            }}
          >
            Add
          </button>
        </div>
      </div>

      <p>Don't worry. You can always update this later.</p>

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <td>First Name</td>
              <td>Last Name</td>
              <td>Email</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  disabled={true}
                  value={user.firstName}
                  onChange={() => {}}
                />
              </td>
              <td>
                <input
                  disabled={true}
                  value={user.lastName}
                  onChange={() => {}}
                />
              </td>
              <td>
                <input
                  disabled={true}
                  value={user.email}
                  onChange={() => {}}
                />
              </td>
            </tr>

            {usersToAdd.map((user, i) => {
              return (
                <tr key={`row-${i}`}>
                  <td>
                    <input
                      value={user.firstName}
                      placeholder="Enter first name"
                      onChange={(e) => handleFieldChange(e, i, 'firstName')}
                    />
                  </td>

                  <td>
                    <input
                      value={user.lastName}
                      placeholder="Enter last name"
                      onChange={(e) => handleFieldChange(e, i, 'lastName')}
                    />
                  </td>

                  <td>
                    <input
                      value={user.email}
                      type="email"
                      placeholder="Enter email"
                      onChange={(e) => handleFieldChange(e, i, 'email')}
                    />
                  </td>

                  <td>
                    <button
                      className="delete-button"
                      onClick={() => {
                        const usersCopy = [ ...usersToAdd ];
          
                        usersCopy.splice(i, 1);
          
                        setUsersToAdd(usersCopy);
                      }}
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="actions-row">
        <Link
          to="/signin"
          onClick={() => {
            const auth = getAuth();
            auth.signOut();
          }}
        >
          Sign out
        </Link>

        <button
          className="success"
          onClick={moveToNextStep}
          disabled={checkIfNextStepIsDisabled()}
        >
          {saving ? 'Saving...' : 'Next'}
        </button>
      </div>
    </div>
  );
}

export default OnboardingStep3;
