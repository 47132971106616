import React from 'react';
import { Link } from 'react-router-dom';

import './header.scss';

function Header(props) {
  return (
    <div className="Header">
      <div className="header-left-container">
        <i onClick={props.openNav} className="fas fa-bars"></i>
      </div>

      <div className="header-logo-container">
        <Link to="/">
          <img
            src="https://pupford.b-cdn.net/assets%2F1659561571475-pupford-logo.png?alt=media&token=ae31678e-909d-48ef-990e-b163298d74a4"
            alt=""
          />
        </Link>
      </div>

      <div className="user-info-container">
        <Link to="/account">
          <div className="profile-image-container">
            {!props.profileImageUrl ? <i className="fas fa-user"></i> :
              <img src={props.profileImageUrl} alt="User"/>
            }
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Header;
