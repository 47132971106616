import {
  SET_USER,
  UPDATE_USER,
} from '../actions/types';

const defaultState = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  onboardingStep: -1,
  roles: [],
  businessId: '',
  created: 0,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_USER:
      return action.payload;
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
