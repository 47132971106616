import React from 'react';

import './staffActionDropdown.scss';

function StaffActionDropdown({ editClicked }) {
  return (
    <div className="StaffActionDropdown">
      <button onClick={() => editClicked()}>
        Edit
      </button>
    </div>
  );
}

export default StaffActionDropdown;
