import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import AsyncSelect from 'react-select/async';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import axios from 'axios';
import { getAuth } from '@firebase/auth';
import { useDispatch, useSelector } from 'react-redux';

import './calendar.scss';
import CalendarHeader from './CalendarHeader';
import CalendarDayView from './CalendarDayView';
import CalendarWeekView from './CalendarWeekView';
import { config } from '../../../../config';
import { colors } from '../../../../util/colors';
import { times } from '../../../../util/times';
import { timeStringsTo24Hour } from '../../../../util/timeStringsTo24Hour';
import { get12HourTimeWithMeridiem } from '../../../../util/get12HourTimeWithMeridiem';
import { isValidEmail } from '../../../../util/isValidEmail';
import debounce from '../../../../util/debounce';
import { updateStaff, setBusinessSchedule, setBusiness, updateServices } from '../../../../store/actions';
import Modal from '../../../shared/Modal';
import ColorPicker from '../../../shared/ColorPicker';

const defaultTime = moment().valueOf();
const defaultDate = new Date();
const intervalOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const periodOptions = [
  'day',
  'week',
  'month',
];
const reminderIntervalOptions = [];
for (let i = 1; i < 60; i++) {
  reminderIntervalOptions.push(i);
}
const reminderPeriodOptions = [
  'minute',
  'hour',
  'day',
];

function Calendar({ user, loading, setLoading }) {
  const businessId = useRef(null);
  const loadOptions = useCallback(
    debounce((inputValue, callback) => {
      loadCustomerOptions(inputValue, callback);
    }, 250),
    []
  );
  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <button className="date-picker-button" onClick={onClick} ref={ref}>
      {value.split('/').join(' - ')}
    </button>
  ));
  const dispatch = useDispatch();
  const business = useSelector(state => state.business);
  const businessSchedule = useSelector(state => state.businessSchedule);
  const staffInitialized = useSelector(state => state.staff.initialized);
  const staffState = useSelector(state => state.staff.staff);
  const servicesInitialized = useSelector(state => state.services.initialized);
  const services = useSelector(state => state.services.services);
  const [viewType, setViewType] = useState('day');
  const [currentDate, setCurrentDate] = useState(defaultTime);
  const [staff, setStaff] = useState([]);
  const [schedule, setSchedule] = useState({});
  const [scheduleInitialized, setScheduleInitialized] = useState(false);
  const [beginningOfCurrentWeek, setBeginningOfCurrentWeek] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [customerSearchValue, setCustomerSearchValue] = useState();
  const [selectedEvent, setSelectedEvent] = useState({
    startTime: '09:00:00',
    startMeridiem: 'AM',
    endTime: '09:30:00',
    endMeridiem: 'AM',
    date: defaultDate,
    recurring: false,
    period: 'day',
    interval: 1,
    ongoing: false,
    endDate: moment().add(1, 'week').toDate(),
    businessId: business.id,
    color: colors[0],
    resourceId: '',
    noteToStaff: '',
    customerId: '',
    noteToCustomer: '',
    newCustomer: false,
    newCustomerFirstName: '',
    newCustomerLastName: '',
    newCustomerEmail: '',
    newCustomerPhone: '',
    sendConfirmationText: false,
    sendConfirmationEmail: false,
    sendCustomerReminderText: false,
    sendCustomerReminderEmail: false,
    sendStaffReminderText: false,
    sendStaffReminderEmail: false,
    customerReminderInterval: 1,
    customerReminderPeriod: 'day',
    staffReminderInterval: 1,
    staffReminderPeriod: 'day',
    serviceId: '',
  });
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [openedEvent, setOpenedEvent] = useState({
    bookedFrom: 'calendar',
    businessId: '',
    color: colors[0],
    created: Date.now(),
    createdBy: '',
    createdFrom: 'calendar',
    customerEmail: '',
    customerId: 0,
    customerName: '',
    customerPhone: '',
    date: defaultDate,
    end: defaultDate,
    id: 0,
    noteToCustomer: '',
    noteToStaff: '',
    recurringAppointmentId: 0,
    customerReminderTime: Date.now(),
    customerRemindersSent: false,
    staffReminderTime: Date.now(),
    staffRemindersSent: false,
    resource: 0,
    resourceId: '',
    sendCustomerReminderEmail: false,
    sendCustomerReminderText: false,
    sendStaffReminderEmail: false,
    sendStaffReminderText: false,
    staffId: '',
    staffName: '',
    start: defaultDate,
    title: '',
    serviceId: '',
  });
  const [editRecurringEventOption, setEditRecurringEventOption] = useState(0);
  const [showEditRecurringEventOptions, setShowEditRecurringEventOptions] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [deleteOption, setDeleteOption] = useState(0);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [appointmentErrors, setAppointmentErrors] = useState({
    endTimeError: false,
    endDateError: false,
  });
  const [appointmentWarnings, setAppointmentWarnings] = useState({
    noCustomerEmail: false,
    noCustomerPhone: false,
    noStaffEmail: false,
    noStaffPhone: false,
  });

  useEffect(() => {
    if (business && business.id && business.timezone) {
      setBeginningOfCurrentWeek(moment().tz(business.timezone).startOf('week'));
    }
  }, []);

  useEffect(() => {
    automaticallyScrollToTime();
  }, [viewType]);

  useEffect(() => {
    const {
      startTime,
      startMeridiem,
      endTime,
      endMeridiem,
      recurring,
      ongoing,
      date,
      endDate,
    } = selectedEvent;

    const updatedAppointmentErrors = {
      endTimeError: false,
      endDateError: false,
    };

    if (startMeridiem === 'PM' && endMeridiem === 'AM') {
      updatedAppointmentErrors.endTimeError = true;
    } else if (startMeridiem === endMeridiem) {
      const startTimeArr = startTime.split(':');
      const startTimeHour = +startTimeArr[0];
      const startTimeMinutes = +startTimeArr[1];

      if (startTimeHour === 12) {
        const endTimeArr = endTime.split(':');
        const endTimeHour = +endTimeArr[0];
        const endTimeMinutes = +endTimeArr[1];

        if (+endTimeHour === 12 && (+endTimeMinutes <= startTimeMinutes)) {
          updatedAppointmentErrors.endTimeError = true;
        }
      } else {
        const startTimeIndex = times.findIndex((time) => time.value === startTime);
        const endTimeIndex = times.findIndex((time) => time.value === endTime);

        if (endTimeIndex <= startTimeIndex) {
          updatedAppointmentErrors.endTimeError = true;
        }
      }
    }

    if (recurring && !ongoing) {
      const endIsNotAfterStart = moment(date).startOf('day').isSameOrAfter(moment(endDate).startOf('day'));

      if (endIsNotAfterStart) {
        updatedAppointmentErrors.endDateError = true;
      }
    }

    setAppointmentErrors(updatedAppointmentErrors);
  }, [selectedEvent]);

  useEffect(() => {
    if (!business.id && user.id) {
      fetchBusiness();
    }

    if (!business.id) {
      setLoading(true);
    } else {
      businessId.current = business.id;
    }
  }, [business, user]);

  useEffect(() => {
    if (!staffInitialized && user.id && business.id) {
      fetchStaff();
    }
  }, [staffInitialized, user, business]);

  useEffect(() => {
    if (!servicesInitialized && user && user.businessId) {
      fetchServices();
    }
  }, [servicesInitialized, user]);

  useEffect(() => {
    if (!businessSchedule.id && user.id && business.id) {
      fetchBusinessSchedule();
    }

    if (businessSchedule.id && business.id) {
      const scheduleMap = {};

      businessSchedule.schedule.forEach(d => {
        const startTimeData = timeStringsTo24Hour(d.startTime, d.startMeridiem);
        const endTimeData = timeStringsTo24Hour(d.endTime, d.endMeridiem);

        const day = d.day;
        const date = moment().day(day);
        const dayNumber = date.day();

        scheduleMap[dayNumber] = {
          active: d.active,
          start: moment().tz(business.timezone).day(day).hour(startTimeData.hours).minutes(startTimeData.minutes).seconds(startTimeData.seconds).subtract(1, 'second').toDate(),
          end: moment().tz(business.timezone).day(day).hour(endTimeData.hours).minutes(endTimeData.minutes).seconds(endTimeData.seconds).subtract(1, 'second').toDate(),
        };
      });

      setSchedule(scheduleMap);
      setScheduleInitialized(true);
      automaticallyScrollToTime();
    }
  }, [businessSchedule, user, business]);

  useEffect(() => {
    if (staffInitialized && businessSchedule.id) {
      setLoading(false);
    }
  }, [staffInitialized, businessSchedule]);

  useEffect(() => {
    if (staff.length !== staffState.length) {
      const updatedStaff = staffState.map(s => {
        return {
          id: s.id,
          name: `${s.firstName} ${s.lastName}`,
        };
      });

      setStaff([
        {
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
        },
        ...updatedStaff,
      ]);
    }
  }, [staffState.length]);

  useEffect(() => {
    if (staffInitialized && !staffState.length) {
      setStaff([
        {
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
        },
      ]);
    }
  }, [staffInitialized, staffState]);

  useEffect(() => {
    if (beginningOfCurrentWeek) {
      fetchAppointments(beginningOfCurrentWeek);
    }
  }, [beginningOfCurrentWeek]);

  useEffect(() => {
    updateServiceEndTime(selectedEvent.startTime, selectedEvent.startMeridiem, selectedEvent.serviceId);
  }, [selectedEvent.startTime, selectedEvent.startMeridiem, selectedEvent.serviceId]);

  const fetchBusinessSchedule = async () => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(`${config.api}/api/v1/business/schedule/${user.businessId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response.data && response.data.id) {
        dispatch(setBusinessSchedule(response.data));
      } else {
        setModalTitle('Error:');
        setModalText('There was an error retrieving the business schedule, please try again.');
      }
    } catch (e) {
      setModalTitle('Error:');
      setModalText('There was an error retrieving the business schedule, please try again.');
    }
  };

  const fetchBusiness = async () => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(`${config.api}/api/v1/business/${user.businessId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response.data && response.data.id) {
        dispatch(setBusiness(response.data));
        setBeginningOfCurrentWeek(moment().tz(response.data.timezone).startOf('week'));
      } else {
        setModalTitle('Error:');
        setModalText('There was an error retrieving the business info, please try again.');
      }
    } catch (e) {
      setModalTitle('Error:');
      setModalText('There was an error retrieving the business info, please try again.');
    }
  };

  const fetchStaff = async () => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(`${config.api}/api/v1/users/staff/${user.businessId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response.data && Array.isArray(response.data) && response.data.length) {
        dispatch(updateStaff(response.data.filter(u => u.id !== user.id)));
      }
    } catch (e) {
      setModalTitle('Error:');
      setModalText('There was an error retrieving staff members, please try again.');
    }
  };

  const fetchServices = async () => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();

      const staffResponse = await axios.get(`${config.api}/api/v1/users/staff/${user.businessId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const staffIdNameMap = {};

      if (staffResponse && staffResponse.data && Array.isArray(staffResponse.data) && staffResponse.data.length) {
        staffResponse.data.forEach(u => {
          staffIdNameMap[u.id] = `${u.firstName} ${u.lastName}`;
        });
      }

      const response = await axios.get(`${config.api}/api/v1/business/services/${user.businessId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response.data && Array.isArray(response.data) && response.data.length) {
        dispatch(updateServices(response.data.map(service => {
          return {
            ...service,
            staff: service.staff.map(s => {
              return {
                value: s,
                label: staffIdNameMap[s],
              };
            }),
          };
        })));
      }
    } catch (e) {
      setModalTitle('Error:');
      setModalText('There was an error retrieving your services, please try again.');
    }
  };

  const fetchAppointments = async (start) => {
    const end = moment(start).endOf('week');
    const startParam = start.valueOf();
    const endParam = end.valueOf();
    setLoading(true);

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(`${config.api}/api/v1/business/appointments/${user.businessId}/${startParam}/${endParam}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response) {
        if (response.data && Array.isArray(response.data)) {
          setAppointments(response.data.map(a => {
            return {
              ...a,
              resource: a.id,
              title: a.customerName,
              start: moment(a.start).tz(business.timezone).toDate(),
              end: moment(a.end).tz(business.timezone).toDate(),
              date: moment(a.start).tz(business.timezone).toDate(),
              resourceId: a.staffId,
            };
          }));
        } else {
          setAppointments([]);
        }
      } else {
        setModalTitle('Error:');
        setModalText('There was an error retrieving appointments for the current week, please try again.');
      }
    } catch (e) {
      setModalTitle('Error:');
      setModalText('There was an error retrieving appointments for the current week, please try again.');
    }

    setLoading(false);
  };

  const loadCustomerOptions = async (value, cb) => {
    if (!value || !businessId.current) {
      cb([]);
    } else {
      try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const response = await axios.get(`${config.api}/api/v1/business/customers/search/${businessId.current}/${value}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response && response.data && Array.isArray(response.data) && response.data.length) {
          cb(response.data.map(customer => {
            return {
              value: customer.id,
              label: `${customer.firstName} ${customer.lastName}`,
            };
          }));
        } else {
          cb([]);
        }
      } catch (e) {
        cb([]);
      }
    }
  };

  const submitEvent = async (recurringEventSelectedOption) => {
    if (!selectedEvent.serviceId) {
      setModalTitle('Notice:');
      setModalText('Please select a service for this appointment.');
      return;
    }

    if (appointmentErrors.endTimeError) {
      setModalTitle('Notice:');
      setModalText('End Time must be after Start Time.');
      return;
    }

    if (appointmentErrors.endDateError) {
      setModalTitle('Notice:');
      setModalText('End Date must be after Start Date.');
      return;
    }
    
    if (selectedEvent.newCustomer && !selectedEvent.newCustomerFirstName) {
      setModalTitle('Notice:');
      setModalText('New customer first name is required.');
      return;
    }
  
    setLoading(true);

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();

      let appointmentToRemoveFromCalendar;
      let recurringAppointmentsToRemoveFromCalendar;
      let originalEventStartDate;

      if ((selectedEvent.id && !selectedEvent.recurringAppointmentId) || recurringEventSelectedOption === 0) {
        // delete old event
        try {
          await axios.delete(`${config.api}/api/v1/business/appointments/${selectedEvent.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          appointmentToRemoveFromCalendar = selectedEvent.id;
        } catch (e) {
          setModalTitle('Error:');
          setModalText('There was an error updating this event, please try again.');
          setLoading(false);
          return;
        }
      }

      if (selectedEvent.id && selectedEvent.recurringAppointmentId && !selectedEvent.recurring) {
        // delete recurring event and all associated events
        try {
          await axios.delete(`${config.api}/api/v1/business/recurring-appointments`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: {
              option: 2,
              recurringAppointmentId: selectedEvent.recurringAppointmentId,
              start: selectedEvent.recurringStartDate,
            },
          });

          recurringAppointmentsToRemoveFromCalendar = selectedEvent.recurringAppointmentId;
        } catch (e) {
          setModalTitle('Error:');
          setModalText('There was an error updating this event, please try again.');
          setLoading(false);
          return;
        }
      }

      if (selectedEvent.id && selectedEvent.recurringAppointmentId && recurringEventSelectedOption === 1) {
        // delete all future events for recurring event and create new one below
        try {
          await axios.delete(`${config.api}/api/v1/business/recurring-appointments`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: {
              option: 1,
              recurringAppointmentId: selectedEvent.recurringAppointmentId,
              start: selectedEvent.originalEventStartDate,
            },
          });

          recurringAppointmentsToRemoveFromCalendar = selectedEvent.recurringAppointmentId;
          originalEventStartDate = selectedEvent.originalEventStartDate;
        } catch (e) {
          setModalTitle('Error:');
          setModalText('There was an error updating this event, please try again.');
          setLoading(false);
          return;
        }
      }

      let customerId;
      let customerName;
      let customerPhone;
      let customerEmail;
      let customerFirstName;

      if (selectedEvent.newCustomer) {
        customerFirstName = selectedEvent.newCustomerFirstName;
        customerName = `${selectedEvent.newCustomerFirstName}${selectedEvent.newCustomerLastName ? ` ${selectedEvent.newCustomerLastName}` : ''}`;
        customerPhone = selectedEvent.newCustomerPhone;
        customerEmail = selectedEvent.newCustomerEmail.trim().toLowerCase();

        const customerData = {
          firstName: selectedEvent.newCustomerFirstName,
          lastName: selectedEvent.newCustomerLastName,
          email: selectedEvent.newCustomerEmail.trim().toLowerCase(),
          phone: selectedEvent.newCustomerPhone,
          phoneExt: '',
          country: 'US',
          city: '',
          state: '',
          zip: '',
          address1: '',
          address2: '',
          group: '',
          businessId: user.businessId,
          notes: '',
          created: moment().valueOf(),
        };

        const result = await axios.post(`${config.api}/api/v1/business/customers`,
          [ customerData ],
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!result || !result.data || !result.data.createdCustomerIds || !result.data.createdCustomerIds.length) {
          setModalTitle('Error:');
          setModalText(`There was an error creating the new customer, please try again.`);
          setLoading(false);
          return;
        }

        customerId = result.data.createdCustomerIds[0];
      } else if (!selectedEvent.customerId) {
        setModalTitle('Notice:');
        setModalText('You must either select an existing customer or create a new customer for this appointment.');
        setLoading(false);
        return;
      } else {
        customerId = selectedEvent.customerId;

        const customerResponse = await axios.get(`${config.api}/api/v1/business/customers/${business.id}/${customerId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!customerResponse.data || !customerResponse.data.id) {
          setModalTitle('Error:');
          setModalText('There was an error retrieving the selected customer, please try again.');
          setLoading(false);
          return;
        }

        const foundCustomer = customerResponse.data;

        customerFirstName = foundCustomer.firstName;
        customerName = `${foundCustomer.firstName}${foundCustomer.lastName ? ` ${foundCustomer.lastName}` : ''}`;
        customerPhone = foundCustomer.phone;
        customerEmail = foundCustomer.email;
      }

      let foundStaff;

      if (selectedEvent.resourceId === user.id) {
        foundStaff = user;
      } else {
        foundStaff = staffState.find(s => s.id === selectedEvent.resourceId);
      }

      const staffName = `${foundStaff.firstName}${foundStaff.lastName ? ` ${foundStaff.lastName}` : ''}`;
      const staffPhone = foundStaff.phone;
      const staffEmail = foundStaff.email;

      const start = moment(selectedEvent.date).tz(business.timezone);
      const end = moment(selectedEvent.date).tz(business.timezone);

      const startTimeData = timeStringsTo24Hour(selectedEvent.startTime, selectedEvent.startMeridiem);
      const endTimeData = timeStringsTo24Hour(selectedEvent.endTime, selectedEvent.endMeridiem);

      start.hour(startTimeData.hours).minutes(startTimeData.minutes).seconds(startTimeData.seconds);
      end.hour(endTimeData.hours).minutes(endTimeData.minutes).seconds(endTimeData.seconds);

      const hasCustomerEmail = customerEmail && isValidEmail(customerEmail);
      const hasStaffEmail = staffEmail && isValidEmail(staffEmail);

      if (selectedEvent.recurring && recurringEventSelectedOption !== 0) {
        const recurringAppointment = {
          startDate: start.valueOf(),
          period: selectedEvent.period,
          interval: parseInt(selectedEvent.interval),
          ongoing: selectedEvent.ongoing,
          endDate: moment(selectedEvent.endDate).tz(business.timezone).endOf('day').valueOf(),
          dateToStartCreatingEventsFrom: start.valueOf(),
          start: start.valueOf(),
          end: end.valueOf(),
          businessId: business.id,
          color: selectedEvent.color,
          createdFrom: 'calendar',
          createdBy: user.id,
          bookedFrom: 'calendar',
          customerId,
          customerName,
          noteToCustomer: selectedEvent.noteToCustomer,
          staffId: selectedEvent.resourceId,
          staffName,
          noteToStaff: selectedEvent.noteToStaff,
          staffReminderTime: moment(start).subtract(+selectedEvent.staffReminderInterval, selectedEvent.staffReminderPeriod).valueOf(),
          customerReminderTime: moment(start).subtract(+selectedEvent.customerReminderInterval, selectedEvent.customerReminderPeriod).valueOf(),
          sendCustomerReminderEmail: selectedEvent.sendCustomerReminderEmail && hasCustomerEmail,
          sendCustomerReminderText: selectedEvent.sendCustomerReminderText && customerPhone.length !== 0,
          sendStaffReminderEmail: selectedEvent.sendStaffReminderEmail && hasStaffEmail,
          sendStaffReminderText: selectedEvent.sendStaffReminderText && staffPhone.length !== 0,
          created: Date.now(),
          serviceId: selectedEvent.serviceId ? +selectedEvent.serviceId : '',
        };

        const response = await axios.post(`${config.api}/api/v1/business/recurring-appointments`,
          recurringAppointment,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response && response.data && response.data.id) {
          fetchAppointments(beginningOfCurrentWeek);

          if ((selectedEvent.sendConfirmationText && customerPhone) || (selectedEvent.sendConfirmationEmail && hasCustomerEmail)) {
            sendCustomerConfirmation(selectedEvent.sendConfirmationEmail, selectedEvent.sendConfirmationText, customerFirstName, customerPhone, customerEmail, recurringAppointment, hasCustomerEmail, true, token);
            return;
          } else {
            setEditModalVisible(false);
          }
        } else {
          setModalTitle('Error:');
          setModalText('There was an error saving this appointment, please try again.');
        }
      } else {
        const appointmentData = {
          start: start.valueOf(),
          end: end.valueOf(),
          businessId: business.id,
          color: selectedEvent.color,
          createdFrom: 'calendar',
          createdBy: user.id,
          bookedFrom: 'calendar',
          customerId,
          customerName,
          noteToCustomer: selectedEvent.noteToCustomer,
          staffId: selectedEvent.resourceId,
          staffName,
          noteToStaff: selectedEvent.noteToStaff,
          sendCustomerReminderEmail: selectedEvent.sendCustomerReminderEmail && hasCustomerEmail,
          sendCustomerReminderText: selectedEvent.sendCustomerReminderText && customerPhone.length !== 0,
          sendStaffReminderEmail: selectedEvent.sendStaffReminderEmail && hasStaffEmail,
          sendStaffReminderText: selectedEvent.sendStaffReminderText && staffPhone.length !== 0,
          staffReminderTime: moment(start).subtract(+selectedEvent.staffReminderInterval, selectedEvent.staffReminderPeriod).valueOf(),
          staffRemindersSent: false,
          customerReminderTime: moment(start).subtract(+selectedEvent.customerReminderInterval, selectedEvent.customerReminderPeriod).valueOf(),
          customerRemindersSent: false,
          recurringAppointmentId: recurringEventSelectedOption === 0 ? selectedEvent.recurringAppointmentId : '',
          created: Date.now(),
          serviceId: selectedEvent.serviceId ? +selectedEvent.serviceId : '',
        };

        const response = await axios.post(`${config.api}/api/v1/business/appointments`,
          appointmentData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        if (response && response.data && response.data.id) {
          let updatedAppointments = [
            ...appointments,
            {
              ...appointmentData,
              id: response.data.id,
              resource: response.data.id,
              title: appointmentData.customerName,
              start: moment(appointmentData.start).tz(business.timezone).toDate(),
              end: moment(appointmentData.end).tz(business.timezone).toDate(),
              date: moment(appointmentData.start).tz(business.timezone).toDate(),
              resourceId: appointmentData.staffId,
            },
          ];

          if (appointmentToRemoveFromCalendar) {
            updatedAppointments = updatedAppointments.filter(a => a.id !== appointmentToRemoveFromCalendar);
          }

          if (recurringAppointmentsToRemoveFromCalendar) {
            if (originalEventStartDate) {
              updatedAppointments = updatedAppointments.filter(a => {
                return a.start < moment(originalEventStartDate).toDate() || a.recurringAppointmentId !== recurringAppointmentsToRemoveFromCalendar;
              });
            } else {
              updatedAppointments = updatedAppointments.filter(a => a.recurringAppointmentId !== recurringAppointmentsToRemoveFromCalendar);
            }
          }

          setAppointments(updatedAppointments);
  
          if ((selectedEvent.sendConfirmationText && customerPhone) || (selectedEvent.sendConfirmationEmail && hasCustomerEmail)) {
            sendCustomerConfirmation(selectedEvent.sendConfirmationEmail, selectedEvent.sendConfirmationText, customerFirstName, customerPhone, customerEmail, appointmentData, hasCustomerEmail, false, token);
            return;
          } else {
            setEditModalVisible(false);
          }
        } else {
          setModalTitle('Error:');
          setModalText('There was an error saving this appointment, please try again.');
        }
      }
    } catch (e) {
      console.log('error', e);
      let errorMessage = 'There was an error saving this appointment, please try again.';
      if (e && e.response && e.response.data && e.response.data.message) {
        errorMessage = e.response.data.message;
      }

      setModalTitle('Error:');
      setModalText(errorMessage);
    }

    setLoading(false);
  };

  const sendCustomerConfirmation = async (sendConfirmationEmail, sendConfirmationText, customerFirstName, customerPhone, customerEmail, appointmentData, hasCustomerEmail, isRecurring, token) => {
    try {
      const timeString = `${moment(appointmentData.start).format('dddd, MMMM Do')} from ${moment(appointmentData.start).format('h:mm')} to ${moment(appointmentData.end).format('h:mma')}`;
      const recurringMessage = !isRecurring ? '' :
        `, and then ${appointmentData.ongoing ? 'ongoing ' : ''} every ${appointmentData.interval > 1 ? `${appointmentData.interval} ` : ''}${appointmentData.period}${appointmentData.interval > 1 ? 's' : ''}${appointmentData.ongoing ? '' : ` through ${moment(appointmentData.endDate).format('dddd, MMMM Do')}`}`;

      await axios.post(`${config.api}/api/v1/notification/appointment-confirmation`,
        {
          customerFirstName,
          email: (sendConfirmationEmail && hasCustomerEmail) ? customerEmail : '',
          phone: (sendConfirmationText && customerPhone) ? customerPhone : '',
          timeString,
          businessName: business.name,
          recurringMessage,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setEditModalVisible(false);
    } catch (e) {
      setEditModalVisible(false);

      setTimeout(() => {
        setModalTitle('Notice:');
        setModalText('The appointment was created but there was an error sending a customer confirmation.');
      }, 0);
    }

    setLoading(false);
  };

  const deleteAppointment = async () => {
    setLoading(true);

    const eventId = openedEvent.id;
    const selectedDeleteOption = deleteOption;
    const eventRecurringAppointmentId = openedEvent.recurringAppointmentId;
    const start = moment(openedEvent.start).startOf('day').valueOf();
    
    setDeleteOption(0);
    setDeleteOpen(false);
    setViewModalVisible(false);

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();

      if (selectedDeleteOption) {
        await axios.delete(`${config.api}/api/v1/business/recurring-appointments`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            option: selectedDeleteOption,
            recurringAppointmentId: eventRecurringAppointmentId,
            start,
          },
        });
  
        if (selectedDeleteOption === 2) {
          setAppointments(appointments.filter(a => a.recurringAppointmentId !== eventRecurringAppointmentId));
        } else {
          setAppointments(appointments.filter(a => {
            return a.start < moment(start).toDate() || a.recurringAppointmentId !== eventRecurringAppointmentId;
          }));
        }
      } else {
        await axios.delete(`${config.api}/api/v1/business/appointments/${eventId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setAppointments(appointments.filter(a => a.id !== eventId));
      }
    } catch (e) {
      console.log('error', e);
      let errorMessage = 'There was an error deleting this appointment, please try again.';
      if (e && e.response && e.response.data && e.response.data.message) {
        errorMessage = e.response.data.message;
      }

      setModalTitle('Error:');
      setModalText(errorMessage);
    }

    setLoading(false);
  };

  const automaticallyScrollToTime = () => {
    setTimeout(() => {
      const parentElement = document.querySelector('.time-scroll-container');

      if (!parentElement) {
        return;
      }

      if (viewType === 'day') {
        let scrollLeft = 1000;
        
        const timeBlockElements = document.getElementsByClassName('staff-time-block');
        if (timeBlockElements && timeBlockElements.length) {
          scrollLeft = timeBlockElements[0].offsetWidth * 8;
        }

        parentElement.scrollLeft = scrollLeft;
      } else {
        let scrollTop = 400;

        const timeBlockElements = document.getElementsByClassName('date-time-cell');
        if (timeBlockElements && timeBlockElements.length) {
          scrollTop = timeBlockElements[0].offsetHeight * 8;
        }

        parentElement.scrollTop = scrollTop;
      }
    }, 0);
  };

  const moveDateBack = () => {
    const updatedDate = moment(currentDate).subtract(1, viewType).valueOf();
    setCurrentDate(updatedDate);
    updateBeginningOfCurrentWeek(updatedDate);
  };

  const moveDateForward = () => {
    const updatedDate = moment(currentDate).add(1, viewType).valueOf();
    setCurrentDate(updatedDate);
    updateBeginningOfCurrentWeek(updatedDate);
  };

  const handleDateSelectedFromCalendar = (newDate) => {
    const updatedDate = moment(newDate).valueOf();
    setCurrentDate(updatedDate);
    updateBeginningOfCurrentWeek(updatedDate);
  };

  const updateBeginningOfCurrentWeek = (newDate) => {
    const beginningOfWeek = moment(newDate).tz(business.timezone).startOf('week');
    
    if (!beginningOfCurrentWeek || beginningOfWeek.valueOf() !== beginningOfCurrentWeek.valueOf()) {
      setBeginningOfCurrentWeek(beginningOfWeek);
    }

    automaticallyScrollToTime();
  };

  const updateServiceEndTime = (startTime, startMeridiem, serviceId) => {
    const service = services.find(s => s.id === +serviceId);

    if (!service) {
      // handle this
      let endTime = startTime;
      let endMeridiem = startMeridiem;

      const timeIndex = times.findIndex(t => t.value === startTime);

      if (timeIndex !== -1 && times[timeIndex + 1]) {
        endTime = times[timeIndex + 1].value;

        const startTimeArr = startTime.split(':');
        const endTimeArr = endTime.split(':');

        if (+startTimeArr[0] !== 12 && +endTimeArr[0] === 12) {
          if (startMeridiem === 'AM') {
            endMeridiem = 'PM';
          } else {
            endMeridiem = 'AM';
          }
        }
      } else {
        endTime = times[0].value;
      }

      setSelectedEvent({
        ...selectedEvent,
        endTime,
        endMeridiem,
      });
    } else {
      const serviceDuration = service.duration;
      let hoursToAdd = Math.floor(serviceDuration / 60);
      const minutesToAdd = Math.floor(serviceDuration - (hoursToAdd * 60));
      const startTimeData = timeStringsTo24Hour(startTime, startMeridiem);
      let endTimeMinutes = minutesToAdd + startTimeData.minutes;

      if (endTimeMinutes > 59) {
        hoursToAdd++;
        endTimeMinutes -= 60;
      }

      let endTimeHours = hoursToAdd + startTimeData.hours;
      let endTimeMeridiem = 'AM';

      if (endTimeHours > 24) {
        endTimeHours = 23;
      }

      if (endTimeHours >= 12) {
        endTimeMeridiem = 'PM';
      }

      if (endTimeHours > 12) {
        endTimeHours -= 12;
      }

      endTimeHours = `${endTimeHours < 10 ? '0' : ''}${endTimeHours}`;
      endTimeMinutes = `${endTimeMinutes < 10 ? '0' : ''}${endTimeMinutes}`;

      setSelectedEvent({
        ...selectedEvent,
        endTime: `${endTimeHours}:${endTimeMinutes}:00`,
        endMeridiem: endTimeMeridiem,
      });
    }
  };

  const handleSlotSelection = ({start, end, resourceId}) => {
    const startData = get12HourTimeWithMeridiem(start);
    const endData = get12HourTimeWithMeridiem(end);
    setSelectedEvent({
      startTime: startData.time,
      startMeridiem: startData.meridiem,
      endTime: endData.time,
      endMeridiem: endData.meridiem,
      date: start < defaultDate ? defaultDate : start,
      recurring: false,
      period: 'day',
      interval: 1,
      ongoing: false,
      endDate: moment().add(1, 'week').toDate(),
      businessId: business.id,
      color: colors[0],
      resourceId,
      noteToStaff: '',
      customerId: '',
      noteToCustomer: '',
      newCustomer: false,
      newCustomerFirstName: '',
      newCustomerLastName: '',
      newCustomerEmail: '',
      newCustomerPhone: '',
      sendConfirmationText: false,
      sendConfirmationEmail: false,
      sendCustomerReminderText: false,
      sendCustomerReminderEmail: false,
      sendStaffReminderText: false,
      sendStaffReminderEmail: false,
      customerReminderInterval: 1,
      customerReminderPeriod: 'day',
      staffReminderInterval: 1,
      staffReminderPeriod: 'day',
      serviceId: '',
    });

    setCustomerSearchValue(null);
    setEditModalVisible(true);
  };

  const handleCreateAppointmentClicked = () => {
    handleSlotSelection({
      start: moment().toDate(),
      end: moment().add(1, 'hour').toDate(),
      resourceId: user.id,
    });
  };

  const handleTimeBlockClickedOnDayView = (hour, minutes, staffId) => {
    const hourArr = hour.split(' ');
    let hours = +hourArr[0];

    if (hours !== 12 && hourArr[1] === 'PM') {
      hours += 12;
    } else if (hours === 12 && hourArr[1] === 'AM') {
      hours = 0;
    }

    const start = moment(currentDate).set({ hours, minutes, seconds: 0, milliseconds: 0 }).toDate();
    const end = moment(start).add(1, 'hour').toDate();

    handleSlotSelection({
      start,
      end,
      resourceId: staffId,
    });
  };

  const handleTimeBlockClickedOnWeekView = (day, hour, minutes) => {
    const hourArr = hour.split(' ');
    let hours = +hourArr[0];

    if (hours !== 12 && hourArr[1] === 'PM') {
      hours += 12;
    } else if (hours === 12 && hourArr[1] === 'AM') {
      hours = 0;
    }

    const start = moment(day).set({ hours, minutes, seconds: 0, milliseconds: 0 }).toDate();
    const end = moment(start).add(1, 'hour').toDate();

    handleSlotSelection({
      start,
      end,
      resourceId: user.id,
    });
  };

  const handleAppointmentClicked = async (eventData) => {
    setLoading(true);

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(`${config.api}/api/v1/business/customers/${eventData.businessId}/${eventData.customerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const dataToSet = {
        ...eventData,
        customerEmail: '',
        customerPhone: '',
      };

      if (response.data && response.data.hasOwnProperty('email') && response.data.hasOwnProperty('phone')) {
        dataToSet.customerEmail = response.data.email;
        dataToSet.customerPhone = response.data.phone;
      }

      setLoading(false);
      setOpenedEvent(dataToSet);
      setViewModalVisible(true);
    } catch (e) {
      setLoading(false);
      setOpenedEvent({
        ...eventData,
        customerEmail: '',
        customerPhone: '',
      });
      setViewModalVisible(true);
    }
  };

  const handleEditAppointmentClicked = async (appointment) => {
    const startData = get12HourTimeWithMeridiem(appointment.start);
    const endData = get12HourTimeWithMeridiem(appointment.end);
    const appointmentData = {
      id: appointment.id,
      recurringAppointmentId: appointment.recurringAppointmentId,
      startTime: startData.time,
      startMeridiem: startData.meridiem,
      endTime: endData.time,
      endMeridiem: endData.meridiem,
      date: appointment.date,
      recurring: false,
      period: 'day',
      interval: 1,
      ongoing: false,
      endDate: moment().add(1, 'week').toDate(),
      businessId: appointment.businessId,
      color: appointment.color,
      resourceId: appointment.resourceId,
      noteToStaff: appointment.noteToStaff,
      customerId: appointment.customerId,
      noteToCustomer: appointment.noteToCustomer,
      newCustomer: false,
      newCustomerFirstName: '',
      newCustomerLastName: '',
      newCustomerEmail: '',
      newCustomerPhone: '',
      sendConfirmationText: false,
      sendConfirmationEmail: false,
      sendCustomerReminderText: appointment.sendCustomerReminderText,
      sendCustomerReminderEmail: appointment.sendCustomerReminderEmail,
      sendStaffReminderText: appointment.sendStaffReminderText,
      sendStaffReminderEmail: appointment.sendStaffReminderEmail,
      customerReminderInterval: 1,
      customerReminderPeriod: 'day',
      staffReminderInterval: 1,
      staffReminderPeriod: 'day',
      serviceId: appointment.serviceId || '',
    };

    const customerReminderDuration = moment.duration(moment(appointment.start).diff(moment(appointment.customerReminderTime)));
    const customerReminderTimeBeforeInHours = customerReminderDuration.asHours();

    if (customerReminderTimeBeforeInHours < 1) {
      const customerReminderTimeBeforeInMinutes = customerReminderDuration.asMinutes();
      appointmentData.customerReminderPeriod = 'minute';
      appointmentData.customerReminderInterval = Math.round(customerReminderTimeBeforeInMinutes);
    } else if (customerReminderTimeBeforeInHours < 24) {
      appointmentData.customerReminderPeriod = 'hour';
      appointmentData.customerReminderInterval = Math.round(customerReminderTimeBeforeInHours);
    } else {
      appointmentData.customerReminderPeriod = 'day';
      appointmentData.customerReminderInterval = Math.floor(customerReminderTimeBeforeInHours / 24);
    }

    const staffReminderDuration = moment.duration(moment(appointment.start).diff(moment(appointment.staffReminderTime)));
    const staffReminderTimeBeforeInHours = staffReminderDuration.asHours();

    if (staffReminderTimeBeforeInHours < 1) {
      const staffReminderTimeBeforeInMinutes = staffReminderDuration.asMinutes();
      appointmentData.staffReminderPeriod = 'minute';
      appointmentData.staffReminderInterval = Math.round(staffReminderTimeBeforeInMinutes);
    } else if (staffReminderTimeBeforeInHours < 24) {
      appointmentData.staffReminderPeriod = 'hour';
      appointmentData.staffReminderInterval = Math.round(staffReminderTimeBeforeInHours);
    } else {
      appointmentData.staffReminderPeriod = 'day';
      appointmentData.staffReminderInterval = Math.floor(staffReminderTimeBeforeInHours / 24);
    }

    if (appointment.recurringAppointmentId) {
      try {
        setLoading(true);
        
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const response = await axios.get(`${config.api}/api/v1/business/recurring-appointments/${user.businessId}/${appointment.recurringAppointmentId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data && response.data.id) {
          appointmentData.recurring = true;
          appointmentData.period = response.data.period;
          appointmentData.interval = response.data.interval;
          appointmentData.ongoing = response.data.ongoing;
          appointmentData.endDate = moment(response.data.endDate).tz(business.timezone).toDate();
          appointmentData.recurringStartDate = moment(response.data.startDate).tz(business.timezone).startOf('day').valueOf();
          appointmentData.originalEventStartDate = moment(appointment.date).tz(business.timezone).startOf('day').valueOf();

          setLoading(false);
        } else {
          setModalTitle('Error:');
          setModalText('There was an error retrieving recurring appointment data, please try again.');
          setLoading(false);
          return;
        }
      } catch (e) {
        setModalTitle('Error:');
        setModalText('There was an error retrieving recurring appointment data, please try again.');
        setLoading(false);
        return;
      }
    }

    setCustomerSearchValue({ value: appointmentData.customerId, label: appointment.customerName });
    setSelectedEvent(appointmentData);
    setEditModalVisible(true);
  };

  return (
    <div className="Calendar">
      <CalendarHeader
        viewType={viewType}
        updateViewType={(value) => {
          if (value !== viewType) {
            setViewType(value);
          }
        }}
        currentDate={currentDate}
        moveDateBackClicked={() => moveDateBack()}
        moveDateForwardClicked={() => moveDateForward()}
        createAppointmentClicked={() => handleCreateAppointmentClicked()}
      />

      {viewType === 'day' ?
        <CalendarDayView
          staff={staff}
          schedule={schedule}
          appointments={appointments}
          currentDate={currentDate}
          handleTimeBlockClicked={(hour, minutes, staffId) => handleTimeBlockClickedOnDayView(hour, minutes, staffId)}
          handleAppointmentClicked={(appointment) => handleAppointmentClicked(appointment)}
        /> :
        <CalendarWeekView
          staff={staff}
          currentUserId={user.id}
          schedule={schedule}
          appointments={appointments}
          currentDate={currentDate}
          handleTimeBlockClicked={(day, hour, minutes) => handleTimeBlockClickedOnWeekView(day, hour, minutes)}
          handleAppointmentClicked={(appointment) => handleAppointmentClicked(appointment)}
          handleChangeDate={(newDate) => handleDateSelectedFromCalendar(newDate)}
        />
      }

      <Modal
        open={editModalVisible}
        close={() => {
          setEditModalVisible(false);
        }}
        title={`${!selectedEvent.id ? 'Create' : 'Edit'} Appointment`}
        buttons={[
          <button key="modal-submit" className="small success" onClick={() => {
            if (selectedEvent.id && selectedEvent.recurringAppointmentId && selectedEvent.recurring) {
              setEditRecurringEventOption(0);
              setShowEditRecurringEventOptions(true);
              return;
            }

            submitEvent();
          }}>Submit</button>,
          <button key="modal-close" className="small" onClick={() => {
            setEditModalVisible(false);
          }}>Close</button>,
        ]}
      >
        <div className="appointment-modal">
          <div className="input-container">
            <div className="times-container">
              <div className="time-container">
                <label>Date:</label>
                <DatePicker
                  selected={selectedEvent.date}
                  onChange={(date) => {
                    setSelectedEvent({
                      ...selectedEvent,
                      date,
                    });
                  }}
                  customInput={<DatePickerInput />}
                  minDate={defaultDate}
                  showPopperArrow={false}
                />
              </div>

              <div className="time-divider"></div>

              <div className="time-container">
                <label>Time:</label>
                <div className="combined-select-container">
                  <select
                    value={selectedEvent.startTime}
                    onChange={(e) => {
                      setSelectedEvent({
                        ...selectedEvent,
                        startTime: e.target.value,
                      });
                    }}
                  >
                    {times.map(time => {
                      return <option key={time.value} value={time.value}>{time.display}</option>
                    })}
                  </select>
                  <select
                    value={selectedEvent.startMeridiem}
                    onChange={(e) => {
                      setSelectedEvent({
                        ...selectedEvent,
                        startMeridiem: e.target.value,
                      });
                    }}
                  >
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </div>

              {/* <div className="time-divider"></div>

              <div className="time-container">
                <label>End:</label>
                <div className="combined-select-container">
                  <select
                    value={selectedEvent.endTime}
                    onChange={(e) => {
                      setSelectedEvent({
                        ...selectedEvent,
                        endTime: e.target.value,
                      });
                    }}
                  >
                    {times.map(time => {
                      return <option key={time.value} value={time.value}>{time.display}</option>
                    })}
                  </select>
                  <select
                    value={selectedEvent.endMeridiem}
                    onChange={(e) => {
                      setSelectedEvent({
                        ...selectedEvent,
                        endMeridiem: e.target.value,
                      });
                    }}
                  >
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </div> */}
            </div>
            {!appointmentErrors.endTimeError ? null : <small className="form-error">End Time must be after Start Time.</small>}
          </div>

          <div className="input-container">
            <label>Service:</label>
            <select
              value={selectedEvent.serviceId || ''}
              onChange={(e) => {
                let serviceId = e.target.value || '';
                const service = services.find(s => s.id === +serviceId);

                if (!service) {
                  serviceId = '';
                }

                setSelectedEvent({
                  ...selectedEvent,
                  serviceId,
                });
              }}
            >
              <option value="" disabled>Choose a Service</option>
              {services.map((service, i) => {
                return <option key={`service-${i}`} value={service.id}>{service.name}</option>
              })}
            </select>
          </div>

          <div className="color-and-recurring-row">
            <div className="color-container-input">
              <ColorPicker
                title="Event Color:"
                selectedColor={selectedEvent.color}
                onSelectColor={(color) => {
                  setSelectedEvent({
                    ...selectedEvent,
                    color,
                  });
                }}
              />
            </div>

            <div className="input-container recurring-row">
              <input
                checked={selectedEvent.recurring}
                type="checkbox"
                onChange={(e) => {
                  setSelectedEvent({
                    ...selectedEvent,
                    recurring: e.target.checked,
                  });
                }}
              />

              <label>Recurring</label>

              <div className="recurring-tip-container">
                <i className="far fa-question-circle" aria-hidden="true"></i>

                <div className="recurring-tip">
                  Toggle to set up recurring appointments for this customer
                </div>
              </div>
            </div>
          </div>

          {!selectedEvent.recurring ? null :
            <>
              <div className="input-container">
                <label>Repeat every:</label>
                <div className="frequency-container">
                  <div className="combined-select-container">
                    <select
                      value={selectedEvent.interval}
                      onChange={(e) => {
                        setSelectedEvent({
                          ...selectedEvent,
                          interval: e.target.value,
                        });
                      }}
                    >
                      {intervalOptions.map(interval => {
                        return <option key={`interval-${interval}`} value={interval}>{interval}</option>
                      })}
                    </select>
                    <select
                      value={selectedEvent.period}
                      onChange={(e) => {
                        setSelectedEvent({
                          ...selectedEvent,
                          period: e.target.value,
                        });
                      }}
                    >
                      {periodOptions.map(period => {
                        return <option key={`period-${period}`} value={period}>{period}{selectedEvent.interval > 1 ? 's' : ''}</option>
                      })}
                    </select>
                  </div>

                  <div className="ongoing-row">
                    <input
                      checked={selectedEvent.ongoing}
                      type="checkbox"
                      onChange={(e) => {
                        setSelectedEvent({
                          ...selectedEvent,
                          ongoing: e.target.checked,
                        });
                      }}
                    />

                    <label>Ongoing</label>

                    <div className="ongoing-tip-container">
                      <i className="far fa-question-circle" aria-hidden="true"></i>

                      <div className="ongoing-tip">
                        Toggle to specify if there is an end date or if appointments will be ongoing
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {selectedEvent.ongoing ? null :
                <div className="input-container">
                  <label>End Date:</label>
                  <DatePicker
                    selected={selectedEvent.endDate}
                    onChange={(endDate) => {
                      setSelectedEvent({
                        ...selectedEvent,
                        endDate,
                      });
                    }}
                    customInput={<DatePickerInput />}
                    minDate={selectedEvent.date}
                    showPopperArrow={false}
                  />
                  {!appointmentErrors.endDateError ? null : <small className="form-error">End Date must be after Start Date.</small>}
                </div>
              }
            </>
          }

          <div className="input-container">
            <label>Staff:</label>
            <select
              value={selectedEvent.resourceId}
              onChange={(e) => {
                setSelectedEvent({
                  ...selectedEvent,
                  resourceId: e.target.value,
                });
              }}
            >
              {staff.map(s => {
                return <option key={s.id} value={s.id}>{s.name}</option>
              })}
            </select>
          </div>

          <div className="input-container">
            <label>Notes:</label>
            <textarea
              value={selectedEvent.noteToStaff}
              placeholder="Enter note..."
              onChange={(e) => {
                setSelectedEvent({
                  ...selectedEvent,
                  noteToStaff: e.target.value,
                });
              }}
            >
            </textarea>
          </div>

          {!selectedEvent.newCustomer && editModalVisible ?
            <div className="select-new-customer-container">
              <div className="input-container">
                <label>Customer:</label>
                <AsyncSelect
                  cacheOptions
                  loadOptions={loadOptions}
                  defaultOptions
                  value={customerSearchValue}
                  placeholder="Search..."
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderRadius: 12,
                    }),
                  }}
                  onChange={(selectedValue) => {
                    setCustomerSearchValue(selectedValue);
                    setSelectedEvent({
                      ...selectedEvent,
                      customerId: selectedValue.value,
                    });
                  }}
                />
              </div>
              <div className="customer-or-container">
                <p>OR</p>
              </div>
              <div className="create-customer-button-container">
                <button
                  onClick={() => {
                    setSelectedEvent({
                      ...selectedEvent,
                      newCustomer: true,
                    });
                  }}
                >
                  Create New Customer
                </button>
              </div>
            </div> :
            <div className="input-container create-new-customer-container">
              <div className="create-new-customer-header-container">
                <div className="create-new-customer-header">
                  New Customer
                </div>

                <a
                  onClick={() => {
                    setSelectedEvent({
                      ...selectedEvent,
                      newCustomer: false,
                    });
                  }}
                >
                  Use Existing
                </a>
              </div>
              <div className="input-container">
                <label>First Name:</label>
                <input
                  value={selectedEvent.newCustomerFirstName}
                  placeholder="First name"
                  type="text"
                  onChange={(e) => {
                    setSelectedEvent({
                      ...selectedEvent,
                      newCustomerFirstName: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="input-container">
                <label>Last Name:</label>
                <input
                  value={selectedEvent.newCustomerLastName}
                  placeholder="Last name"
                  type="text"
                  onChange={(e) => {
                    setSelectedEvent({
                      ...selectedEvent,
                      newCustomerLastName: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="input-container">
                <label>Email:</label>
                <input
                  value={selectedEvent.newCustomerEmail}
                  placeholder="Email"
                  type="email"
                  onChange={(e) => {
                    setSelectedEvent({
                      ...selectedEvent,
                      newCustomerEmail: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="input-container">
                <label>Phone:</label>
                <input
                  value={selectedEvent.newCustomerPhone}
                  placeholder="Phone"
                  type="text"
                  onChange={(e) => {
                    setSelectedEvent({
                      ...selectedEvent,
                      newCustomerPhone: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          }

          <div className="input-container">
            <label>Note to Customer:</label>
            <textarea
              value={selectedEvent.noteToCustomer}
              placeholder="Enter note..."
              onChange={(e) => {
                setSelectedEvent({
                  ...selectedEvent,
                  noteToCustomer: e.target.value,
                });
              }}
            >
            </textarea>
          </div>

          <div className="notifications-container">
            {selectedEvent.id ? null :
              <div className="input-container appointment-notification-container">
                <p>{selectedEvent.id ? 'Notify Customer' : 'Customer Confirmation'}</p>
                <div className="appointment-notification-selection">
                  <input
                    checked={selectedEvent.sendConfirmationEmail}
                    type="checkbox"
                    onChange={(e) => {
                      setSelectedEvent({
                        ...selectedEvent,
                        sendConfirmationEmail: e.target.checked,
                      });
                    }}
                  />
                  <label>Email</label>
                  <input
                    checked={selectedEvent.sendConfirmationText}
                    type="checkbox"
                    onChange={(e) => {
                      setSelectedEvent({
                        ...selectedEvent,
                        sendConfirmationText: e.target.checked,
                      });
                    }}
                  />
                  <label>Text</label>
                </div>
              </div>
            }

            <div className="input-container appointment-notification-container">
              <p>Customer Reminder</p>
              <div className="appointment-notification-selection">
                <input
                  checked={selectedEvent.sendCustomerReminderEmail}
                  type="checkbox"
                  onChange={(e) => {
                    setSelectedEvent({
                      ...selectedEvent,
                      sendCustomerReminderEmail: e.target.checked,
                    });
                  }}
                />
                <label>Email</label>
                <input
                  checked={selectedEvent.sendCustomerReminderText}
                  type="checkbox"
                  onChange={(e) => {
                    setSelectedEvent({
                      ...selectedEvent,
                      sendCustomerReminderText: e.target.checked,
                    });
                  }}
                />
                <label>Text</label>
              </div>

              {(selectedEvent.sendCustomerReminderEmail || selectedEvent.sendCustomerReminderText) ?
                <div className="input-container">
                  <div className="frequency-container">
                    <div className="combined-select-container">
                      <select
                        value={selectedEvent.customerReminderInterval}
                        onChange={(e) => {
                          setSelectedEvent({
                            ...selectedEvent,
                            customerReminderInterval: e.target.value,
                          });
                        }}
                      >
                        {reminderIntervalOptions.map(interval => {
                          return <option key={`interval-${interval}`} value={interval}>{interval}</option>
                        })}
                      </select>
                      <select
                        value={selectedEvent.customerReminderPeriod}
                        onChange={(e) => {
                          setSelectedEvent({
                            ...selectedEvent,
                            customerReminderPeriod: e.target.value,
                          });
                        }}
                      >
                        {reminderPeriodOptions.map(period => {
                          return <option key={`period-${period}`} value={period}>{period}{selectedEvent.customerReminderInterval > 1 ? 's' : ''}</option>
                        })}
                      </select>
                    </div>
                    <div className="before-container">
                      <label>Before</label>
                    </div>
                  </div>
                </div> : null
              }
            </div>

            <div className="input-container appointment-notification-container">
              <p>Staff Reminder</p>
              <div className="appointment-notification-selection">
                <input
                  checked={selectedEvent.sendStaffReminderEmail}
                  type="checkbox"
                  onChange={(e) => {
                    setSelectedEvent({
                      ...selectedEvent,
                      sendStaffReminderEmail: e.target.checked,
                    });
                  }}
                />
                <label>Email</label>
                <input
                  checked={selectedEvent.sendStaffReminderText}
                  type="checkbox"
                  onChange={(e) => {
                    setSelectedEvent({
                      ...selectedEvent,
                      sendStaffReminderText: e.target.checked,
                    });
                  }}
                />
                <label>Text</label>
              </div>

              {(selectedEvent.sendStaffReminderEmail || selectedEvent.sendStaffReminderText) ?
                <div className="input-container">
                  <div className="frequency-container">
                    <div className="combined-select-container">
                      <select
                        value={selectedEvent.staffReminderInterval}
                        onChange={(e) => {
                          setSelectedEvent({
                            ...selectedEvent,
                            staffReminderInterval: e.target.value,
                          });
                        }}
                      >
                        {reminderIntervalOptions.map(interval => {
                          return <option key={`interval-${interval}`} value={interval}>{interval}</option>
                        })}
                      </select>
                      <select
                        value={selectedEvent.staffReminderPeriod}
                        onChange={(e) => {
                          setSelectedEvent({
                            ...selectedEvent,
                            staffReminderPeriod: e.target.value,
                          });
                        }}
                      >
                        {reminderPeriodOptions.map(period => {
                          return <option key={`period-${period}`} value={period}>{period}{selectedEvent.staffReminderInterval > 1 ? 's' : ''}</option>
                        })}
                      </select>
                    </div>
                    <div className="before-container">
                      <label>Before</label>
                    </div>
                  </div>
                </div> : null
              }
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={showEditRecurringEventOptions}
        close={() => {
          setEditRecurringEventOption(0);
          setShowEditRecurringEventOptions(false);
        }}
        title="Edit Recurring Event"
        buttons={[
          <button key="modal-submit" className="small success" onClick={() => {
            submitEvent(editRecurringEventOption);
            setEditRecurringEventOption(0);
            setShowEditRecurringEventOptions(false);
          }}>Confirm</button>,
          <button key="modal-close" className="small" onClick={() => {
            setEditRecurringEventOption(0);
            setShowEditRecurringEventOptions(false);
          }}>Cancel</button>,
        ]}
      >
        <div className="appointment-delete-modal">
          {!showEditRecurringEventOptions ? null :
            <div onChange={(e) => setEditRecurringEventOption(+e.target.value)} className="recurring-appointment-options">
              <span>
                <input id="edit-option-0" type="radio" value={0} name="edit option" defaultChecked/>
                <label htmlFor="edit-option-0">This event</label>
              </span>
              <span>
                <input id="edit-option-1" type="radio" value={1} name="edit option"/>
                <label htmlFor="edit-option-1">This and following events</label>
              </span>
            </div>
          }
        </div>
      </Modal>

      <Modal
        open={viewModalVisible}
        close={() => {
          setViewModalVisible(false);
        }}
        title=""
        buttons={[]}
      >
        <div className="view-event-modal">
          <p className="customer-name">{openedEvent.customerName}</p>

          <p className="date">
            {moment(openedEvent.start).format('dddd, MMMM Do')}
            <span className="date-divider"></span>
            {moment(openedEvent.start).format('h:mm')}
            <span className="time-divider">-</span>
            {moment(openedEvent.end).format('h:mma')}
          </p>

          <p className="detail">
            <strong>Email:</strong> {openedEvent.customerEmail ? openedEvent.customerEmail : '-'}
          </p>

          <p className="detail">
            <strong>Phone:</strong> {openedEvent.customerPhone ? openedEvent.customerPhone : '-'}
          </p>

          <p className="detail">
            <strong>Staff:</strong> {openedEvent.staffName}
          </p>

          <p className="detail">
            <strong>Notes:</strong> {openedEvent.noteToStaff ? null : '-'}
          </p>

          <p className="detail notes">{openedEvent.noteToStaff}</p>

          <div className="actions-buttons-container">
            <button
              className="action-button"
              onClick={() => {
                setDeleteOption(0);
                setDeleteOpen(true);
              }}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>

            <button
              className="action-button"
              onClick={() => {
                setViewModalVisible(false);
                handleEditAppointmentClicked(openedEvent);
              }}
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={deleteOpen}
        close={() => {
          setDeleteOption(0);
          setDeleteOpen(false);
        }}
        title={`Delete ${!openedEvent.recurringAppointmentId ? '' : 'recurring '}event`}
        buttons={[
          <button key="modal-submit" className="small success" onClick={() => {
            deleteAppointment();
          }}>Confirm</button>,
          <button key="modal-close" className="small" onClick={() => {
            setDeleteOption(0);
            setDeleteOpen(false);
          }}>Cancel</button>,
        ]}
      >
        <div className="appointment-delete-modal">
          {deleteOpen && openedEvent.recurringAppointmentId ?
            <div onChange={(e) => setDeleteOption(+e.target.value)} className="recurring-appointment-options">
              <span>
                <input id="delete-option-0" type="radio" value={0} name="delete option" defaultChecked/>
                <label htmlFor="delete-option-0">This event</label>
              </span>
              <span>
                <input id="delete-option-1" type="radio" value={1} name="delete option"/>
                <label htmlFor="delete-option-1">This and following events</label>
              </span>
              <span>
                <input id="delete-option-2" type="radio" value={2} name="delete option"/>
                <label htmlFor="delete-option-2">All events</label>
              </span>
            </div> :
            <p className="message">Are you sure you want to delete this event? This action cannot be undone.</p>
          }
        </div>
      </Modal>

      <Modal
        open={!!modalText}
        close={() => {
          setModalTitle('');
          setModalText('');
        }}
        title={modalTitle}
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            setModalTitle('');
            setModalText('');
          }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default Calendar;
