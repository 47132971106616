import React from 'react';
import { Link, Switch, Route, useLocation } from 'react-router-dom';

import './account.scss';
import BusinessInfo from './BusinessInfo';
// import Settings from './Settings';
import Profile from './Profile';

function Account({}) {
  let location = useLocation();

  return (
    <div className="Account">
      <div>
        <div className="header-container">
          <h1>Account</h1>
        </div>

        <div className="tabs-container">
          <Link to="/account/business-info">
            <div className={`nav-tab${(location.pathname === '/account' || location.pathname.includes('business-info')) ? ' active' : ''}`}>
              Business Info
              <div className="tab-highlighter"></div>
            </div>
          </Link>

          {/* <Link to="/account/settings">
            <div className={`nav-tab${location.pathname.includes('settings') ? ' active' : ''}`}>
              Settings
              <div className="tab-highlighter"></div>
            </div>
          </Link> */}

          <Link to="/account/profile">
            <div className={`nav-tab${location.pathname.includes('profile') ? ' active' : ''}`}>
              Profile
              <div className="tab-highlighter"></div>
            </div>
          </Link>
        </div>

        <div className="tab-content">
          <Switch>
            <Route path="/account/profile" component={Profile} />
            {/* <Route path="/account/settings" component={Settings} /> */}
            <Route path="/account/business-info" component={BusinessInfo} />
            <Route component={BusinessInfo}/>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Account;
