import React from 'react';

import './businessNotFound.scss';

function BusinessNotFound({}) {
  return (
    <div className="BusinessNotFound">
      <div className="text-container">
        <h1>Oops, it looks like this page doesn't exist.</h1>
        <p>This page may have been moved. Try navigating back a page or please contact us if you have any troubles finding what you're looking for.</p>
      </div>
    </div>
  );
}

export default BusinessNotFound;
