import React, { useState } from 'react';

import './colorPicker.scss';
import { colors } from '../../../util/colors';

function ColorPicker({ selectedColor, onSelectColor }) {
  const [colorsOpen, setColorsOpen] = useState(false);
  return (
    <div className="ColorPicker">
      <div className="input-container color-picker-container">
        <div
          className="color-container"
          style={{ backgroundColor: selectedColor || colors[0] }}
          onClick={() => setColorsOpen(!colorsOpen)}
        ></div>
        <label>Choose Color</label>
      </div>

      {!colorsOpen ? null :
        <div className="colors-container">
          <div className="colors-container-inner">
            {colors.map(color => {
              return (
                <div
                  key={`color-${color}`}
                  className="color-container"
                  style={{ backgroundColor: color }}
                  onClick={() => {
                    onSelectColor(color);
                    setColorsOpen(false);
                  }}
                ></div>
              );
            })}
          </div>
        </div>
      }
    </div>
  );
}

export default ColorPicker;
