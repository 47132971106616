import {
  UPDATE_STAFF,
} from './types';

export const updateStaff = (staff) => {
  return {
    type: UPDATE_STAFF,
    payload: staff,
  };
};
