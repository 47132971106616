export const defaultSchedule = {
  id: '',
  name: '',
  dateOverrides: [],
  schedule: [
    {
      day: 'Monday',
      startTime: '08:00:00',
      startMeridiem: 'AM',
      endTime: '05:00:00',
      endMeridiem: 'PM',
      active: true,
      additionalTimes: [],
    },
    {
      day: 'Tuesday',
      startTime: '08:00:00',
      startMeridiem: 'AM',
      endTime: '05:00:00',
      endMeridiem: 'PM',
      active: true,
      additionalTimes: [],
    },
    {
      day: 'Wednesday',
      startTime: '08:00:00',
      startMeridiem: 'AM',
      endTime: '05:00:00',
      endMeridiem: 'PM',
      active: true,
      additionalTimes: [],
    },
    {
      day: 'Thursday',
      startTime: '08:00:00',
      startMeridiem: 'AM',
      endTime: '05:00:00',
      endMeridiem: 'PM',
      active: true,
      additionalTimes: [],
    },
    {
      day: 'Friday',
      startTime: '08:00:00',
      startMeridiem: 'AM',
      endTime: '05:00:00',
      endMeridiem: 'PM',
      active: true,
      additionalTimes: [],
    },
    {
      day: 'Saturday',
      startTime: '08:00:00',
      startMeridiem: 'AM',
      endTime: '05:00:00',
      endMeridiem: 'PM',
      active: false,
      additionalTimes: [],
    },
    {
      day: 'Sunday',
      startTime: '08:00:00',
      startMeridiem: 'AM',
      endTime: '05:00:00',
      endMeridiem: 'PM',
      active: false,
      additionalTimes: [],
    },
  ],
};
