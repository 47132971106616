import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Calendar from 'react-calendar';

import './calendarWeekView.scss';
import { displayHours } from '../../../../../util/displayHours';

function CalendarWeekView({
  staff = [],
  currentUserId,
  schedule,
  appointments = [],
  currentDate,
  handleTimeBlockClicked,
  handleAppointmentClicked,
  handleChangeDate,
}) {
  const [daysOfCurrentWeek, setDaysOfCurrentWeek] = useState([]);
  const [staffAppointments, setStaffAppointments] = useState({});
  const [selectedStaffIds, setSelectedStaffIds] = useState([currentUserId]);

  useEffect(() => {
    const updatedDaysOfCurrentWeek = [];
    let dayOfTheWeek = moment(currentDate).startOf('week');

    while (updatedDaysOfCurrentWeek.length < 7) {
      updatedDaysOfCurrentWeek.push(moment(dayOfTheWeek).valueOf());
      dayOfTheWeek.add(1, 'day');
    }

    setDaysOfCurrentWeek(updatedDaysOfCurrentWeek);
  }, [currentDate]);

  useEffect(() => {
    const updatedStaffAppointments = {};

    staff.forEach(s => {
      updatedStaffAppointments[s.id] = [];
    });

    appointments.forEach(a => {
      if (updatedStaffAppointments[a.resourceId]) {
        updatedStaffAppointments[a.resourceId].push(a);
      }
    });

    setStaffAppointments(updatedStaffAppointments);
  }, [appointments, currentDate, selectedStaffIds]);

  const renderAppointmentsForHour = (day, hours) => {
    const startOfHour = moment(day).set({ hours, minutes: 0, seconds: 0, milliseconds: 0 });
    let appointmentsForHour = [];
    const appointments = [];

    for (let key in staffAppointments) {
      if (selectedStaffIds.includes(key)) {
        staffAppointments[key].forEach(a => appointments.push(a));
      }
    }

    (appointments || []).forEach(a => {
      const appointmentStart = moment(a.start);
      const appointmentEnd = moment(a.end);
      const duration = moment.duration(appointmentEnd.diff(appointmentStart));
      const appointmentLengthInMinutes = duration.asMinutes();

      if (moment(appointmentStart).startOf('hour').isSame(startOfHour)) {
        appointmentsForHour.push({
          ...a,
          topDistance: ((appointmentStart.get('minutes') / 60) * 50),
          height: ((appointmentLengthInMinutes / 60) * 50),
        });
      }
    });

    return appointmentsForHour.map((appointment) => {
      return (
        <button
          key={`week-appointment-button-${appointment.id}`}
          className="appointment-button"
          style={{ top: appointment.topDistance, height: appointment.height, backgroundColor: appointment.color }}
          onClick={() => handleAppointmentClicked(appointment)}
        >
          <span>
            <span>{appointment.customerName}</span>
            <span className="appointment-time">{moment(appointment.start).format('h:mm A')} - {moment(appointment.end).format('h:mm A')}</span>
          </span>
        </button>
      );
    });
  };

  return (
    <div className="CalendarWeekView">
      <div className="left-column">
        <div className="left-column-header">
        </div>

        <div className="left-column-content">
          <div className="calendar-picker-container">
            <Calendar
              onChange={(newDate) => handleChangeDate(newDate)}
              value={new Date(currentDate)}
              prev2Label={<></>}
              next2Label={<></>}
              prevLabel={<i className="fas fa-chevron-left"></i>}
              nextLabel={<i className="fas fa-chevron-right"></i>}
              calendarType="US"
            />
          </div>

          <div className="staff-container">
            {staff.map((s, i) => {
              return (
                <button
                  key={`staff-${i}`}
                  className="staff-member"
                  onClick={() => {
                    const staffIdIndex = selectedStaffIds.indexOf(s.id);
                    const updatedSelectedStaffIds = [ ...selectedStaffIds ];

                    if (staffIdIndex === -1) {
                      updatedSelectedStaffIds.push(s.id);
                    } else {
                      updatedSelectedStaffIds.splice(staffIdIndex, 1);
                    }

                    setSelectedStaffIds(updatedSelectedStaffIds);
                  }}
                >
                  <div>
                    <div className={`selected-indicator${selectedStaffIds.includes(s.id) ? ' active' : ''}`}>
                    </div>
                  </div>
                  <div>
                    <div className="staff-image-container">
                      <i className="fas fa-user"></i>
                    </div>
                  </div>
                  <div className="staff-details-container">
                    <p className="staff-name">{s.name}</p>
                    <p className="staff-appointment-count">{(staffAppointments[s.id] || []).length} Appointment{(staffAppointments[s.id] || []).length === 1 ? '' : 's'}</p>
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      </div>

      <div className="right-container">
        <div className="date-container">
          <div className="date-container-header-row">
            <div className="time-header-row">
              <div className="time-header">
              </div>
            </div>

            {daysOfCurrentWeek.map((day, i) => {
              return (
                <div key={`date-header-row-${i}`} className="date-header-row">
                  <div className="date-header">
                    <div className="date-header-inner">
                      <p className="date-number">{moment(day).format('D')}</p>
                      <p className="date-day">{moment(day).format('ddd')}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="time-container-outer time-scroll-container">
            <div className="time-container">
              <div className="time-column">
                {displayHours.map((h, i) => {
                  return (
                    <div key={`hour-row-${i}`} className="hour-cell">
                      {i === 0 ? '' :
                        <span>{h}</span>
                      }
                    </div>
                  );
                })}
              </div>

              <div className="date-columns">
                {daysOfCurrentWeek.map((day, i) => {
                  return (
                    <div key={`date-column-${i}`} className="date-column">
                      {displayHours.map((h, j) => {
                        const hourArr = h.split(' ');
                        let hours = +hourArr[0];

                        if (hours !== 12 && hourArr[1] === 'PM') {
                          hours += 12;
                        } else if (hours === 12 && hourArr[1] === 'AM') {
                          hours = 0;
                        }

                        return (
                          <div key={`date-hour-row-${i}-${j}`} className="date-time-cell">
                            <button
                              className="time-block-select-button"
                              onClick={() => handleTimeBlockClicked(day, h, 0)}
                            ></button>
                            <button
                              className="time-block-select-button"
                              onClick={() => handleTimeBlockClicked(day, h, 15)}
                            ></button>
                            <button
                              className="time-block-select-button"
                              onClick={() => handleTimeBlockClicked(day, h, 30)}
                            ></button>
                            <button
                              className="time-block-select-button"
                              onClick={() => handleTimeBlockClicked(day, h, 45)}
                            ></button>

                            {renderAppointmentsForHour(day, hours)}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CalendarWeekView;
