import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';

import './onboardingStep1.scss';
import { isValidPhone } from '../../../../util/isValidPhone';
import { countries } from '../../../../util/countries';
import { timezones } from '../../../../util/timezones';
import { config } from '../../../../config';
import { setBusiness, updateUser } from '../../../../store/actions';

function OnboardingStep1({ handleError }) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [saving, setSaving] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('US');
  const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const moveToNextStep = async () => {
    if (saving) {
      return;
    }

    setSaving(true);

    try {
      const businessData = {
        name: businessName,
        appURL: '',
        logo: '',
        country,
        city: '',
        state: '',
        zip: '',
        address1: '',
        address2: '',
        industry: '',
        timezone,
        description: '',
        website: '',
        email: user.email,
        phone: phoneNumber,
        phoneExt: '',
        customerCount: 0,
        created: moment().valueOf(),
      };

      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();

      const response = await axios.post(`${config.api}/api/v1/business/${user.id}`,
        businessData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const businessId = response.data.id;

      const userData = {
        firstName,
        lastName,
        email: user.email,
        phone: '',
        onboardingStep: 1,
        roles: user.roles,
        businessId: businessId,
        created: user.created,
      };

      await axios.put(`${config.api}/api/v1/user/${user.id}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch(setBusiness({
        ...businessData,
        id: businessId,
      }));

      dispatch(updateUser(userData));
    } catch (e) {
      let errorMessage = 'There was an error saving this step. Please try again.';
      if (e && e.response && e.response.data && e.response.data.message) {
        errorMessage = e.response.data.message;
      }

      handleError('Error:', errorMessage);
      setSaving(false);
    }
  };

  return (
    <div className="OnboardingStep1">
      <div className="input-row">
        <div className="input-container">
          <label>First Name:</label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="Enter first name"
          />
        </div>

        <div className="input-container">
          <label>Last Name:</label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Enter last name"
          />
        </div>
      </div>

      <div className="input-container">
        <label>Business Name:</label>
        <input
          type="text"
          value={businessName}
          onChange={(e) => setBusinessName(e.target.value)}
          placeholder="Enter business name"
        />
      </div>

      <div className="input-container">
        <label>Phone Number:</label>
        <input
          type="phone"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder="Enter phone number"
        />
      </div>

      <div className="input-row">
        <div className="input-container">
          <label>Country:</label>
          <select
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          >
            {countries.map((c, i) => {
              return <option key={`country-${i}`} value={c.value}>{c.name}</option>
            })}
          </select>
        </div>

        <div className="input-container">
          <label>Timezone:</label>
          <select
            value={timezone}
            onChange={(e) => setTimezone(e.target.value)}
          >
            {timezones.map((timezone, i) => {
              return <option key={`timezone-${i}`} value={timezone}>{timezone}</option>
            })}
          </select>
        </div>
      </div>

      <div className="actions-row">
        <Link
          to="/signin"
          onClick={() => {
            const auth = getAuth();
            auth.signOut();
          }}
        >
          Sign out
        </Link>

        <button
          className="success"
          onClick={moveToNextStep}
          disabled={!firstName || !lastName || !businessName || !phoneNumber || !isValidPhone(phoneNumber)}
        >
          {saving ? 'Saving...' : 'Next'}
        </button>
      </div>
    </div>
  );
}

export default OnboardingStep1;
