import React from 'react';

import './servicesActionDropdown.scss';

function ServicesActionDropdown({
  editClicked,
  shareCalendarClicked,
  previewCalendarClicked,
  deleteClicked,
}) {
  return (
    <div className="ServicesActionDropdown">
      <button onClick={() => editClicked()}>
        Edit
      </button>
      <button onClick={() => shareCalendarClicked()}>
        <span>Share Calendar</span> <i className="fa fa-share"></i>
      </button>
      <button onClick={() => previewCalendarClicked()}>
        <span>Preview Calendar</span> <i className="fa fa-external-link"></i>
      </button>
      <button onClick={() => deleteClicked()}>
        Delete
      </button>
    </div>
  );
}

export default ServicesActionDropdown;
