import React, { useState } from 'react';

import './onboarding.scss';
import Modal from '../../shared/Modal';
import OnboardingStep1 from './OnboardingStep1';
import OnboardingStep2 from './OnboardingStep2';
import OnboardingStep3 from './OnboardingStep3';
import OnboardingStep4 from './OnboardingStep4';

function Onboarding({ onboardingStep }) {
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  const renderStep = () => {
    switch (onboardingStep) {
      case 0:
        return (
          <OnboardingStep1
            handleError={handleError}
          />
        );
      case 1:
        return (
          <OnboardingStep2
            handleError={handleError}
          />
        );
      case 2:
        return (
          <OnboardingStep3
            handleError={handleError}
          />
        );
      case 3:
        return (
          <OnboardingStep4
            handleError={handleError}
          />
        );
      default:
        return null;
    }
  };

  const handleError = (title, message) => {
    setModalTitle(title);
    setModalText(message);
  };

  return (
    <div className="Onboarding">
      <h1>Welcome, let's get started!</h1>

      <div className="well onboarding-container">
        <div className="stepper-container">
          <div className={`step-container complete${onboardingStep === 0 ? ' active' : ''}`}>
            <div className="flex-number-container">1</div>
            <p>Welcome</p>
            <div className="filler-line"></div>
          </div>
          <div className={`step-container${onboardingStep >= 1 ? ' complete' : ''}${onboardingStep === 1 ? ' active' : ''}`}>
            <div className="flex-number-container">2</div>
            <p>Working hours</p>
            <div className="filler-line"></div>
          </div>
          <div className={`step-container${onboardingStep >= 2 ? ' complete' : ''}${onboardingStep === 2 ? ' active' : ''}`}>
            <div className="flex-number-container">3</div>
            <p>Staff</p>
            <div className="filler-line"></div>
          </div>
          <div className={`step-container last-step-container${onboardingStep >= 3 ? ' complete' : ''}${onboardingStep === 3 ? ' active' : ''}`}>
            <div className="flex-number-container">4</div>
            <p>Services</p>
          </div>
        </div>

        {renderStep()}
      </div>

      <Modal
        open={!!modalText}
        close={() => {
          setModalTitle('');
          setModalText('');
        }}
        title={modalTitle}
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            setModalTitle('');
            setModalText('');
          }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default Onboarding;
