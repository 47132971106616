import {
  UPDATE_SERVICES,
} from '../actions/types';

const defaultState = {
  initialized: false,
  services: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_SERVICES:
      return {
        ...state,
        initialized: true,
        services: [ ...action.payload ],
      };
    default:
      return state;
  }
};
