import React from 'react';

import './tableRow.scss';

function TableRow({ children }) {
  return (
    <tr className="TableRow">
      {children}
    </tr>
  );
}

export default TableRow;
